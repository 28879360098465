import React, { useState, useEffect, useRef } from "react";
import logo from "../../../data/assets/logo/openbuilder_logo.png";
import { useAuth } from "../../../backend/firebase/AuthContext";
import ProjectNameGenerator from "../../../random_project_name/ProjectNameGenerator";
import { setDoc, doc, serverTimestamp } from "firebase/firestore";
import { db } from "../../../backend/firebase/firebase-config";
import { useNavigate } from "react-router-dom";
import FileSelectorMultiple from "../../../backend/upload/FileSelectorMultiple";
import CitySelector from "../../../building_blocks/CitySelector";
import SuburbSelector from "../../../building_blocks/SuburbSelector";
import { servicesData } from "../../../services/ServicesData";
import { ServiceCategory } from "../../../services/ServiceCategory";

import {
  CapeTownSuburbs,
  JohannesburgSuburbs,
} from "../../../data/locations/Suburbs";

export function ClientBuildAlterationNextGen() {
  const [projectName, setProjectName] = useState("");
  const [specialRequests, setSpecialRequests] = useState([{ value: "" }]);
  const { user, handleSignOut } = useAuth();
  const [downloadUrls, setDownloadUrls] = useState({});
  const [housePlansUrl, setHousePlansUrl] = useState({});
  const [multipleFileUrls, setMultipleFileUrls] = useState({});
  const [multipleSingleUrls, setSingleFileUrls] = useState({});
  const [activeButtonIndices, setActiveButtonIndices] = useState([[], []]);
  const [requestHistory, setRequestHistory] = useState([]);
  const [showUploadPlans, setShowUploadPlans] = useState(false);
  const navigate = useNavigate();
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedSuburb, setSelectedSuburb] = useState("");
  const [budget, setBudget] = useState("");
  const [selectedServices, setSelectedServices] = useState({});

  const handleUrlReceivedMultiple = (id, urls) => {
    console.log("Received URLs for", id, ":", urls);
    setMultipleFileUrls((prevUrls) => ({ ...prevUrls, [id]: urls }));
  };

  const handleProjectNameChange = (name) => {
    console.log("New Project Name:", name);
    setProjectName(name);
  };

  const handleRequestSubmit = (requestData) => {
    setRequestHistory((prevHistory) => [...prevHistory, requestData]);
  };

  const handleHousePlansUrl = (id, url) => {
    setHousePlansUrl(url);
  };

  const logHistory = () => {
    console.log(requestHistory);
  };

  const handleCityChange = (event) => {
    setSelectedCity(event.target.value);
    setSelectedSuburb(""); // Reset suburb when city changes
  };

  const handleSelectService = (category, item) => {
    setSelectedServices((prevServices) => {
      const updatedServices = { ...prevServices, [category]: item };
      return updatedServices;
    });
  };

  const handleSuburbChange = (event) => {
    setSelectedSuburb(event.target.value);
  };

  const handleBudgetChange = (event) => {
    const value = event.target.value;
    setBudget(value);
  };

  const HistoryDisplay = ({ history }) => {
    return (
      <div className="bg-white shadow overflow-hidden rounded-lg border-2 border-colour_2">
        <ul className="divide-y divide-gray-200">
          {history.map((request, index) => (
            <li key={index}>
              <a href="#" className="block hover:bg-gray-50">
                <div className="px-4 py-4 sm:px-6 ">
                  <div className="flex items-center justify-between">
                    <p className="text-sm font-medium text-indigo-600 truncate">
                      {request.item.name}
                    </p>
                    <div className="ml-2 flex-shrink-0 flex">
                      <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                        {request.budget}
                      </p>
                    </div>
                  </div>
                  <div className="mt-2 sm:flex sm:justify-between flex">
                    <div className="sm:flex">
                      <p className="flex items-center text-sm text-gray-500">
                        Special Requests: {request.specialRequests.join(", ")}
                      </p>
                    </div>
                    <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                      <p>Budget: {request.budget}</p>
                    </div>
                  </div>
                </div>
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const setProjectDetails = async () => {
    console.log("Project Name:", projectName);
    navigate("/congratulations_client_build_alteration");

    try {
      const userDocRef = doc(db, "unpriced_jobs_next_gen", `${projectName}`);
      await setDoc(userDocRef, {
        name: projectName,
        user: user.email,
        city: selectedCity,
        suburb: selectedSuburb,
        budget: budget,
        imageUrls: multipleSingleUrls,
        housePlansUrl: housePlansUrl,
        multipleFileUrls: multipleFileUrls["multipleFiles"] ?? {},
        date_created: serverTimestamp(),
        paid: false,
        paid_amount: 0,
        requests: requestHistory,
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="w-full md:full">
      <div className="flex items-center justify-center">
        <ProjectNameGenerator onProjectNameChange={handleProjectNameChange} />

        <div className="w-full h-100px flex items-center">
          <img
            className="h-16 md:h-32 w-16 md:w-32 p-1 md:p-5"
            src={logo}
            alt="Logo"
          />

          <h1 className="w-8/10 md:w-max font-bold text-xs md:text-4xl text-colour_2 p-0 md:p-2 justify-center">
            CHOOSE THE CHANGES YOU WOULD LIKE TO DO TO YOUR HOME
          </h1>
        </div>
      </div>

      {user ? (
        <div className="flex flex-col md:flex-row">
          <div className="w-full flex flex-col md:flex-row">
            <div className="w-full md:w-4/6 flex flex-col items-center p-4 bg-transparent">
              <h1 className="font-semibold text-xs md:text-xl text-colour_2 p-2">
                YOUR REQUEST
              </h1>
              <div className="w-3/4 h-1/6">
                <CitySelector
                  value={selectedCity}
                  onChange={handleCityChange}
                />
                <SuburbSelector
                  city={selectedCity}
                  value={selectedSuburb}
                  onChange={handleSuburbChange}
                />
                <input
                  type="number"
                  id="budget"
                  name="budget"
                  placeholder="Enter your overall budget"
                  value={budget}
                  onChange={handleBudgetChange}
                  className="w-full md:h-1/2 h-1/4 text-sm text-xs md:p-2 p-0 text-colour_2 font-thin rounded-lg border-4 border-colour_2 m-2"
                />

                <div className="flex flex-col items-center justify-center w-full">
                  <button
                    className="w-full md:w-3/4 rounded-lg bg-colour_2 text-white p-4 text-xs md:text-lg"
                    onClick={() => setShowUploadPlans(!showUploadPlans)}
                  >
                    {showUploadPlans
                      ? "HIDE UPLOAD OPTIONS"
                      : "UPLOAD YOUR PLANS"}
                  </button>
                  {showUploadPlans && (
                    <div className="upload">
                      <h1 className="font-semibold text-xs md:text-xl text-colour_2 p-2">
                        UPLOAD YOUR PLANS
                      </h1>
                      <FileSelectorMultiple
                        id="multipleFiles"
                        text="UPLOAD YOUR ARCHITECTURAL HOUSE PLAN"
                        path={`unpriced_jobs/${user.email}/${projectName}/plans/architectural/`}
                        onUrlsReceived={handleUrlReceivedMultiple}
                      />
                      <FileSelectorMultiple
                        id="multipleFiles"
                        text="UPLOAD YOUR ELECTRICAL HOUSE PLAN"
                        path={`unpriced_jobs/${user.email}/${projectName}/plans/electrical/`}
                        onUrlsReceived={handleUrlReceivedMultiple}
                      />
                      <FileSelectorMultiple
                        id="multipleFiles"
                        text="UPLOAD YOUR STRUCTURAL HOUSE PLAN"
                        path={`unpriced_jobs/${user.email}/${projectName}/plans/structural/`}
                        onUrlsReceived={handleUrlReceivedMultiple}
                      />
                      <FileSelectorMultiple
                        id="multipleFiles"
                        text="UPLOAD ADDITIONAL SITE IMAGES"
                        path={`unpriced_jobs/${user.email}/${projectName}/images/`}
                        onUrlsReceived={handleUrlReceivedMultiple}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="w-full md:w-2/6 flex flex-col items-center p-4 bg-transparent">
              {servicesData.map((category, index) => (
                <ServiceCategory
                  key={index}
                  handleRequestSubmit={handleRequestSubmit}
                  category={category}
                  onSelect={handleSelectService}
                  selectedItems={selectedServices[category.header]} // Pass the selected items for this category
                  project={projectName}
                />
              ))}
            </div>
          </div>

          <div className="p-4">
            {/* <div className="flex-col items-center justify-center w-full"> */}
            <h1 className="font-semibold text-xs md:text-xl text-colour_2 p-2">
              REQUEST SUMMARY
            </h1>
            <div className="text-white ">
              {Object.entries(selectedServices).map(([category, item]) => (
                <div key={category}>
                  <h3>{category}</h3>
                  <p>{item.name}</p>
                </div>
              ))}
            </div>
            <div>
              <div className="w-full md:full">
                <HistoryDisplay history={requestHistory} />
              </div>

              <div className="mt-3">
                {selectedCity && selectedSuburb && budget ? (
                  <button
                    className="w-full md:1/4 rounded-lg bg-colour_2 text-white p-4 text-xs md:text-lg"
                    onClick={setProjectDetails}
                  >
                    REQUEST QUOTATIONS
                  </button>
                ) : (
                  <div></div>
                )}
              </div>
            </div>
            {/* </div> */}
          </div>
        </div>
      ) : (
        <div className="w-full h-100px flex items-center justify-center">
          <div className="flex flex-col justify-center items-center">
            <h1 className="font-bold text-xl text-colour_2 p-8">
              Please sign in to use this feature
            </h1>

            <button
              className="w-1/2 rounded-lg bg-colour_2 text-white p-4 text-lg"
              onClick={() => {
                navigate("/sign_into_profile");
              }}
            >
              SIGN IN
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
