import React from "react";

export const StatisticsTable = React.memo(({ data }) => {
  // Helper function to calculate statistics
  const calculateStats = (fees, count) => {
    if (fees.length === 0) return { max: 0, min: 0, avg: 0, count: 0 };
    const sum = fees.reduce((a, b) => a + b, 0);
    return {
      max: Math.round(Math.max(...fees)),
      min: Math.round(Math.min(...fees)),
      avg: Math.round(sum / fees.length),
      count: count
    };
  };

  // Processing data to get callout fees and count by service
  const feesByService = {};
  data.forEach((item) => {
    item.services.forEach((service) => {
      if (!feesByService[service]) {
        feesByService[service] = { fees: [], count: 0 };
      }
      const fee = parseFloat(item.business_callout_fee);
      if (!isNaN(fee)) {
        feesByService[service].fees.push(fee);
      }
      feesByService[service].count += 1;
    });
  });

  // Calculating statistics for each service
  const statsByService = {};
  Object.keys(feesByService).forEach((service) => {
    const { fees, count } = feesByService[service];
    statsByService[service] = calculateStats(fees, count);
  });

  return (
    <table className="min-w-full border-collapse border border-gray-300">
      <thead>
        <tr>
          <th className="border border-gray-300 px-4 py-2">Service</th>
          <th className="border border-gray-300 px-4 py-2">Max Fee</th>
          <th className="border border-gray-300 px-4 py-2">Min Fee</th>
          <th className="border border-gray-300 px-4 py-2">Average Fee</th>
          <th className="border border-gray-300 px-4 py-2">Contractor Count</th>
        </tr>
      </thead>
      <tbody>
        {Object.keys(statsByService).map((service) => (
          <tr key={service}>
            <td className="border border-gray-300 px-4 py-2">{service}</td>
            <td className="border border-gray-300 px-4 py-2">{statsByService[service].max}</td>
            <td className="border border-gray-300 px-4 py-2">{statsByService[service].min}</td>
            <td className="border border-gray-300 px-4 py-2">{statsByService[service].avg}</td>
            <td className="border border-gray-300 px-4 py-2">{statsByService[service].count}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
});


export default StatisticsTable;
