import { db } from "../firebase/firebase-config";
import {
  collection,
  getDocs,
  query,
  where,
  doc,
  getDoc,
} from "firebase/firestore";

export const fetchPosts = async (userId) => {
  // const posts = new Array();
  const posts = [];

  // const q = query(collection(db, "OpenTenders"), where("clientID", "==", userId));
  const q = query(collection(db, "unpriced_jobs"));

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    let postData = doc.data();
    // console.log(postData)
    postData.postId = doc.id;
    posts.push(postData);
  });
  return { posts };
};

export const fetchPostsContractor = async (userId) => {
  // const posts = new Array();
  const posts = [];

  const q = query(
    collection(db, "unpriced_jobs"),
    where("user", "!=", userId.email),
    where("paid", "==", true)
  );

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    let postData = doc.data();
    // console.log(postData)
    postData.postId = doc.id;
    posts.push(postData);
  });
  return { posts };
};

export const fetchPostsContractorNotSignedIn = async () => {
  // const posts = new Array();
  const posts = [];

  const q = query(collection(db, "unpriced_jobs"));

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    let postData = doc.data();
    // console.log(postData)
    postData.postId = doc.id;
    posts.push(postData);
  });
  return { posts };
};

///////////////////

export const fetchPostsContractorNextGen = async (userId) => {
  // const posts = new Array();
  const posts = [];

  const q = query(
    collection(db, "unpriced_jobs_next_gen"),
    where("user", "!=", userId.email),
    // where("paid", "==", true)
  );

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    let postData = doc.data();
    // console.log(postData)
    postData.postId = doc.id;
    posts.push(postData);
  });
  return { posts };
};

export const fetchPostsContractorNotSignedInNextGen = async () => {
  // const posts = new Array();
  const posts = [];

  const q = query(collection(db, "unpriced_jobs_next_gen"));

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    let postData = doc.data();
    // console.log(postData)
    postData.postId = doc.id;
    posts.push(postData);
  });
  return { posts };
};

///////////////////
























export const fetchPostsClientPaid = async (userId) => {
  // const posts = new Array();
  const posts = [];

  const q = query(
    collection(db, "unpriced_jobs"),
    where("user", "==", userId.email),
    where("paid", "==", true)
  );

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    let postData = doc.data();
    // console.log(postData)
    postData.postId = doc.id;
    posts.push(postData);
  });
  return { posts };
};

export const fetchPostsClientUnPaid = async (userId) => {
  // const posts = new Array();
  const posts = [];

  const q = query(
    collection(db, "unpriced_jobs"),
    where("user", "==", userId.email)
    // where("paid", "==", true)
  );

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    let postData = doc.data();
    // console.log(postData)
    postData.postId = doc.id;
    posts.push(postData);
  });
  return { posts };
};

export const fetchPostsClientUnPaidNextGen = async (userId) => {
  // const posts = new Array();
  const posts = [];

  const q = query(
    collection(db, "unpriced_jobs_next_gen"),
    where("user", "==", userId.email)
    // where("paid", "==", true)
  );

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    let postData = doc.data();
    // console.log(postData)
    postData.postId = doc.id;
    posts.push(postData);
  });
  return { posts };
};

export const fetchPostsClient = async (userId) => {
  // const posts = new Array();
  const posts = [];

  const q = query(
    collection(db, "unpriced_jobs"),
    where("user", "==", userId.email)
  );

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    let postData = doc.data();
    // console.log(postData)
    postData.postId = doc.id;
    posts.push(postData);
  });
  return { posts };
};

export const fetchPostsByName = async (project) => {
  // const posts = new Array();
  const posts = [];

  const q = query(
    collection(db, "unpriced_jobs"),
    where("name", "==", project)
  );
  // const q = query(collection(db, "unpriced_jobs"));

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    let postData = doc.data();
    // console.log(postData)
    postData.postId = doc.id;
    posts.push(postData);
  });
  return { posts };
};

export const fetchPricedByName = async (project) => {
  // const posts = new Array();
  const posts = [];
  const q = query(
    collection(db, "priced_jobs"),
    where("name", "==", String(project))
  );
  // const q = query(collection(db, "unpriced_jobs"));

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    let postData = doc.data();
    // console.log(postData)
    postData.postId = doc.id;
    posts.push(postData);
  });
  return { posts };
};




export const fetchPostsByNameNextGen = async (project) => {
  // const posts = new Array();
  const posts = [];

  const q = query(
    collection(db, "unpriced_jobs_next_gen"),
    where("name", "==", project)
  );
  // const q = query(collection(db, "unpriced_jobs"));

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    let postData = doc.data();
    // console.log(postData)
    postData.postId = doc.id;
    posts.push(postData);
  });
  return { posts };
};

export const fetchPricedByNameNextGen = async (project) => {
  // const posts = new Array();
  const posts = [];
  const q = query(
    collection(db, "priced_jobs_next_gen"),
    where("name", "==", String(project))
  );
  // const q = query(collection(db, "unpriced_jobs"));

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    let postData = doc.data();
    // console.log(postData)
    postData.postId = doc.id;
    posts.push(postData);
  });
  return { posts };
};

// // Function to fetch all homeowners' emails from Firestore
// export const fetchHomeownersEmails = async () => {
//   // Initialize an empty array to hold the fetched emails
//   const emails = [];

//   // Create a query to fetch documents from the 'homeowners' collection
//   const q = query(collection(db, "homeowner"));

//   // Execute the query and get the documents
//   const querySnapshot = await getDocs(q);

//   // Iterate over each document in the snapshot
//   querySnapshot.forEach((doc) => {
//     // Get the data of the document
//     let homeownerData = doc.data();

//     // Check if the email field exists and add it to the emails array
//     if (homeownerData.email) {
//       emails.push(homeownerData.email);
//     }
//   });

//   // Return the emails array
//   return emails;
// };

// // Function to fetch all contractor emails from Firestore
// export const fetchContractorEmails = async () => {
//   // Initialize an empty array to hold the fetched emails
//   const emails = [];

//   // Create a query to fetch documents from the 'contractor' collection
//   const q = query(collection(db, "contractor"));

//   // Execute the query and get the documents
//   const querySnapshot = await getDocs(q);

//   // Iterate over each document in the snapshot
//   querySnapshot.forEach((doc) => {
//     // Get the data of the document
//     let contractorData = doc.data();

//     // Check if the email field exists and add it to the emails array
//     if (contractorData.email) {
//       emails.push(contractorData.email);
//     }
//   });

//   // Return the emails array
//   return emails;
// };

// // Function to fetch all business emails from Firestore
// export const fetchBusinessesContractorEmails = async () => {
//   // Initialize an empty array to hold the fetched business emails
//   const businessEmails = [];

//   // Create a query to fetch documents from the 'businesses_contractor' collection
//   const q = query(collection(db, "businesses_contractor"));

//   // Execute the query and get the documents
//   const querySnapshot = await getDocs(q);

//   // Iterate over each document in the snapshot
//   querySnapshot.forEach((doc) => {
//     // Get the data of the document
//     let businessData = doc.data();

//     // Check if the business_email field exists and add it to the businessEmails array
//     if (businessData.business_email) {
//       businessEmails.push(businessData.business_email);
//     }
//   });

//   // Return the businessEmails array
//   return businessEmails;
// };

// Function to fetch all homeowners' emails from Firestore
export const fetchHomeownersEmails = async () => {
  // Initialize an empty array to hold the fetched emails
  const emails = [];

  // Create a query to fetch documents from the 'homeowners' collection
  const q = query(collection(db, "homeowner"));

  // Execute the query and get the documents
  const querySnapshot = await getDocs(q);

  // Iterate over each document in the snapshot
  querySnapshot.forEach((doc) => {
    // Get the data of the document
    let homeownerData = doc.data();

    // Check if the email field exists and add it to the emails array
    if (homeownerData.email) {
      emails.push(homeownerData.email);
    }
  });

  // Return the emails array
  return emails;
};

// Function to fetch all contractor emails from Firestore
export const fetchContractorEmails = async () => {
  // Initialize an empty array to hold the fetched emails
  const emails = [];

  // Create a query to fetch documents from the 'contractor' collection
  const q = query(collection(db, "contractor"));

  // Execute the query and get the documents
  const querySnapshot = await getDocs(q);

  // Iterate over each document in the snapshot
  querySnapshot.forEach((doc) => {
    // Get the data of the document
    let contractorData = doc.data();

    // Check if the email field exists and add it to the emails array
    if (contractorData.email) {
      emails.push(contractorData.email);
    }
  });

  // Return the emails array
  return emails;
};

// Function to fetch all business emails from Firestore
export const fetchBusinessesContractorEmails = async () => {
  // Initialize an empty array to hold the fetched business emails
  const businessEmails = [];

  // Create a query to fetch documents from the 'businesses_contractor' collection
  const q = query(collection(db, "businesses_contractor"));

  // Execute the query and get the documents
  const querySnapshot = await getDocs(q);

  // Iterate over each document in the snapshot
  querySnapshot.forEach((doc) => {
    // Get the data of the document
    let businessData = doc.data();

    // Check if the business_email field exists and add it to the businessEmails array
    if (businessData.business_email) {
      businessEmails.push(businessData.business_email);
    }
  });

  // Return the businessEmails array
  return businessEmails;
};


// Function to fetch service categories
export const fetchServiceCategories = async () => {
  const categories = [];
  const categoriesCollection = collection(db, "service_categories");

  try {
    const querySnapshot = await getDocs(categoriesCollection);
    querySnapshot.forEach((doc) => {
      let categoryData = doc.data();
      categories.push(categoryData);
    });
  } catch (error) {
    console.error("Error fetching service categories: ", error);
  }

  return categories;
};
