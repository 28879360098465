// SuburbSelector.js
import React from 'react';
import { CapeTownSuburbs, JohannesburgSuburbs } from '../data/locations/Suburbs'

function SuburbSelector({ city, value, onChange }) {
    let suburbs;
    if (city === "Cape Town") {
        suburbs = CapeTownSuburbs.sort();
    } else if (city === "Johannesburg") {
        suburbs = JohannesburgSuburbs.sort();
    } else {
        suburbs = [];
    }

    return (
        <select
            className="w-full md:h-1/2 h-1/4 text-sm text-xs md:p-2 p-0 text-colour_2 font-thin rounded-lg border-4 border-colour_2 overflow-y-auto max-h-60  m-2"
            value={value}
            onChange={onChange}
            disabled={suburbs.length === 0}
        >
            <option value="">--Select a suburb--</option>
            {suburbs.map((suburb) => (
                <option key={suburb} value={suburb}>
                    {suburb}
                </option>
            ))}
        </select>
    );
}

export default SuburbSelector;
