import React from "react";
import { useEffect, useState, } from "react";
import logo from "../../data/assets/logo/openbuilder_logo.png";
import { useAuth } from "../../backend/firebase/AuthContext";

import { NavLink, useNavigate, useLocation } from "react-router-dom";

const testing_mode = true;
// const testing_mode = false;

function GenerateHTML_testing(email, phone, amount) {
  const [message, setMessage] = useState([]);

  useEffect(() => {
    fetch(
      "https://opbai98.pythonanywhere.com/form_test/" +
        email +
        "/" +
        phone +
        "/" +
        amount
    )
      .then((response) => response.json())
      .then((data) => {
        setMessage(data);
      });
  }, []);

  return message["form"];
}

function GenerateHTML_live(email, amount) {

  const location = useLocation();
  const { projectName } = location.state || {};
  const [message, setMessage] = useState([]);

  useEffect(() => {
    fetch(
      "https://opbai98.pythonanywhere.com/form_live/" +
        email +
        "/" +
        amount
        +
        "/" +
        projectName
    )
      .then((response) => response.json())
      .then((data) => {
        setMessage(data);
      });
  }, []);

  return message["form"];
}

// function GenerateHTML_live(email, phone, amount) {
//   const [message, setMessage] = useState([]);

//   useEffect(() => {
//     fetch(
//       "https://opbai98.pythonanywhere.com/form_live/" +
//         email +
//         "/" +
//         phone +
//         "/" +
//         amount
//     )
//       .then((response) => response.json())
//       .then((data) => {
//         setMessage(data);
//       });
//   }, []);

//   return message["form"];
// }

export function Payments() {
  const { user, handleSignOut } = useAuth();

  const price =250
  const price_string = "R".concat(price.toString())

  if (testing_mode) {
    var newhtml_test = GenerateHTML_live(
      user.email,
      price
    );
    console.log(newhtml_test);

    return (
      <div className="flex flex-col items-center justify-center">
        <img src={logo} className="w-48 h-48 m-10" alt="Logo" />
        <section class="py-24 lg:pb-36 overflow-hidden ">
          <div class="container px-4 mx-auto">
            <div class="flex flex-wrap items-center justify-between">
              <div class="w-auto p-2"></div>
              <div class="w-auto p-2"></div>
            </div>
            <div class="flex flex-wrap -m-8">
              <div class="w-full md:w-1/2 p-8 ">
                <div class="p-10 bg-colour_2 rounded-t-lg">
                  <h4 class="mb-2 text-xl font-semibold tracking-tight text-white">
                    Continue to the payment gateway
                  </h4>
                  <br />
                  <h4 class="mb-2 text-xl font-semibold tracking-tight text-white">
                    Included in price - Call out fee for contractor
                  </h4>
                  <br />
                  <p class="text-xl tracking-tight text-white">
                    Your price is shown below. You will be redirected to the
                    payment gateway to complete your purchase.
                  </p>
                </div>
                <div class="relative px-10 rounded-b-lg">
                  <div class="flex flex-wrap justify-between items-center py-1">
                    <div class="w-auto p-2">
                      <h3 class="text-3xl text-colour_2 font-semibold">{price_string}</h3>
                    </div>
                    <div class="w-auto p-2">
                      <p class="text-colour_2 font-medium tracking-tight">
                        Once off payment
                      </p>
                      <p class="text-colour_2 font-medium tracking-tight">
                        Please wait the pay now button to load
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="payment-container">
          <button className="border-2 w-100px rounded-lg bg-colour_2 text-white p-2 text-lg m-4 p-6">
            <div dangerouslySetInnerHTML={{ __html: newhtml_test }} />
          </button>
        </div>
      </div>
    );
  }
  if (!testing_mode) {
    var newhtml_live = GenerateHTML_live(
      "siraaj62@outlook.com",
      "0815872081",
      "200"
    );
    console.log(newhtml_live);

    return (
      <div className="flex flex-col items-center justify-center">
        <img src={logo} className="w-48 h-48 m-10" alt="Logo" />
        <section class="py-24 lg:pb-36 overflow-hidden ">
          <div class="container px-4 mx-auto">
            <div class="flex flex-wrap items-center justify-between">
              <div class="w-auto p-2"></div>
              <div class="w-auto p-2"></div>
            </div>
            <div class="flex flex-wrap -m-8">
              <div class="w-full md:w-1/2 p-8 ">
                <div class="p-10 bg-colour_2 rounded-t-lg">
                  <h4 class="mb-2 text-xl font-semibold tracking-tight text-white">
                    Continue to the payment gateway
                  </h4>
                  <p class="text-xl tracking-tight text-white">
                    Your price is shown below. You will be redirected to the
                    payment gateway to complete your purchase.
                  </p>
                </div>
                <div class="relative px-10 rounded-b-lg">
                  <div class="flex flex-wrap justify-between items-center py-1">
                    <div class="w-auto p-2">
                      <h3 class="text-3xl text-colour_2 font-semibold">
                        R2000
                      </h3>
                    </div>
                    <div class="w-auto p-2">
                      <p class="text-colour_2 font-medium tracking-tight">
                        Once off payment
                      </p>
                      <p class="text-colour_2 font-medium tracking-tight">
                        Please wait the pay now button to load
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="payment-container">
          <button className="border-2 w-100px rounded-lg bg-colour_2 text-white p-2 text-lg m-4 p-6">
            <div dangerouslySetInnerHTML={{ __html: newhtml_live }} />
          </button>
        </div>
      </div>
    );
  }
}
