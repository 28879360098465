import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import homeowner from "../../data/assets/background/homeowner.png";
import designer from "../../data/assets/background/designer.jpg";
import contractor from "../../data/assets/background/contractor.jpg";

import material_supplier from "../../data/assets/background/material_supplier.png";

import Wrench from "../../data/assets/icons/Wrench.png";
import HouseGarden from "../../data/assets/icons/HouseWithGarden.png";
import Design from "../../data/assets/icons/Design.png";
import ConstructionPlan from "../../data/assets/icons/ConstructionPlan.png";
import Crane from "../../data/assets/icons/Constructing.png";
import HandPen from "../../data/assets/icons/Hand_With_Pen_1.png";

import Receipt from "../../data/assets/icons/Receipt.png";
import Buying from "../../data/assets/icons/Buying.png";

import Supplier from "../../data/assets/background/material_supplier.png"; // Add an appropriate icon for suppliers

export function UniversalDashboard() {
  const navigate = useNavigate();
  const [isHomeownerHovered, setHomeownerHovered] = useState(false);
  const [isDesignerHovered, setDesignerHovered] = useState(false);
  const [isContractorHovered, setContractorHovered] = useState(false);
  const [isSupplierHovered, setSupplierHovered] = useState(false);

  return (
    <div className="flex flex-col md:flex-row h-screen relative">
      <div
        className="flex-1"
        onMouseEnter={() => setHomeownerHovered(true)}
        onMouseLeave={() => setHomeownerHovered(false)}
      >
        <div
          className="w-full h-full bg-cover bg-center transition-all duration-300 p-5"
          style={{
            backgroundImage: `url(${homeowner})`,
            filter: isHomeownerHovered ? "" : "grayscale(100%)",
          }}
        >
          <div className="pt-8 w-full text-center bg-neutral-300 rounded-lg">
            <h1
              className={`font-bold text-xl md:text-3xl lg:text-4xl ${
                isHomeownerHovered ? "text-colour-2" : "text-white"
              }`}
            >
              HOMEOWNERS
            </h1>
          </div>
          <div className="flex flex-col md:flex-row justify-between items-center mt-2 bg-opacity-70 bg-white rounded-lg p-2 md:p-4">
            <img src={Wrench} className="w-1/12 h-auto" alt="Wrench" />
            <h1 className="font-semibold text-sm md:text-lg lg:text-xl text-colour_2 p-2 md:p-4">
              LOOK AT VARIOUS OPTIONS TO UPGRADE YOUR HOME
            </h1>
            <div>
              <button
                className="rounded-lg bg-colour_2 text-white p-2 md:p-4 text-xs md:text-sm lg:text-md w-full"
                onClick={() => {
                  navigate("/client_build_alteration");
                }}
              >
                Do alterations to your existing home
              </button>
            </div>
          </div>

          <div className="flex flex-col md:flex-row justify-between items-center mt-2 bg-opacity-70 bg-white rounded-lg p-2 md:p-4">
            <img
              src={HouseGarden}
              className="w-1/12 h-auto"
              alt="House with Garden"
            />
            <h1 className="font-semibold text-sm md:text-lg lg:text-xl text-colour_2 p-2 md:p-4">
              VIEW YOUR HOME ALTERATION PROGRESS
            </h1>
            <button
              className="rounded-lg bg-colour_2 text-white p-2 md:p-4 text-xs md:text-sm lg:text-md"
              onClick={() => navigate("/client_projects")}
            >
              View your home improvements
            </button>
          </div>
        </div>
      </div>

      <div
        className="flex-1"
        onMouseEnter={() => setDesignerHovered(true)}
        onMouseLeave={() => setDesignerHovered(false)}
      >
        <div
          className="w-full h-full bg-cover bg-center transition-all duration-300 p-5"
          style={{
            backgroundImage: `url(${designer})`,
            filter: isDesignerHovered ? "" : "grayscale(100%)",
          }}
        >
          <div className="pt-8 w-full text-center bg-neutral-300 rounded-lg">
            <h1
              className={`font-bold text-xl md:text-3xl lg:text-4xl ${
                isDesignerHovered ? "text-black" : "text-white"
              }`}
            >
              DESIGNERS
            </h1>
          </div>
          <div className="flex flex-col md:flex-row justify-between items-center mt-2 bg-opacity-70 bg-white rounded-lg p-2 md:p-4">
            <img
              src={ConstructionPlan}
              className="w-1/12 h-auto"
              alt="ConstructionPlan"
            />
            <h1 className="font-semibold text-sm md:text-lg lg:text-xl text-colour_2 p-2 md:p-4">
              VIEW DESIGN OPPORTUNITIES
            </h1>
            <div className="w-100">
              <button
                className="rounded-lg bg-colour_2 text-white p-2 md:p-4 text-xs md:text-sm lg:text-md"
                onClick={() => {
                  alert("Coming soon!");
                }}
              >
                View design projects
              </button>
            </div>
          </div>

          <div className="flex flex-col md:flex-row justify-between items-center mt-2 bg-opacity-70 bg-white rounded-lg p-2 md:p-4">
            <img src={Design} className="w-1/12 h-auto" alt="Design" />
            <h1 className="font-semibold text-sm md:text-lg lg:text-xl text-colour_2 p-2 md:p-4">
              LIST YOUR COMPANY DESIGN SERVICES AND REGISTRATIONS
            </h1>
            <button
              className="rounded-lg bg-colour_2 text-white p-2 md:p-4 text-xs md:text-sm lg:text-md"
              onClick={() => {
                navigate("/create_business_profile_designer");
              }}            >
              List your design services company
            </button>
          </div>
        </div>
      </div>

      <div
        className="flex-1"
        onMouseEnter={() => setContractorHovered(true)}
        onMouseLeave={() => setContractorHovered(false)}
      >
        <div
          className="w-full h-full bg-cover bg-center transition-all duration-300 p-5"
          style={{
            backgroundImage: `url(${contractor})`,
            filter: isContractorHovered ? "" : "grayscale(100%)",
          }}
        >
          <div className="pt-8 w-full text-center bg-neutral-300 rounded-lg">
            <h1
              className={`font-bold text-xl md:text-3xl lg:text-4xl ${
                isContractorHovered ? "text-black" : "text-white"
              }`}
            >
              CONTRACTORS
            </h1>
          </div>

          <div className="flex flex-col md:flex-row justify-between items-center mt-2 bg-opacity-70 bg-white rounded-lg p-2 md:p-4">
            <img src={Crane} className="w-1/12 h-auto" alt="Crane" />
            <h1 className="font-semibold text-sm md:text-lg lg:text-xl text-colour_2 p-2 md:p-4">
              VIEW CONSTRUCTION OPPORTUNITIES
            </h1>
            <button
              className="rounded-lg bg-colour_2 text-white p-2 md:p-4 text-xs md:text-sm lg:text-md"
              onClick={() => navigate("/contractor_opportunities")}
            >
              View construction projects
            </button>
          </div>

          <div className="flex flex-col md:flex-row justify-between items-center mt-2 bg-opacity-70 bg-white rounded-lg p-2 md:p-4">
            <img src={HandPen} className="w-1/12 h-auto" alt="HandPen" />
            <h1 className="font-semibold text-sm md:text-lg lg:text-xl text-colour_2 p-2 md:p-4">
              LIST YOUR CONSTRUCTION BUSINESS AND CAPABILITIES
            </h1>
            <div className="w-100">
              <button
                className="rounded-lg bg-colour_2 text-white p-2 md:p-4 text-xs md:text-sm lg:text-md"
                onClick={() => {
                  navigate("/create_business_profile");
                }}
              >
                List your contracting business
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="flex-1"
        onMouseEnter={() => setSupplierHovered(true)}
        onMouseLeave={() => setSupplierHovered(false)}
      >
        <div
          className="w-full h-full bg-cover bg-center transition-all duration-300 p-5"
          style={{
            backgroundImage: `url(${material_supplier})`, // Using the same background as contractor
            filter: isSupplierHovered ? "" : "grayscale(100%)",
          }}
        >
          <div className="pt-8 w-full text-center bg-neutral-300 rounded-lg">
            <h1
              className={`font-bold text-xl md:text-3xl lg:text-4xl ${
                isSupplierHovered ? "text-black" : "text-white"
              }`}
            >
              MATERIAL SUPPLIERS
            </h1>
          </div>
          <div className="flex flex-col md:flex-row justify-between items-center mt-2 bg-opacity-70 bg-white rounded-lg p-2 md:p-4">
            <img src={Receipt} className="w-1/12 h-auto" alt="Supplier" />
            <h1 className="font-semibold text-sm md:text-lg lg:text-xl text-colour_2 p-2 md:p-4">
              VIEW SUPPLY OPPORTUNITIES
            </h1>
            <button
              className="rounded-lg bg-colour_2 text-white p-2 md:p-4 text-xs md:text-sm lg:text-md"
              onClick={() => alert("Coming soon!")}
            >
              View supply projects
            </button>
          </div>

          <div className="flex flex-col md:flex-row justify-between items-center mt-2 bg-opacity-70 bg-white rounded-lg p-2 md:p-4">
            <img src={Buying} className="w-1/12 h-auto" alt="Supplier" />
            <h1 className="font-semibold text-sm md:text-lg lg:text-xl text-colour_2 p-2 md:p-4">
              LIST YOUR SUPPLY BUSINESS AND PRODUCTS
            </h1>
            <div className="w-100">
              <button
                className="rounded-lg bg-colour_2 text-white p-2 md:p-4 text-xs md:text-sm lg:text-md"
                // navigate to create_business_profile_material_supplier

                onClick={() => {
                  navigate("/create_business_profile_material_supplier");
                }}
              >
                List your supply business
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
// import homeowner from "../../data/assets/background/homeowner.png";
// import designer from "../../data/assets/background/designer.jpg";
// import contractor from "../../data/assets/background/contractor.jpg";
// import Wrench from "../../data/assets/icons/Wrench.png";
// import HouseGarden from "../../data/assets/icons/HouseWithGarden.png";
// import Design from "../../data/assets/icons/Design.png";
// import ConstructionPlan from "../../data/assets/icons/ConstructionPlan.png";
// import Crane from "../../data/assets/icons/Constructing.png";
// import HandPen from "../../data/assets/icons/Hand_With_Pen_1.png";

// export function UniversalDashboard() {
//   const navigate = useNavigate();
//   const [isHomeownerHovered, setHomeownerHovered] = useState(false);
//   const [isDesignerHovered, setDesignerHovered] = useState(false);
//   const [isContractorHovered, setContractorHovered] = useState(false);

//   return (
//     <div className="flex flex-col md:flex-row h-screen relative">
//       <div
//         className="flex-1"
//         onMouseEnter={() => setHomeownerHovered(true)}
//         onMouseLeave={() => setHomeownerHovered(false)}
//       >
//         <div
//           className="w-full h-full bg-cover bg-center transition-all duration-300 p-5"
//           style={{
//             backgroundImage: `url(${homeowner})`,
//             filter: isHomeownerHovered ? "" : "grayscale(100%)",
//           }}
//         >
//           <div className="pt-8 w-full text-center bg-neutral-300 rounded-lg">
//             <h1
//               className={`font-bold text-xl md:text-3xl lg:text-4xl ${
//                 isHomeownerHovered ? "text-colour-2" : "text-white"
//               }`}
//             >
//               HOMEOWNERS
//             </h1>
//           </div>
//           <div className="flex flex-col md:flex-row justify-between items-center mt-2 bg-opacity-70 bg-white rounded-lg p-2 md:p-4">
//             {/* Image */}
//             <img src={Wrench} className="w-1/12 h-auto" alt="Wrench" />

//             {/* Heading */}
//             <h1 className="font-semibold text-sm md:text-lg lg:text-xl text-colour_2 p-2 md:p-4">
//               LOOK AT VARIOUS OPTIONS TO UPGRADE YOUR HOME
//             </h1>

//             {/* Button */}
//             <div>
//               <button
//                 className="rounded-lg bg-colour_2 text-white p-2 md:p-4 text-xs md:text-sm lg:text-md w-full"
//                 onClick={() => {
//                   navigate("/client_build_alteration");
//                 }}
//               >
//                 Do alterations to your existing home
//               </button>
//             </div>
//           </div>

//           <div className="flex flex-col md:flex-row justify-between items-center mt-2 bg-opacity-70 bg-white rounded-lg p-2 md:p-4">
//             <img
//               src={HouseGarden}
//               className="w-1/12 h-auto"
//               alt="House with Garden"
//             />
//             <h1 className="font-semibold text-sm md:text-lg lg:text-xl text-colour_2 p-2 md:p-4">
//               VIEW YOUR HOME ALTERATION PROGRESS
//             </h1>
//             <button
//               className="rounded-lg bg-colour_2 text-white p-2 md:p-4 text-xs md:text-sm lg:text-md"
//               onClick={() => navigate("/client_projects")}
//             >
//               View your home improvements
//             </button>
//           </div>
//         </div>
//       </div>

//       <div
//         className="flex-1"
//         onMouseEnter={() => setDesignerHovered(true)}
//         onMouseLeave={() => setDesignerHovered(false)}
//       >
//         <div
//           className="w-full h-full bg-cover bg-center transition-all duration-300 p-5"
//           style={{
//             backgroundImage: `url(${designer})`,
//             filter: isDesignerHovered ? "" : "grayscale(100%)",
//           }}
//         >
//           <div className="pt-8 w-full text-center bg-neutral-300 rounded-lg">
//             <h1
//               className={`font-bold text-xl md:text-3xl lg:text-4xl ${
//                 isDesignerHovered ? "text-black" : "text-white"
//               }`}
//             >
//               DESIGNERS
//             </h1>
//           </div>
//           <div className="flex flex-col md:flex-row justify-between items-center mt-2 bg-opacity-70 bg-white rounded-lg p-2 md:p-4">
//             {/* Image */}
//             <img
//               src={ConstructionPlan}
//               className="w-1/12 h-auto"
//               alt="ConstructionPlan"
//             />

//             {/* Heading */}
//             <h1 className="font-semibold text-sm md:text-lg lg:text-xl text-colour_2 p-2 md:p-4">
//               VIEW DESIGN OPPORTUNITIES
//             </h1>

//             {/* Button */}
//             <div className="w-100">
//               <button
//                 className="rounded-lg bg-colour_2 text-white p-2 md:p-4 text-xs md:text-sm lg:text-md"
//                 onClick={() => {
//                   alert("Coming soon!");
//                 }}
//               >
//                 View design projects
//               </button>
//             </div>
//           </div>

//           <div className="flex flex-col md:flex-row justify-between items-center mt-2 bg-opacity-70 bg-white rounded-lg p-2 md:p-4">
//             <img src={Design} className="w-1/12 h-auto" alt="Design" />
//             <h1 className="font-semibold text-sm md:text-lg lg:text-xl text-colour_2 p-2 md:p-4">
//               LIST YOUR COMPANY DESIGN SERVICES AND REGISTRATIONS
//             </h1>
//             <button
//               className="rounded-lg bg-colour_2 text-white p-2 md:p-4 text-xs md:text-sm lg:text-md"
//               onClick={() => alert("Coming soon!")}
//             >
//               List your design services company
//             </button>
//           </div>

//           {/* Additional content sections as needed... */}
//         </div>
//       </div>

//       <div
//         className="flex-1"
//         onMouseEnter={() => setContractorHovered(true)}
//         onMouseLeave={() => setContractorHovered(false)}
//       >
//         <div
//           className="w-full h-full bg-cover bg-center transition-all duration-300 p-5"
//           style={{
//             backgroundImage: `url(${contractor})`,
//             filter: isContractorHovered ? "" : "grayscale(100%)",
//           }}
//         >
//           <div className="pt-8 w-full text-center bg-neutral-300 rounded-lg">
//             <h1
//               className={`font-bold text-xl md:text-3xl lg:text-4xl ${
//                 isContractorHovered ? "text-black" : "text-white"
//               }`}
//             >
//               CONTRACTORS
//             </h1>
//           </div>

//           <div className="flex flex-col md:flex-row justify-between items-center mt-2 bg-opacity-70 bg-white rounded-lg p-2 md:p-4">
//             <img src={Crane} className="w-1/12 h-auto" alt="Crane" />
//             <h1 className="font-semibold text-sm md:text-lg lg:text-xl text-colour_2 p-2 md:p-4">
//               VIEW CONSTRUCTION OPPORTUNITIES
//             </h1>
//             <button
//               className="rounded-lg bg-colour_2 text-white p-2 md:p-4 text-xs md:text-sm lg:text-md"
//               onClick={() => navigate("/contractor_opportunities")}
//             >
//               View construction projects
//             </button>
//           </div>

//           <div className="flex flex-col md:flex-row justify-between items-center mt-2 bg-opacity-70 bg-white rounded-lg p-2 md:p-4">
//             {/* Image */}
//             <img
//               src={HandPen}
//               className="w-1/12 h-auto"
//               alt="Crane"
//               alt="HandPen"
//             />

//             {/* Heading */}
//             <h1 className="font-semibold text-sm md:text-lg lg:text-xl text-colour_2 p-2 md:p-4">
//               LIST YOUR CONSTRUCTION BUSINESS AND CAPABILITIES
//             </h1>

//             {/* Button */}
//             <div className="w-100">
//               <button
//                 className="rounded-lg bg-colour_2 text-white p-2 md:p-4 text-xs md:text-sm lg:text-md"
//                 // onClick={() => { navigate('/client_new_build') }}
//                 onClick={() => {
//                   navigate("/create_business_profile");
//                   // alert("Coming soon");
//                 }}
//               >
//                 List your contracting business
//               </button>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }
