// import React, { useEffect, useState } from 'react';
// import { fetchServiceCategories } from './path/to/your/fetchFunction';

// const ServiceCategories = () => {
//   const [servicesData, setServicesData] = useState([]);

//   useEffect(() => {
//     const fetchData = async () => {
//       const data = await fetchServiceCategories();
//       setServicesData(data);
//     };

//     fetchData();
//   }, []);

//   return (
//     <div>
//       {servicesData.map((category) => (
//         <div key={category.header}>
//           <h2>{category.header}</h2>
//           <ul>
//             {category.items.map((item) => (
//               <li key={item.id}>{item.name}</li>
//             ))}
//           </ul>
//         </div>
//       ))}
//     </div>
//   );
// };

// export default ServiceCategories;



















// Data structures for service categories with unique IDs for each item
export const servicesData = [
  {
    header: "ELECTRICAL",
    items: [
      { id: "10000", name: "NEW RSA SOCKET OUTLETS" },
      { id: "10001", name: "NEW LIGHTING FIXTURES" },
      { id: "1000X", name: "CUSTOM ITEM" },
      // ... other items
    ],
  },
  {
    header: "PLUMBING",
    items: [
      { id: "20000", name: "NEW DRAINAGE POINTS" },
      { id: "20001", name: "NEW TAP POINT" },
      { id: "2000X", name: "CUSTOM ITEM" },
      // ... other items
    ],
  },

  {
    header: "TILING",
    items: [
      { id: "40000", name: "NEW TILING" },
      { id: "40001", name: "REMOVE TILING" },
      { id: "4000X", name: "CUSTOM ITEM" },
      // ... other items
    ],
  },

  {
    header: "STRUCTURAL",
    items: [
      { id: "30000", name: "NEW BRICK WALL" },
      { id: "30001", name: "NEW STAIRS" },
      { id: "3000X", name: "CUSTOM ITEM" },
      // ... other items
    ],
  },


  // ... other categories
];


