import React from "react";

const ButtonArray = ({
  buttons,
  activeButtonIndices,
  onActiveButtonIndicesChange,
}) => {
  const handleClick = (index) => {
    const updatedIndices = [...activeButtonIndices];

    if (updatedIndices.includes(index)) {
      // Button is already active, so remove it
      const indexToRemove = updatedIndices.indexOf(index);
      updatedIndices.splice(indexToRemove, 1);
    } else {
      // Button is not active, so add it
      updatedIndices.push(index);
    }

    onActiveButtonIndicesChange(updatedIndices);
  };

  return (
    <div className="w-full p-2">
      {buttons.map((button, index) => (
        <button
          key={index}
          onClick={() => handleClick(index)}
          className={`${
            activeButtonIndices.includes(index)
              ? "border-2 rounded-lg border-colour_2 bg-colour_2 text-white text-xs"
              : "border-2 rounded-lg border-colour_2 bg-white text-colour_2 text-xs"
          } block w-full p-1 mb-1 md:mb-2`}
        >
          {button.text}
        </button>
      ))}
    </div>
  );
};

export default ButtonArray;

// import React from 'react';

// const ButtonArray = ({ buttons, activeButtonIndices, onActiveButtonIndicesChange }) => {
//   const handleClick = (index) => {
//     const updatedIndices = [...activeButtonIndices];

//     if (updatedIndices.includes(index)) {
//       // Button is already active, so remove it
//       const indexToRemove = updatedIndices.indexOf(index);
//       updatedIndices.splice(indexToRemove, 1);
//     } else {
//       // Button is not active, so add it
//       updatedIndices.push(index);
//     }

//     onActiveButtonIndicesChange(updatedIndices);
//   };

//   return (
//     <div>
//       {buttons.map((button, index) => (
//         <button
//           key={index}
//           onClick={() => handleClick(index)}
//           style={{
//             backgroundColor: activeButtonIndices.includes(index)
//               ? '#171658'
//               : 'transparent',
//             color: activeButtonIndices.includes(index) ? 'white' : 'inherit',
//           }}
//         >
//           {button.text}
//         </button>
//       ))}
//     </div>
//   );
// };

// export default ButtonArray;
// ? "border-colour_2 bg-colour_2 text-white"
// : "border-colour_2 bg-white text-colour_2"
