import React from "react";
import { Line } from "react-chartjs-2";
import Chart from "chart.js/auto";

export const LineChart = React.memo(({ data }) => {
  // Function to convert timestamp to YYYY-MM format
  const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000); // Convert to milliseconds
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // Months are 0-indexed
    return `${year}-${month.toString().padStart(2, "0")}`;
  };

  // Count contractors per month
  const monthlyCounts = {};
  data.forEach((item) => {
    const month = formatDate(item.date_joined.seconds);
    monthlyCounts[month] = (monthlyCounts[month] || 0) + 1;
  });

  // Sort the months
  const sortedMonths = Object.keys(monthlyCounts).sort();

  // Calculate cumulative counts
  let cumulativeCount = 0;
  const cumulativeCounts = sortedMonths.map((month) => {
    cumulativeCount += monthlyCounts[month];
    return cumulativeCount;
  });

  // Chart data
  const chartData = {
    labels: sortedMonths,
    datasets: [
      {
        label: "Cumulative Contractors",
        data: cumulativeCounts,
        fill: false,
        borderColor: "rgb(75, 192, 192)",
        tension: 0.1,
      },
    ],
  };

  // Chart options
  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return <Line data={chartData} options={options} />;
});

export default LineChart;
