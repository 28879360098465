import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from '../../backend/firebase/firebase-config';

const Upload = async (path, file, blob, onProgress) => {
    const metadata = {
        contentType: file.type,
    };

    const storageRef = ref(storage, path + file.name);
    const uploadTask = uploadBytesResumable(storageRef, blob, metadata);

    return new Promise((resolve, reject) => {
        uploadTask.on('state_changed',
            (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                onProgress(progress);
            }, 
            (error) => {
                reject(error);
            }, 
            async () => {
                try {
                    const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                    console.log('File available at', downloadURL);
                    resolve(downloadURL);
                } catch (error) {
                    reject(error);
                }
            }
        );
    });
}

export default Upload;




















// import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
// import { storage } from '../../backend/firebase/firebase-config';

// const Upload = async (path, file, blob) => {

//     // Create the file metadata dynamically based on the file type
//     const metadata = {
//         contentType: file.type // Set the content type from the file
//     };

//     // Upload file and metadata to the object using the given path
//     const storageRef = ref(storage, path + file.name);
//     const uploadTask = uploadBytesResumable(storageRef, blob, metadata); // Pass "metadata"

//     return new Promise((resolve, reject) => {
//         uploadTask.on('state_changed',
//             (snapshot) => {
//                 // You can handle progress or state changes here if needed
//             },
//             (error) => {
//                 // Handle the error
//                 reject(error);
//             },
//             async () => {
//                 // Upload completed successfully, now we can get the download URL
//                 try {
//                     const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
//                     console.log('File available at', downloadURL);
//                     resolve(downloadURL);
//                 } catch (error) {
//                     reject(error);
//                 }
//             }
//         );
//     });
// }

// export default Upload;














// import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
// import { storage } from '../../backend/firebase/firebase-config';

// // Create the file metadata 
// // /** @type {any} */
// // const metadata = {
// //     contentType: 'image/jpeg'
// // };

// const Upload = async (path, file, blob) => { // Added "path" parameter

//     console.log('From Testing')
//     console.log(file)
//     console.log(blob)

//     // Create the file metadata dynamically based on the file type
//     const metadata = {
//         contentType: file.type // Set the content type from the file
//     };

//     // Upload file and metadata to the object using the given path
//     const storageRef = ref(storage, path + file.name);
//     const uploadTask = uploadBytesResumable(storageRef, blob, metadata); // Pass "metadata"


//     // Listen for state changes, errors, and completion of the upload.
//     uploadTask.on('state_changed',
//         (snapshot) => {
//             console.log('Snapshot:', snapshot); // Log the snapshot object
//             // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
//             const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
//             console.log('Upload is ' + progress + '% done');
//             switch (snapshot.state) {
//                 case 'paused':
//                     console.log('Upload is paused');
//                     break;
//                 case 'running':
//                     console.log('Upload is running');
//                     break;
//             }
//         },
//         (error) => {
//             // A full list of error codes is available at
//             // https://firebase.google.com/docs/storage/web/handle-errors
//             switch (error.code) {
//                 case 'storage/unauthorized':
//                     // User doesn't have permission to access the object
//                     break;
//                 case 'storage/canceled':
//                     // User canceled the upload
//                     break;

//                 // ...

//                 case 'storage/unknown':
//                     // Unknown error occurred, inspect error.serverResponse
//                     break;
//             }
//         },
//         () => {
//             // Upload completed successfully, now we can get the download URL
//             getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
//                 console.log('File available at', downloadURL);
//             });
//         }
//     );
// }
// export default Upload;


// import { getStorage, ref, uploadBytes, uploadBytesResumable, getDownloadURL } from "firebase/storage";
// import { storage } from '../../backend/firebase/firebase-config';

// // Create the file metadata 
// /** @type {any} */
// const metadata = {
//     contentType: 'image/jpeg'
// };

// const Upload = async (file, blob) => {

//     console.log('From Testing')
//     console.log(file)
//     console.log(blob)

//     // Upload file and metadata to the object 'images/mountains.jpg'
//     const storageRef = ref(storage, 'images/' + file.name);
//     // const storageRef = ref(storage, 'website/' + file.name);
//     const uploadTask = uploadBytesResumable(storageRef, blob);

//     // Listen for state changes, errors, and completion of the upload.
//     uploadTask.on('state_changed',
//         (snapshot) => {
//             // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
//             const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
//             console.log('Upload is ' + progress + '% done');
//             switch (snapshot.state) {
//                 case 'paused':
//                     console.log('Upload is paused');
//                     break;
//                 case 'running':
//                     console.log('Upload is running');
//                     break;
//             }
//         },
//         (error) => {
//             // A full list of error codes is available at
//             // https://firebase.google.com/docs/storage/web/handle-errors
//             switch (error.code) {
//                 case 'storage/unauthorized':
//                     // User doesn't have permission to access the object
//                     break;
//                 case 'storage/canceled':
//                     // User canceled the upload
//                     break;

//                 // ...

//                 case 'storage/unknown':
//                     // Unknown error occurred, inspect error.serverResponse
//                     break;
//             }
//         },
//         () => {
//             // Upload completed successfully, now we can get the download URL
//             getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
//                 console.log('File available at', downloadURL);
//             });
//         }
//     );


// }
// export default Upload;