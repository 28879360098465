import React, { useState, useEffect } from "react";
import {
  fetchPostsContractorNextGen,
  fetchPostsContractorNotSignedInNextGen,
} from "../../../backend/lists/fetchData";
import { useAuth } from "../../../backend/firebase/AuthContext";
import { useNavigate } from "react-router-dom";

export function ContractorOpportunitiesNextGen() {
  const { user, handleSignOut } = useAuth();
  const navigate = useNavigate();
  const [posts, setPosts] = useState([]);

  async function getPosts() {
    if (user) {
      const postsData = await fetchPostsContractorNextGen(user);
      setPosts(postsData.posts);
    } else {
      // Handle the scenario when user is null, maybe set an error or redirect
      const postsData = await fetchPostsContractorNotSignedInNextGen();
      setPosts(postsData.posts);
    }
  }

  useEffect(() => {
    getPosts();
  }, []); // Empty dependency array ensures this runs only once

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 9; // 9 items per page to fit the 3x3 grid
  const totalPages = Math.ceil(posts.length / itemsPerPage);

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const currentItems = posts.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // +1 because months are 0-indexed
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };

  console.log(posts);
  const serviceColors = {
    electrical: "bg-blue-200",
    plumbing: "bg-yellow-100",
    tiling: "bg-blue-50",
    structural: "bg-indigo-200",
  };

  const listItemClassNames = "p-2 mb-2 rounded text-black border border-gray-300";

  // Check if the user is signed in
  if (!user) {
    return (
      <div className="max-w-full mx-auto p-4">
        <h1 className="text-center text-3xl font-semibold mb-4 text-colour_2">
          Projects Available for Pricing
        </h1>
        {posts.length === 0 ? (
          // Display this message if there are no posts
          <div className="text-center my-10">
            <h2 className="text-center text-xl w-full">
              No open home alterations
            </h2>
          </div>
        ) : (
          // Otherwise, display the grid and the posts
          <>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              {currentItems.map((post) => (
                <div
                  key={post.postId}
                  className="border rounded-lg p-4 flex flex-col bg-colour_2 text-white"
                >
                  <span className="text-xl">{post.city}</span>
                  <span>Budget: {post.budget}</span>
                  <span>
                    Date Created: {formatDate(post.date_created.seconds)}
                  </span>
                  <span>Suburb: {post.suburb}</span>
                  <span>Services:</span>
                  <ul>
                    {post.requests
                      .sort((a, b) => a.header.localeCompare(b.header))
                      .map((request) => (
                        <li
                          key={request.item.id}
                          className={`${listItemClassNames} ${serviceColors[request.header.toLowerCase()] || "bg-gray-100"}`}
                        >
                          {`${request.header}: ${request.item.name}`}
                        </li>
                      ))}
                  </ul>
                  <button
                    className="mt-auto bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    onClick={() => alert("Please sign in to see this project")}
                  >
                    Go to Project
                  </button>
                </div>
              ))}
            </div>
            <div className="pagination-controls mt-4 flex justify-center items-center space-x-2">
              <button
                onClick={handlePrev}
                className="bg-colour_2 text-white rounded-lg p-2"
              >
                Previous
              </button>
              <span>
                Page {currentPage} of {totalPages}
              </span>
              <button
                onClick={handleNext}
                className="bg-colour_2 text-white rounded-lg p-2"
              >
                Next
              </button>
            </div>
          </>
        )}
      </div>
    );
  }

  if (user) {
    return (
      <div className="max-w-full mx-auto p-4">
        <h1 className="text-center text-3xl font-semibold mb-4 text-colour_2">
          Projects Available for Pricing
        </h1>
        {posts.length === 0 ? (
          // Display this message if there are no posts
          <div className="text-center my-10">
            <h2 className="text-center text-xl w-full">
              No open home alterations
            </h2>
          </div>
        ) : (
          // Otherwise, display the grid and the posts
          <>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              {currentItems.map((post) => (
                <div
                  key={post.postId}
                  className="border rounded-lg p-4 flex flex-col bg-colour_2 text-white"
                >
                  <span className="text-xl">{post.city}</span>
                  <span>Budget: {post.budget}</span>
                  <span>
                    Date Created: {formatDate(post.date_created.seconds)}
                  </span>
                  <span>Suburb: {post.suburb}</span>
                  <span>Services:</span>
                  <ul>
                    {post.requests
                      .sort((a, b) => a.header.localeCompare(b.header))
                      .map((request) => (
                        <li
                          key={request.item.id}
                          className={`${listItemClassNames} ${serviceColors[request.header.toLowerCase()] || "bg-gray-100"}`}
                        >
                          {`${request.header}: ${request.item.name}`}
                        </li>
                      ))}
                  </ul>
                  <button
                    className="mt-auto bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    onClick={() =>
                      navigate(`/contractor_pricing_editor_next_gen/${post.postId}`, {
                        state: { id: post.postId, project: post.name },
                      })
                    }
                  >
                    Go to Project
                  </button>
                </div>
              ))}
            </div>
            <div className="pagination-controls mt-4 flex justify-center items-center space-x-2">
              <button
                onClick={handlePrev}
                className="bg-colour_2 text-white rounded-lg p-2"
              >
                Previous
              </button>
              <span>
                Page {currentPage} of {totalPages}
              </span>
              <button
                onClick={handleNext}
                className="bg-colour_2 text-white rounded-lg p-2"
              >
                Next
              </button>
            </div>
          </>
        )}
      </div>
    );
  }
}


// import React, { useState, useEffect } from "react";
// import {
//   fetchPostsContractorNextGen,
//   fetchPostsContractorNotSignedInNextGen,
// } from "../../../backend/lists/fetchData";
// import { useAuth } from "../../../backend/firebase/AuthContext";
// import { useNavigate } from "react-router-dom";

// export function ContractorOpportunitiesNextGen() {
//   const { user, handleSignOut } = useAuth();

//   const navigate = useNavigate();
//   const [posts, setPosts] = useState([]);

//   async function getPosts() {
//     if (user) {
//       const postsData = await fetchPostsContractorNextGen(user);
//       setPosts(postsData.posts);
//     } else {
//       // Handle the scenario when user is null, maybe set an error or redirect
//       const postsData = await fetchPostsContractorNotSignedInNextGen();
//       setPosts(postsData.posts);
//     }
//   }

//   useEffect(() => {
//     getPosts();
//   });

//   const [currentPage, setCurrentPage] = useState(1);
//   const itemsPerPage = 9; // 9 items per page to fit the 3x3 grid

//   const totalPages = Math.ceil(posts.length / itemsPerPage);

//   const handleNext = () => {
//     if (currentPage < totalPages) {
//       setCurrentPage(currentPage + 1);
//     }
//   };

//   const handlePrev = () => {
//     if (currentPage > 1) {
//       setCurrentPage(currentPage - 1);
//     }
//   };

//   const currentItems = posts.slice(
//     (currentPage - 1) * itemsPerPage,
//     currentPage * itemsPerPage
//   );

//   const formatDate = (timestamp) => {
//     const date = new Date(timestamp * 1000);
//     const day = String(date.getDate()).padStart(2, "0");
//     const month = String(date.getMonth() + 1).padStart(2, "0"); // +1 because months are 0-indexed
//     const year = date.getFullYear();

//     return `${day}/${month}/${year}`;
//   };

//   console.log(posts);
//   const serviceColors = {
//     Builder: "#ffcccb",
//     Electrical: "#add8e6",
//     Interior: "#90ee90",
//     Joinery: "#ffb6c1",
//     Roofing: "#f0e68c",
//     Painting: "#dda0dd",
//     Plumbing: "#ffdead",
//     Glasswork: "#f0f8ff",
//     Electronic: "#7d8ebd",
//   };

//   // Check if the user is signed in
//   if (!user) {
//     return (
//       <div className="max-w-full mx-auto p-4">
//         <h1 className="text-center text-3xl font-semibold mb-4 text-colour_2">
//           Projects Available for Pricing
//         </h1>
//         {posts.length === 0 ? (
//           // Display this message if there are no posts
//           <div className="text-center my-10">
//             <h2 className="text-center text-xl w-full">
//               No open home alterations
//             </h2>
//           </div>
//         ) : (
//           // Otherwise, display the grid and the posts
//           <>
//             <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
//               {currentItems.map((post) => (
//                 <div
//                   key={post.postId}
//                   className="border rounded-lg p-4 flex flex-col bg-colour_2 text-white"
//                 >
//                   <span className="text-xl">{post.location}</span>
//                   <span>Budget: {post.budget}</span>
//                   <span>
//                     Date Created: {formatDate(post.date_created.seconds)}
//                   </span>
//                   {post.special_requests && (
//                     <span>Special Requests: {post.special_requests}</span>
//                   )}
//                   <span>Services:</span>
//                   <ul>
//                     {Object.entries({
//                       Electrical: post.ELECTRICAL,
//                       Plumbing: post.PLUMBING,
//                       Painting: post.PAINTING,
//                       Interior: post.INTERIOR,
//                       Builder: post.BUILDERS,
//                       Roofing: post.ROOF,
//                       Joinery: post.JOINERY,
//                       Glasswork: post.GLASS,
//                       Electronic: post.ELECTRONIC,
//                     }).map(
//                       ([service, items]) =>
//                         items &&
//                         items.length > 0 && (
//                           <li
//                             style={{
//                               backgroundColor: serviceColors[service],
//                               color: "black",
//                             }}
//                           >
//                             {`${service}: ${items.join(", ")}`}
//                           </li>
//                         )
//                     )}
//                   </ul>
//                   <button
//                     className="mt-auto bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
//                     onClick={() => alert("Please sign in to see this project")}
//                   >
//                     Go to Project
//                   </button>
//                 </div>
//               ))}
//             </div>
//             <div className="pagination-controls mt-4">
//               <button
//                 onClick={handlePrev}
//                 className="bg-colour_2 text-white rounded-lg p-2 m-2"
//               >
//                 Previous
//               </button>
//               <span>
//                 Page {currentPage} of {totalPages}
//               </span>
//               <button
//                 onClick={handleNext}
//                 className="bg-colour_2 text-white rounded-lg p-2 m-2"
//               >
//                 Next
//               </button>
//             </div>
//           </>
//         )}
//       </div>

//       // <div className="w-full h-100px flex items-center justify-center">
//       //   <div className="flex flex-col justify-center items-center">
//       //     {/* Top Section */}
//       //     <h1 className="font-bold text-xl text-colour_2 p-8">
//       //       Please sign in to use this feature
//       //     </h1>

//       //     <button
//       //       className="w-1/2 rounded-lg bg-colour_2 text-white p-4 text-lg"
//       //       onClick={() => {
//       //         navigate("/sign_into_profile");
//       //       }}
//       //     >
//       //       SIGN IN
//       //     </button>
//       //   </div>
//       // </div>
//     );
//   }

//   if (user) {
//     return (
//       <div className="max-w-full mx-auto p-4">
//         <h1 className="text-center text-3xl font-semibold mb-4 text-colour_2">
//           Projects Available for Pricing
//         </h1>
//         {posts.length === 0 ? (
//           // Display this message if there are no posts
//           <div className="text-center my-10">
//             <h2 className="text-center text-xl w-full">
//               No open home alterations
//             </h2>
//           </div>
//         ) : (
//           // Otherwise, display the grid and the posts
//           <>
//             <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
//               {currentItems.map((post) => (
//                 <div
//                   key={post.postId}
//                   className="border rounded-lg p-4 flex flex-col bg-colour_2 text-white"
//                 >
//                   <span className="text-xl">{post.budget}</span>
//                   {/* <span>Budget: {post.budget}</span>
//                 <span>
//                   Date Created: {formatDate(post.date_created.seconds)}
//                 </span>
//                 {post.special_requests && (
//                   <span>Special Requests: {post.special_requests}</span>
//                 )}
//                 <span>Services:</span>
//                 <ul>
//                   {Object.entries({
//                     Electrical: post.ELECTRICAL,
//                     Plumbing: post.PLUMBING,
//                     Painting: post.PAINTING,
//                     Interior: post.INTERIOR,
//                     Builder: post.BUILDERS,
//                     Roofing: post.ROOF,
//                     Joinery: post.JOINERY,
//                     Glasswork: post.GLASS,
//                     Electronic: post.ELECTRONIC,
//                   }).map(
//                     ([service, items]) =>
//                       items &&
//                       items.length > 0 && (
//                         <li
//                           style={{
//                             backgroundColor: serviceColors[service],
//                             color: "black",
//                             padding: "0.25rem",
//                           }}
//                         >
//                           {`${service}: ${items.join(", ")}`}
//                         </li>
//                       )
//                   )}
//                 </ul> */}
//                   <button
//                     className="mt-auto bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
//                     onClick={() =>
//                       navigate(`/contractor_pricing_editor/${post.postId}`, {
//                         state: { id: post.postId, project: post.name },
//                       })
//                     }
//                   >
//                     Go to Project
//                   </button>
//                 </div>
//               ))}
//             </div>
//             <div className="pagination-controls mt-4">
//               <button
//                 onClick={handlePrev}
//                 className="bg-colour_2 text-white rounded-lg p-2 m-2"
//               >
//                 Previous
//               </button>
//               <span>
//                 Page {currentPage} of {totalPages}
//               </span>
//               <button
//                 onClick={handleNext}
//                 className="bg-colour_2 text-white rounded-lg p-2 m-2"
//               >
//                 Next
//               </button>
//             </div>
//           </>
//         )}
//       </div>
//     );
//   }
// }
