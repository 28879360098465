import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage'

const firebaseConfig = {
  apiKey: "AIzaSyDW8m_1j7kIH6OttP0UBQ26qgTkMc9WH6I",
  authDomain: "openbuilder-ai-firebase.firebaseapp.com",
  projectId: "openbuilder-ai-firebase",
  storageBucket: "openbuilder-ai-firebase.appspot.com",
  messagingSenderId: "70658279318",
  appId: "1:70658279318:web:d9f95c1a5e06d470683fb7"
};

// Initialize Firebase App
export const app = initializeApp(firebaseConfig);
// Initialize Authentication
export const auth = getAuth(app);
// export default storage
export const storage = getStorage(app);
//export firestore
export const db = getFirestore(app)