import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../../data/assets/logo/openbuilder_logo.png";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import {
  fetchPostsByNameNextGen,
  fetchPricedByNameNextGen,
} from "../../../backend/lists/fetchData";
import { addContractorPricing } from "../../../backend/lists/setData";
import { useAuth } from "../../../backend/firebase/AuthContext";
import { useParams } from "react-router-dom";

export function ContractorPricingEditorNextGen() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { projectId } = useParams();

  const INITIAL_DATA = {
    itemDescription: "",
    unit: "",
    rateSupply: 0,
    rateInstall: 0,
    quantity: 0,
    linkToItem: "",
    specialRequests: "",
  };

  const [tabs, setTabs] = useState([]);
  const [tab, setTab] = useState("");
  const [priced, setPriced] = useState(false);
  const [informationForPricing, setInformationForPricing] = useState({});
  const [data, setData] = useState({});
  const [totalPrices, setTotalPrices] = useState({});
  const [supportingDocs, setSupportingDocs] = useState([]);

  const calculateTotalPrice = (dataForTab) => {
    return dataForTab.reduce(
      (total, row) => total + (row.rateSupply + row.rateInstall) * row.quantity,
      0
    );
  };

  useEffect(() => {
    async function getPosts() {
      if (projectId) {
        try {
          const postsData = await fetchPostsByNameNextGen(projectId);
          const pricedData = await fetchPricedByNameNextGen(projectId);
          const headers = Array.from(
            new Set(postsData.posts[0].requests.map((req) => req.header))
          );

          const tabData = headers.reduce((acc, header) => {
            const itemsForTab = postsData.posts[0].requests
              .filter((req) => req.header === header)
              .map((req) => ({
                ...INITIAL_DATA,
                itemDescription: req.item.name,
                specialRequests: req.specialRequests.join(", "),
              }));
            acc[header] = itemsForTab;
            return acc;
          }, {});

          const totalPricesData = headers.reduce((acc, header) => {
            acc[header] = calculateTotalPrice(tabData[header]);
            return acc;
          }, {});

          setTabs(headers);
          setTab(headers[0]);
          setData(tabData);
          setTotalPrices(totalPricesData);
          setPriced(pricedData.posts.length > 0);
          setInformationForPricing(postsData.posts[0]);
          setSupportingDocs(postsData.posts[0].multipleFileUrls);
        } catch (error) {
          console.error("Error fetching posts:", error);
        }
      }
    }

    getPosts();
  }, [projectId]);

  const updateField = (i, field, value) => {
    const newValue = field.includes("rate") ? parseFloat(value) : value;
    const newData = { ...data };
    newData[tab][i][field] = newValue;

    const newTotalPrice = calculateTotalPrice(newData[tab]);
    setTotalPrices((prevPrices) => ({ ...prevPrices, [tab]: newTotalPrice }));
    setData(newData);
  };

  const deleteRow = (i) => {
    const newData = { ...data };
    newData[tab].splice(i, 1);
    setData(newData);
  };

  const addRow = () => {
    const newData = { ...data };
    newData[tab].push({ ...INITIAL_DATA });
    setData(newData);
  };

  const units = ["m", "m^2", "m^3", "days", "item"];

  const openLink = (url) => {
    if (url) {
      window.open(url, "_blank");
    } else {
      alert("No link entered");
    }
  };

  const addTab = () => {
    const tabName = prompt("Enter new tab name");
    if (tabName) {
      setTabs([...tabs, tabName]);
      setData({
        ...data,
        [tabName]: [{ ...INITIAL_DATA }],
      });
      setTab(tabName);
    }
  };

  const generatePDF = () => {
    const doc = new jsPDF();
    tabs.forEach((tabName) => {
      doc.setFontSize(18);
      doc.text(tabName, 11, 8);
      autoTable(doc, {
        startY: 10,
        body: data[tabName].map((row) => [
          row.itemDescription,
          row.specialRequests,
          row.unit,
          row.rateSupply,
          row.rateInstall,
          row.quantity,
          (row.rateSupply + row.rateInstall) * row.quantity,
          row.linkToItem,
        ]),
        columns: [
          { header: "Item Description", dataKey: 0 },
          { header: "Special Requests", dataKey: 1 },
          { header: "Unit", dataKey: 2 },
          { header: "Rate (Supply)", dataKey: 3 },
          { header: "Rate (Install)", dataKey: 4 },
          { header: "Quantity", dataKey: 5 },
          { header: "Item Price", dataKey: 6 },
          { header: "Link to Item", dataKey: 7 },
        ],
      });
      if (tabName !== tabs[tabs.length - 1]) {
        doc.addPage();
      }
    });
    doc.save("tables.pdf");
  };

  return (
    <div>
      {user ? (
        <div>
          <div className="h-24 flex items-center justify-center">
            <div className="w-full h-100px flex items-center">
              <img className="h-18 w-32 p-5" src={logo} alt="Logo" />
            </div>
            <div className="h-1/2 flex flex-col justify-center items-center">
              <h1 className="w-max font-bold text-xs md:text-4xl text-colour_2">
                PRICE YOUR SERVICES TO GIVE A QUOTATION
              </h1>
            </div>
            <div className="w-full h-100px flex items-center justify-between">
              <div className="flex items-center"></div>
            </div>
          </div>

          <div className="flex p-3">
            <div className="flex-1 flex flex-col p-3">
              <h1 className="w-max font-bold text-xs md:text-xl text-colour_2">
                INSTRUCTIONS FOR CONTRACTOR TO PRICE
              </h1>
              <table
                border="1"
                cellspacing="0"
                cellpadding="5"
                className="font-thin text-xs md:text-lg  text-colour_2 p-2 m-4"
              >
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>Review the special requests</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>Look at the site images and drawings provided.</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>
                      Arrange for a site visit for measurement if required for
                      pricing purposes only by contacting 0815872081.
                    </td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>
                      Fill out the information for each item in the pricing
                      table according to the pricing requirements. Also remember
                      that you can add items as necessary for complete
                      construction.
                    </td>
                  </tr>
                  <tr>
                    <td>5</td>
                    <td>Submit the pricing and await further communication.</td>
                  </tr>
                </tbody>
              </table>
              <div className="h-1/2 p-2">
                <h2 className="font-bold text-xs md:text-xl text-colour_2">
                  SUPPORTING DOCUMENTS
                </h2>
                {supportingDocs.map((url, index) => (
                  <button
                    key={index}
                    className="rounded-lg bg-colour_2 text-white p-2 text-sm m-2"
                    onClick={() => openLink(url)}
                  >
                    Document {index + 1}
                  </button>
                ))}
              </div>
            </div>
          </div>

          {!priced && (
            <div className="alert-message">
              <h2 className="text-center text-2xl text-red-500">
                This notification will only appear if you are the first
                contractor to price.
              </h2>
              <h2 className="text-center text-2xl text-red-500">
                Please arrange a visit the home for measurements before you can
                price. Please contact 081 587 2081.
              </h2>
            </div>
          )}

          <div className="p-3">
            {tabs.map((t, index) => (
              <button
                key={index}
                className={`rounded-lg p-2 md:p-4 text-xs md:text-sm mr-2 border border-colour_2 ${
                  tab === t
                    ? "bg-colour_2 text-white"
                    : "bg-white text-colour_2"
                }`}
                onClick={() => setTab(t)}
              >
                {t}
              </button>
            ))}
          </div>

          <div className="overflow-x-auto md:overflow-x-hidden">
            <table className="min-w-full divide-y divide-gray-200 border border-colour_2">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider md:px-6 md:py-3">
                    Item Description
                  </th>
                  <th className="px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider md:px-6 md:py-3">
                    Special Requests
                  </th>
                  <th className="px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider md:px-6 md:py-3">
                    Unit
                  </th>
                  <th className="hidden md:table-cell px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Rate (Supply)
                  </th>
                  <th className="hidden md:table-cell px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Rate (Install)
                  </th>
                  <th className="hidden md:table-cell px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Quantity (Number)
                  </th>
                  <th className="hidden md:table-cell px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Item Price (Rand)
                  </th>
                  <th className="hidden md:table-cell px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Link to Item
                  </th>
                  <th className="hidden md:table-cell px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Open Link
                  </th>
                  <th className="hidden md:table-cell px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {data[tab]?.map((row, index) => (
                  <tr key={index}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      <input
                        className="border-2	border-colour_2 rounded-lg h-12 p-2"
                        type="text"
                        value={row.itemDescription}
                        onChange={(e) =>
                          updateField(index, "itemDescription", e.target.value)
                        }
                      />
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      <div className="h-12 p-2">{row.specialRequests}</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      <select
                        className="h-12 w-3/4"
                        value={row.unit}
                        onChange={(e) =>
                          updateField(index, "unit", e.target.value)
                        }
                      >
                        {units.map((unit, i) => (
                          <option key={i} value={unit}>
                            {unit}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      <input
                        className="border-2	border-colour_2 rounded-lg h-12 p-2"
                        type="number"
                        value={row.rateSupply}
                        onChange={(e) =>
                          updateField(index, "rateSupply", e.target.value)
                        }
                      />
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      <input
                        className="border-2	border-colour_2 rounded-lg h-12 p-2"
                        type="number"
                        value={row.rateInstall}
                        onChange={(e) =>
                          updateField(index, "rateInstall", e.target.value)
                        }
                      />
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      <input
                        className="border-2	border-colour_2 rounded-lg h-12 p-2"
                        type="number"
                        value={row.quantity}
                        onChange={(e) =>
                          updateField(index, "quantity", e.target.value)
                        }
                      />
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {(parseFloat(row.rateSupply) +
                        parseFloat(row.rateInstall)) *
                        parseFloat(row.quantity)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      <input
                        className="border-2	border-colour_2 rounded-lg h-12 p-2"
                        type="text"
                        value={row.linkToItem}
                        onChange={(e) =>
                          updateField(index, "linkToItem", e.target.value)
                        }
                      />
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      <button
                        className="rounded-lg bg-colour_2 text-white p-2 text-sm"
                        onClick={() => openLink(row.linkToItem)}
                      >
                        Open Link
                      </button>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      <button
                        className="rounded-lg bg-red-600 text-white p-2 text-sm"
                        onClick={() => deleteRow(index)}
                      >
                        Remove
                      </button>
                    </td>
                  </tr>
                ))}
                <tr>
                  <td
                    colSpan={6}
                    className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                  >
                    Total Item Price
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {totalPrices[tab]}
                  </td>
                  <td colSpan={3}></td>
                </tr>
              </tbody>
            </table>
            <button
              className="rounded-lg bg-colour_2 text-white p-4 text-sm mb-2 mt-2 ml-2"
              onClick={addRow}
            >
              Add Row
            </button>
            <button
              className="rounded-lg bg-colour_2 text-white p-4 text-sm mb-2 mt-2 ml-2"
              onClick={generatePDF}
            >
              Download PDF
            </button>
            <button
              className="rounded-lg bg-colour_2 text-white p-4 text-sm mb-2 mt-2 ml-2"
              onClick={() => console.log(informationForPricing)}
            >
              Log Post Information
            </button>
          </div>

          <div>
            <div className="flex justify-center">
              <div className="p-3">
                <button
                  className="w-full rounded-lg bg-colour_2 text-white p-4 text-lg"
                  onClick={() => {
                    addContractorPricing(
                      user.email,
                      projectId,
                      data,
                      totalPrices
                    );
                    navigate("/");
                  }}
                >
                  SUBMIT PRICING
                </button>
                <h1 className="font-bold text-xl text-colour_2 p-4">
                  Please note you may only submit pricing once
                </h1>
                <h1 className="font-bold text-xl text-colour_2">
                  You will not be able to edit your pricing once submitted
                </h1>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="w-full h-100px flex items-center justify-center">
          <div className="flex flex-col justify-center items-center">
            <h1 className="font-bold text-xl text-colour_2 p-8">
              Please sign in to use this feature
            </h1>
            <button
              className="w-1/2 rounded-lg bg-colour_2 text-white p-4 text-lg"
              onClick={() => {
                navigate("/sign_into_profile");
              }}
            >
              SIGN IN
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

// import React, { useState, useEffect } from "react";
// import { useLocation, useNavigate } from "react-router-dom";
// import logo from "../../../data/assets/logo/openbuilder_logo.png";
// import { jsPDF } from "jspdf";
// import autoTable from "jspdf-autotable";
// import {
//   fetchPostsByNameNextGen,
//   fetchPricedByNameNextGen,
// } from "../../../backend/lists/fetchData";
// import { addContractorPricing } from "../../../backend/lists/setData";
// import { useAuth } from "../../../backend/firebase/AuthContext";
// import { useParams } from "react-router-dom";

// export function ContractorPricingEditorNextGen() {
//   const { user } = useAuth();
//   const navigate = useNavigate();
//   const { state } = useLocation();
//   // const { id, project } = state;
//   const { projectId } = useParams();

//   // Make project equal to projectId if projectId is not null

//   const TABS = [
//     // "KITCHEN",
//     // "LIVING ROOM",
//     // "BATHROOM",
//     "ELECTRICAL",
//     "PLUMBING",
//     "PAINTING",
//     "INTERIOR",
//     "BUILDERS WORK",
//     "ROOF",
//     "JOINERY",
//     "GLASSWORK",
//     "ELECTRONIC",
//   ];

//   const TAB_TO_DATA_KEY_MAPPING = {
//     ELECTRICAL: "ELECTRICAL",
//     PLUMBING: "PLUMBING",
//     PAINTING: "PAINTING",
//     INTERIOR: "INTERIOR",
//     "BUILDERS WORK": "BUILDERS",
//     ROOF: "ROOF",
//     JOINERY: "JOINERY",
//     GLASSWORK: "GLASS",
//     ELECTRONIC: "ELECTRONIC",
//   };

//   const INITIAL_DATA = {
//     itemDescription: "",
//     unit: "",
//     rateSupply: 0,
//     rateInstall: 0,
//     quantity: 0,
//     linkToItem: "",
//   };

//   const [tabs, setTabs] = useState(TABS);
//   const [tab, setTab] = useState(TABS[0]);
//   const [priced, setPriced] = useState(false); // added
//   const [informationForPricing, setInformationForPricing] = useState({}); // added
//   const [data, setData] = useState(
//     TABS.reduce((acc, tabName) => {
//       acc[tabName] = [{ ...INITIAL_DATA }];
//       return acc;
//     }, {})
//   );
//   const [totalPrices, setTotalPrices] = useState(
//     TABS.reduce((acc, tabName) => {
//       acc[tabName] = 0;
//       return acc;
//     }, {})
//   );

//   const calculateTotalPrice = (dataForTab) => {
//     return dataForTab.reduce(
//       (total, row) => total + (row.rateSupply + row.rateInstall) * row.quantity,
//       0
//     );
//   };

//   useEffect(() => {
//     async function getPosts() {
//       if (projectId) {
//         try {
//           const postsData = await fetchPostsByNameNextGen(projectId);
//           const pricedData = await fetchPricedByNameNextGen(projectId);
//           const updatedData = { ...data };

//           Object.entries(TAB_TO_DATA_KEY_MAPPING).forEach(
//             ([tabName, dataKey]) => {
//               if (postsData.posts[0][dataKey]) {
//                 updatedData[tabName] = postsData.posts[0][dataKey].map(
//                   (description) => ({
//                     ...INITIAL_DATA,
//                     itemDescription: description,
//                   })
//                 );
//               }
//             }
//           );

//           setData(updatedData);
//           setPriced(pricedData.posts.length > 0);
//           setInformationForPricing(postsData.posts[0]);

//           console.log(pricedData.posts.length > 0);
//         } catch (error) {
//           console.error("Error fetching posts:", error);
//         }
//       }
//     }

//     getPosts();
//   }, [projectId]); // removed data from dependency array

//   // const updateField = (i, field, value) => {
//   //   const newData = { ...data };
//   //   newData[tab][i][field] = value;

//   //   // Calculate the new total price for the current tab
//   //   const newTotalPrice = calculateTotalPrice(newData[tab]);

//   //   setTotalPrices((prevPrices) => ({ ...prevPrices, [tab]: newTotalPrice }));
//   //   setData(newData);
//   // };

//   const updateField = (i, field, value) => {
//     const newValue = field.includes("rate") ? parseFloat(value) : value; // assuming that 'rate' fields should be numbers

//     const newData = { ...data };
//     newData[tab][i][field] = newValue;

//     // Calculate the new total price for the current tab
//     const newTotalPrice = calculateTotalPrice(newData[tab]);

//     setTotalPrices((prevPrices) => ({ ...prevPrices, [tab]: newTotalPrice }));
//     setData(newData);
//   };

//   const deleteRow = (i) => {
//     const newData = { ...data };
//     newData[tab].splice(i, 1);
//     setData(newData);
//   };

//   const addRow = () => {
//     const newData = { ...data };
//     newData[tab].push({
//       itemDescription: "",
//       unit: "",
//       rate: 0,
//       quantity: 0,
//       linkToItem: "",
//     });
//     setData(newData);
//   };

//   const units = ["m", "m^2", "m^3", "days", "item"];
//   const totalItemPrice = data[tab].reduce(
//     (total, row) => total + (row.rateSupply + row.rateInstall) * row.quantity,
//     0
//   );

//   const openLink = (url) => {
//     if (url) {
//       window.open(url, "_blank");
//     } else {
//       alert("No link entered");
//     }
//   };

//   const addTab = () => {
//     const tabName = prompt("Enter new tab name");
//     if (tabName) {
//       setTabs([...tabs, tabName]);
//       setData({
//         ...data,
//         [tabName]: [
//           {
//             itemDescription: "",
//             unit: "",
//             rate: 0,
//             quantity: 0,
//             linkToItem: "",
//           },
//         ],
//       });
//       setTab(tabName);
//     }
//   };

//   const generatePDF = () => {
//     const doc = new jsPDF();
//     tabs.forEach((tabName) => {
//       doc.setFontSize(18);
//       doc.text(tabName, 11, 8);
//       autoTable(doc, {
//         startY: 10,
//         body: data[tabName].map((row) => [
//           row.itemDescription,
//           row.unit,
//           row.rate,
//           row.quantity,
//           row.rate * row.quantity,
//           row.linkToItem,
//         ]),
//         columns: [
//           { header: "Item Description", dataKey: 0 },
//           { header: "Unit", dataKey: 1 },
//           { header: "Rate", dataKey: 2 },
//           { header: "Quantity", dataKey: 3 },
//           { header: "Item Price", dataKey: 4 },
//           { header: "Link to Item", dataKey: 5 },
//         ],
//       });
//       if (tabName !== tabs[tabs.length - 1]) {
//         doc.addPage();
//       }
//     });
//     doc.save("tables.pdf");
//   };

//   return (
//     <div>
//       {user ? (
//         <div>
//           <div className="h-24 flex items-center justify-center">
//             <div className="w-full h-100px flex items-center">
//               <img className="h-18 w-32 p-5" src={logo} alt="Logo" />
//             </div>

//             <div className="h-1/2 flex flex-col justify-center items-center">
//               <h1 className="w-max font-bold text-xs md:text-4xl text-colour_2">
//                 PRICE YOUR SERVICES TO GIVE A QUOTATION
//               </h1>
//             </div>

//             <div className="w-full h-100px flex items-center justify-between">
//               <div className="flex items-center"></div>
//             </div>
//           </div>

//           <div className="flex p-3">
//             <div className="flex-1 flex flex-col p-3">
//               <h1 className="w-max font-bold text-xs md:text-xl text-colour_2">
//                 INSTRUCTIONS FOR CONTRACTOR TO PRICE
//               </h1>

//               <table
//                 border="1"
//                 cellspacing="0"
//                 cellpadding="5"
//                 className="font-thin text-xs md:text-lg  text-colour_2 p-2 m-4"
//               >
//                 <tbody>
//                   <tr>
//                     <td>1</td>
//                     <td>Review the special requests</td>
//                   </tr>
//                   <tr>
//                     <td>2</td>
//                     <td>Look at the site images and drawings provided.</td>
//                   </tr>
//                   <tr>
//                     <td>3</td>
//                     <td>
//                       Arrange for a site visit for measurement if required for
//                       pricing purposes only by contacting 0815872081.
//                     </td>
//                   </tr>
//                   <tr>
//                     <td>4</td>
//                     <td>
//                       Fill out the information for each item in the pricing
//                       table according tho the pricing requirements. Also
//                       rememeber that you can add items as neccessary for
//                       complete construction.
//                     </td>
//                   </tr>
//                   <tr>
//                     <td>5</td>
//                     <td>Submit the pricing and await further communication.</td>
//                   </tr>
//                 </tbody>
//               </table>

//               <div className="h-1/2 p-2">
//                 {/* <a
//                   href={informationForPricing.multipleFileUrls[0]}
//                   target="_blank"
//                   rel="noreferrer"
//                   className="rounded-lg bg-colour_2 text-white p-2 text-sm m-2"
//                 >
//                   Structural Drawing 1
//                 </a>{" "} */}
//               </div>
//             </div>
//           </div>

//           {/* Here I would like to add instructions for the  */}

//           {/* New message for when priced is false */}
//           {!priced && (
//             <div className="alert-message">
//               <h2 className="text-center text-2xl text-red-500">
//                 This notification will only appear if you are the first
//                 contractor to price.
//               </h2>
//               <h2 className="text-center text-2xl text-red-500">
//                 Please arrange a visit the home for measurements before you can
//                 price. Please contact 081 587 2081.
//               </h2>
//             </div>
//           )}

//           <div className="p-3">
//             {tabs.map((t, index) => (
//               <button
//                 key={index}
//                 className={`rounded-lg p-2 md:p-4 text-xs md:text-sm mr-2 border border-colour_2 ${
//                   tab === t
//                     ? "bg-colour_2 text-white"
//                     : "bg-white text-colour_2"
//                 }`}
//                 onClick={() => setTab(t)}
//               >
//                 {t}
//               </button>
//             ))}
//           </div>

//           <div className="overflow-x-auto md:overflow-x-hidden">
//             <table className="min-w-full divide-y divide-gray-200 border border-colour_2">
//               <thead className="bg-gray-50">
//                 <tr>
//                   <th className="px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider md:px-6 md:py-3">
//                     Item Description
//                   </th>
//                   <th className="px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider md:px-6 md:py-3">
//                     Unit
//                   </th>
//                   <th className="px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider md:px-6 md:py-3">
//                     Rate (Supply)
//                   </th>
//                   <th className="hidden md:table-cell px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
//                     {" "}
//                     Rate (Install)
//                   </th>

//                   <th className="hidden md:table-cell px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
//                     Quantity (Number)
//                   </th>
//                   <th className="hidden md:table-cell px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
//                     Item Price (Rand)
//                   </th>
//                   <th className="hidden md:table-cell px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
//                     Link to Item
//                   </th>
//                   <th className="hidden md:table-cell px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
//                     Open Link
//                   </th>
//                   <th className="hidden md:table-cell px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
//                     Actions
//                   </th>
//                 </tr>
//               </thead>
//               <tbody className="bg-white divide-y divide-gray-200">
//                 {data[tab].map((row, index) => (
//                   <tr key={index}>
//                     <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
//                       <input
//                         className="border-2	border-colour_2 rounded-lg h-12 p-2"
//                         type="text"
//                         value={row.itemDescription}
//                         onChange={(e) =>
//                           updateField(index, "itemDescription", e.target.value)
//                         }
//                       />
//                     </td>
//                     <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
//                       <select
//                         className="h-12 w-3/4"
//                         value={row.unit}
//                         onChange={(e) =>
//                           updateField(index, "unit", e.target.value)
//                         }
//                       >
//                         {units.map((unit, i) => (
//                           <option key={i} value={unit}>
//                             {unit}
//                           </option>
//                         ))}
//                       </select>
//                     </td>
//                     <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
//                       <input
//                         className="border-2	border-colour_2 rounded-lg h-12 p-2"
//                         type="number"
//                         value={row.rateSupply}
//                         onChange={(e) =>
//                           updateField(index, "rateSupply", e.target.value)
//                         }
//                       />
//                     </td>
//                     <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
//                       <input
//                         className="border-2	border-colour_2 rounded-lg h-12 p-2"
//                         type="number"
//                         value={row.rateInstall}
//                         onChange={(e) =>
//                           updateField(index, "rateInstall", e.target.value)
//                         }
//                       />
//                     </td>

//                     <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
//                       <input
//                         className="border-2	border-colour_2 rounded-lg h-12 p-2"
//                         type="number"
//                         value={row.quantity}
//                         onChange={(e) =>
//                           updateField(index, "quantity", e.target.value)
//                         }
//                       />
//                     </td>
//                     {/* <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
//                       {(row.rateSupply + row.rateInstall) * row.quantity}
//                     </td> */}
//                     <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
//                       {(parseFloat(row.rateSupply) +
//                         parseFloat(row.rateInstall)) *
//                         parseFloat(row.quantity)}
//                     </td>

//                     <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
//                       <input
//                         className="border-2	border-colour_2 rounded-lg h-12 p-2"
//                         type="text"
//                         value={row.linkToItem}
//                         onChange={(e) =>
//                           updateField(index, "linkToItem", e.target.value)
//                         }
//                       />
//                     </td>
//                     <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
//                       <button
//                         className="rounded-lg bg-colour_2 text-white p-2 text-sm"
//                         onClick={() => openLink(row.linkToItem)}
//                       >
//                         Open Link
//                       </button>
//                     </td>
//                     <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
//                       <button
//                         className="rounded-lg bg-red-600 text-white p-2 text-sm"
//                         onClick={() => deleteRow(index)}
//                       >
//                         Remove
//                       </button>
//                     </td>
//                   </tr>
//                 ))}
//                 <tr>
//                   <td
//                     colSpan={4}
//                     className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
//                   >
//                     Total Item Price
//                   </td>
//                   <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
//                     {totalPrices[tab]}
//                   </td>
//                   <td colSpan={3}></td>
//                 </tr>
//               </tbody>
//             </table>
//             <button
//               className="rounded-lg bg-colour_2 text-white p-4 text-sm mb-2 mt-2 ml-2"
//               onClick={addRow}
//             >
//               Add Row
//             </button>
//             <button
//               className="rounded-lg bg-colour_2 text-white p-4 text-sm mb-2 mt-2 ml-2"
//               onClick={generatePDF}
//             >
//               Download PDF
//             </button>
//             <button
//               className="rounded-lg bg-colour_2 text-white p-4 text-sm mb-2 mt-2 ml-2"
//               onClick={() => console.log(informationForPricing)}
//             >
//               Log Post Information
//             </button>
//           </div>

//           <div>
//             <div className="flex justify-center">
//               <div className="p-3">
//                 <button
//                   className="w-full rounded-lg bg-colour_2 text-white p-4 text-lg"
//                   onClick={() => {
//                     // console.log(priced)
//                     // console.log(totalPrices[tab]);
//                     addContractorPricing(
//                       user.email,
//                       projectId,
//                       data,
//                       totalPrices
//                     );
//                     // This is where the dates for priced_jobs will be set
//                     navigate("/welcome_contractor");
//                   }}
//                 >
//                   SUBMIT PRICING
//                 </button>
//                 <h1 className="font-bold text-xl text-colour_2 p-4">
//                   Please note you may only submit pricing once
//                 </h1>
//                 <h1 className="font-bold text-xl text-colour_2">
//                   You will not be able to edit your pricing once submitted
//                 </h1>
//                 {/* <div className="p-3">
//                   <button
//                     className="w-full rounded-lg bg-colour_2 text-white p-4 text-lg"
//                     onClick={() => {
//                       navigate("/welcome_contractor");
//                     }}
//                   >
//                     PRICING COMPLETED
//                   </button>
//                 </div> */}
//               </div>
//             </div>
//           </div>
//         </div>
//       ) : (
//         <div className="w-full h-100px flex items-center justify-center">
//           <div className="flex flex-col justify-center items-center">
//             {/* Top Section */}
//             <h1 className="font-bold text-xl text-colour_2 p-8">
//               Please sign in to use this feature
//             </h1>

//             <button
//               className="w-1/2 rounded-lg bg-colour_2 text-white p-4 text-lg"
//               onClick={() => {
//                 navigate("/sign_into_profile");
//               }}
//             >
//               SIGN IN
//             </button>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// }
