import React, { useState } from "react";
import FileSelectorMultiple from "../backend/upload/FileSelectorMultiple";
import { useAuth } from "../backend/firebase/AuthContext";

export const UserPrompt = ({
  isOpen,
  close,
  item,
  header, // Accept header here
  handleRequestSubmit,
  project,
}) => {
  const [hasCurrentInstallation, setHasCurrentInstallation] = useState(null);
  const [budget, setBudget] = useState("");
  const [specialRequests, setSpecialRequests] = useState([""]);
  const [multipleFileUrls, setMultipleFileUrls] = useState({});

  const { user } = useAuth();

  const handleAddRequest = () => {
    setSpecialRequests([...specialRequests, ""]);
  };

  const handleRemoveRequest = (index) => {
    setSpecialRequests(specialRequests.filter((_, i) => i !== index));
  };

  const handleChangeRequest = (value, index) => {
    const updatedRequests = specialRequests.map((request, i) =>
      i === index ? value : request
    );
    setSpecialRequests(updatedRequests);
  };

  const handleUrlReceivedMultiple = (id, urls) => {
    console.log("Received URLs for", id, ":", urls);
    setMultipleFileUrls((prevUrls) => ({ ...prevUrls, [id]: urls }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const requestData = {
      item,
      header,
      hasCurrentInstallation,
      imageURLs: multipleFileUrls,
      budget,
      specialRequests,
    };
    handleRequestSubmit(requestData);
    close();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50">
      <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
        <form onSubmit={handleSubmit}>
          <h3 className="text-lg font-semibold text-center mb-4">{`New request for: ${item.name}`}</h3>

          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Is this a completely new installation?
            </label>
            <label className="inline-flex items-center">
              <input
                type="radio"
                name="installation"
                value="yes"
                onChange={() => setHasCurrentInstallation(true)}
                className="form-radio"
              />
              <span className="ml-2">Yes</span>
            </label>
            <label className="inline-flex items-center ml-6">
              <input
                type="radio"
                name="installation"
                value="no"
                onChange={() => setHasCurrentInstallation(false)}
                className="form-radio"
              />
              <span className="ml-2">No</span>
            </label>
          </div>

          {hasCurrentInstallation && (
            <FileSelectorMultiple
              id="multipleFiles"
              text="UPLOAD IMAGE OF CURRENT INSTALLATION"
              path={`unpriced_jobs/${user.email}/${project}/site_images/`}
              onUrlsReceived={handleUrlReceivedMultiple}
            />
          )}

          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              What is your budget for this item?
            </label>
            <input
              type="number"
              value={budget}
              onChange={(e) => setBudget(e.target.value)}
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              placeholder="Enter your budget"
              min="0"
              step="0.01"
            />
          </div>

          <div className="mb-4">
            {specialRequests.map((request, index) => (
              <div
                key={index}
                className="mb-2 border border-gray-300 rounded-md p-2"
              >
                <textarea
                  value={request}
                  onChange={(e) => handleChangeRequest(e.target.value, index)}
                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  placeholder="Enter any special requests"
                />
                <button
                  type="button"
                  onClick={() => handleRemoveRequest(index)}
                  className="mt-2 px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
                >
                  Remove
                </button>
              </div>
            ))}
            <button
              type="button"
              onClick={handleAddRequest}
              className="mt-2 px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
            >
              Add another request
            </button>
          </div>

          <div className="flex items-center justify-between">
            <button
              type="button"
              className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
              onClick={close}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UserPrompt;






























// import React, { useState, useRef } from "react";
// import FileSelector from "../backend/upload/FileSelector";
// import FileSelectorMultiple from "../backend/upload/FileSelectorMultiple";
// import { useAuth } from "../backend/firebase/AuthContext";

// export const UserPrompt = ({
//   isOpen,
//   close,
//   item,
//   header, // Accept header here
//   handleRequestSubmit,
//   project,
// }) => {
//   const [hasCurrentInstallation, setHasCurrentInstallation] = useState(null);
//   const [budget, setBudget] = useState("");
//   // const [specialRequests, setSpecialRequests] = useState("");

//   const [specialRequests, setSpecialRequests] = useState([""]); // Initialize as array with one empty string
//   const [imageURL, setImageURL] = useState("");
//   const { user, handleSignOut } = useAuth();

//   // const handleUrlReceivedMultiple = (id, urls) => {
//   //   console.log("Received URLs for", id, ":", urls);
//   // };

//   const handleAddRequest = () => {
//     setSpecialRequests([...specialRequests, ""]); // Add new empty string to array
//   };

//   const handleRemoveRequest = (index) => {
//     setSpecialRequests(specialRequests.filter((_, i) => i !== index)); // Remove item at index
//   };

//   const handleChangeRequest = (value, index) => {
//     const updatedRequests = specialRequests.map((request, i) =>
//       i === index ? value : request
//     );
//     setSpecialRequests(updatedRequests);
//   };

//   const handleUrlReceived = (url) => {
//     console.log("URL received:", url); // Debug output
//     setImageURL(url);
//   };

//   const [multipleFileUrls, setMultipleFileUrls] = useState({});
//   const handleUrlReceivedMultiple = (id, urls) => {
//     console.log("Received URLs for", id, ":", urls);
//     setMultipleFileUrls((prevUrls) => ({ ...prevUrls, [id]: urls }));
//   };

//   // const handleSubmit = (event) => {
//   //   event.preventDefault();
//   //   const requestData = {
//   //     item,
//   //     header, // Include the header in the requestData
//   //     hasCurrentInstallation,
//   //     imageURL,
//   //     budget,
//   //     specialRequests,
//   //   };
//   //   handleRequestSubmit(requestData);
//   //   close();
//   // };

//   const handleSubmit = (event) => {
//     event.preventDefault();
//     console.log("Submitting with imageURL:", imageURL); // Debug output
//     const requestData = {
//       item,
//       header,
//       hasCurrentInstallation,
//       imageURL,
//       budget,
//       specialRequests,
//     };
//     handleRequestSubmit(requestData);
//     close();
//   };
//   if (!isOpen) return null;

//   return (
//     <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50">
//       <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
//         <form onSubmit={handleSubmit}>
//           <h3 className="text-lg font-semibold text-center mb-4">{`New request for: ${item.name}`}</h3>

//           <div className="mb-4">
//             <label className="block text-sm font-medium text-gray-700 mb-2">
//               Is this a completely new installation?
//             </label>
//             <label className="inline-flex items-center">
//               <input
//                 type="radio"
//                 name="installation"
//                 value="yes"
//                 onChange={() => setHasCurrentInstallation(true)}
//                 className="form-radio"
//               />
//               <span className="ml-2">Yes</span>
//             </label>
//             <label className="inline-flex items-center ml-6">
//               <input
//                 type="radio"
//                 name="installation"
//                 value="no"
//                 onChange={() => setHasCurrentInstallation(false)}
//                 className="form-radio"
//               />
//               <span className="ml-2">No</span>
//             </label>
//           </div>

//           {hasCurrentInstallation && (
//             // <FileSelector
//             //   id="installation-image"
//             //   text="Take/Upload a photo of the current installation"
//             //   path="/upload-path" // Set the correct path to your upload handling endpoint
//             //   onUrlReceived={handleUrlReceived}
//             // />
//             <FileSelectorMultiple
//               id="multipleFiles"
//               text="UPLOAD IMAGE OF CURRENT INSTALLATION"
//               path={`unpriced_jobs/${user.email}/${project}/site_images/`}
//               onUrlsReceived={handleUrlReceivedMultiple}
//             />
//           )}

//           {/* <div className="mb-4">
//             <label className="block text-sm font-medium text-gray-700 mb-2">
//               What is your budget for this item?
//             </label>
//             <input
//               type="text"
//               value={budget}
//               onChange={(e) => setBudget(e.target.value)}
//               className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
//               placeholder="Enter your budget"
//             />
//           </div> */}

//           <div className="mb-4">
//             <label className="block text-sm font-medium text-gray-700 mb-2">
//               What is your budget for this item?
//             </label>
//             <input
//               type="number" // Ensures that only numbers can be input
//               value={budget}
//               onChange={(e) => setBudget(e.target.value)}
//               className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
//               placeholder="Enter your budget"
//               min="0" // Prevents negative values
//               step="0.01" // Allows decimal values for precise budgeting, if necessary
//             />
//           </div>

//           {/* <div className="mb-4">
//             <label className="block text-sm font-medium text-gray-700 mb-2">
//               Enter any special requests for this installation
//             </label>
//             <textarea
//               value={specialRequests}
//               onChange={(e) => setSpecialRequests(e.target.value)}
//               className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
//               placeholder="Enter any special requests"
//             />
//           </div> */}

//           <div className="mb-4">
//             {specialRequests.map((request, index) => (
//               <div
//                 key={index}
//                 className="mb-2 border border-gray-300 rounded-md p-2"
//               >
//                 <textarea
//                   value={request}
//                   onChange={(e) => handleChangeRequest(e.target.value, index)}
//                   className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
//                   placeholder="Enter any special requests"
//                 />
//                 <button
//                   type="button"
//                   onClick={() => handleRemoveRequest(index)}
//                   className="mt-2 px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600" // Add hover effect
//                 >
//                   Remove
//                 </button>
//               </div>
//             ))}
//             <button
//               type="button"
//               onClick={handleAddRequest}
//               className="mt-2 px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
//             >
//               Add another request
//             </button>
//           </div>

//           <div className="flex items-center justify-between">
//             <button
//               type="button"
//               className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
//               onClick={close}
//             >
//               Cancel
//             </button>
//             <button
//               type="submit"
//               className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
//             >
//               Submit
//             </button>
//           </div>
//         </form>
//       </div>
//     </div>
//   );
// };

// export default UserPrompt;
