import React, { useEffect, useState } from "react";
import { useAuth } from "../../backend/firebase/AuthContext";
import logo from "../../data/assets/logo/openbuilder_logo.png";
import { fetchContractors } from "../../backend/statistics/fetchContractorData";
import { BarChart } from "../../backend/graphs/BarChart";
import LineChart from "../../backend/graphs/LineChart";
import StatisticsTable from "../../backend/graphs/StatisticsTable";
import ExperienceVsServiceRadarChart from "../../backend/graphs/ExperienceVsServiceRadarChart";

export function AdminDashboard() {
  const { user, handleSignOut, isAdmin } = useAuth();

  const [posts, setPosts] = useState([]);
  async function getPosts() {
    if (user) {
      const postsData = await fetchContractors(user);
      setPosts(postsData.posts);
      console.log(postsData.posts);
    } else {
      // Handle the scenario when user is null, maybe set an error or redirect
    }
  }

  useEffect(() => {
    getPosts();
  }, []);

  if (isAdmin) {
    return (
      <div className="admin-dashboard">
        <div className="h-24 flex items-center justify-center">
          {/* Header Section */}
          {/* <h1 className="text-white text-2xl">Header</h1> */}

          <div className="w-full h-100px flex items-center">
            <img className="md:h-18 w-32 p-5" src={logo} alt="Logo" />
          </div>

          <div className="h-1/2 flex flex-col justify-center items-center">
            {/* Top Section */}
            <h1 className="md:w-max w-8/10 font-bold text-xs md:text-4xl  text-colour_2">
              USER STATISTICS DASHBOARD
            </h1>
          </div>

          <div className="w-full h-100px flex items-center justify-between">
            <div className="flex items-center"></div>
          </div>
        </div>

        <div>
          <div className="grid md:grid-cols-2 grid-rows-2 gap-5">
            {/* Top Left: Bar Chart */}
            <div className="p-4 border border-gray-200 rounded shadow h-128">
              <BarChart data={posts} />
            </div>

            {/* Top Right: Line Chart */}
            <div className="p-4 border border-gray-200 rounded shadow h-128">
              <div>
                <LineChart data={posts} />
              </div>
            </div>

            {/* Bottom Left:  */}
            <div className="p-4 border border-gray-200 rounded shadow h-128">
              {/* <LineGraph data={data.lineGraphData} /> */}
              <div>
                <StatisticsTable data={posts} />
              </div>
            </div>

            {/* Bottom Right:  */}
            <div className="p-4 border border-gray-200 rounded shadow h-128 flex justify-center items-center">
              {/* <StackedBarChart data={data.stackedBarChartData} /> */}
              <ExperienceVsServiceRadarChart data={posts} />
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (!isAdmin) {
    return <h1>Not an admin</h1>;
  }
}
