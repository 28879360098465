import { db } from "../firebase/firebase-config";
import {
  collection,
  doc,
  setDoc,
  updateDoc,
  where,
  query,
  getDoc,
  serverTimestamp,
} from "firebase/firestore";

export const addContractorPricing = async (
  contractor,
  project,
  data,
  prices
) => {
  const projectDocRef = doc(db, "priced_jobs_next_gen", String(project));
  let newData = {};

  try {
    const docSnapshot = await getDoc(projectDocRef);

    if (docSnapshot.exists()) {
      const existingData = docSnapshot.data();
      const isContractorExist =
        existingData.contractors && existingData.contractors[contractor];

      if (isContractorExist) {
        alert("You have already priced this project.");
        return;
      }

      const isFirstContractor =
        !existingData.contractors ||
        Object.keys(existingData.contractors).length === 0;
      const pricingTime = serverTimestamp();

      newData = {
        ...existingData,
        contractors: {
          ...existingData.contractors,
          [contractor]: {
            ...data,
            pricing_submitted: pricingTime,
            prices: prices,
          },
        },
      };

      if (isFirstContractor) {
        const currentDateTime = new Date();
        const contractorsEndDate = new Date(
          currentDateTime.getTime() + 2 * 24 * 60 * 60 * 1000
        );
        const optimizationEndDate = new Date(
          contractorsEndDate.getTime() + 1 * 24 * 60 * 60 * 1000
        );
        const clientChoiceEndDate = new Date(
          optimizationEndDate.getTime() + 2 * 24 * 60 * 60 * 1000
        );

        newData.contractors_end_date = contractorsEndDate;
        newData.optimization_end_date = optimizationEndDate;
        newData.client_choice_end_date = clientChoiceEndDate;
        newData.first_contractor_date = currentDateTime;
      }
    } else {
      const currentDateTime = new Date();
      const contractorsEndDate = new Date(
        currentDateTime.getTime() + 2 * 24 * 60 * 60 * 1000 // Pricing for 2 days
      );
      const optimizationEndDate = new Date(
        contractorsEndDate.getTime() + 1 * 24 * 60 * 60 * 1000 // Optimize in 1 day
      );
      const clientChoiceEndDate = new Date(
        optimizationEndDate.getTime() + 2 * 24 * 60 * 60 * 1000 // Valid for 2 days
      );

      newData = {
        name: project,
        contractors: {
          [contractor]: {
            ...data,
            pricing_submitted: serverTimestamp(),
            prices: prices,
          },
        },
        contractors_end_date: contractorsEndDate,
        optimization_end_date: optimizationEndDate,
        client_choice_end_date: clientChoiceEndDate,
        first_contractor_date: currentDateTime,
      };
    }

    await setDoc(projectDocRef, newData);
  } catch (error) {
    console.error("Error adding contractor pricing: ", error);
  }
};



export const addFieldToPricedJob = async (projectId, fieldName, fieldValue) => {
  const projectDocRef = doc(db, "priced_jobs", String(projectId));

  try {
    const docSnapshot = await getDoc(projectDocRef);

    if (!docSnapshot.exists()) {
      console.error("Project not found.");
      return;
    }

    const updateData = {
      [fieldName]: fieldValue,
    };

    await updateDoc(projectDocRef, updateData);
    console.log(`Field '${fieldName}' added to project '${projectId}'.`);
  } catch (error) {
    console.error("Error adding field to priced job: ", error);
  }
};
