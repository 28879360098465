import { db } from "../firebase/firebase-config";
import {
  collection,
  getDocs,
  query,
  where,
  doc,
  getDoc,
} from "firebase/firestore";


export const fetchContractors = async (userId) => {
  // const posts = new Array();
  const posts = [];

  const q = query(
    collection(db, "businesses_contractor"),
    // where("user", "!=", userId.email),
    // where("paid", "==", true)
  );

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    let postData = doc.data();
    // console.log(postData)
    postData.postId = doc.id;
    posts.push(postData);
  });
  return { posts };
};