import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { doc, setDoc, getDoc, serverTimestamp } from "firebase/firestore";
import { db } from "../../../backend/firebase/firebase-config";

// Logos
import logo from "../../../data/assets/logo/openbuilder_logo.png";
import builders_work from "../../../data/assets/service_icons/BrickWall.png";
import electrical from "../../../data/assets/service_icons/LightningBolt.png";
import roofing from "../../../data/assets/service_icons/Roofing.png";
import plumbing from "../../../data/assets/service_icons/Plumbing.png";
import mechanical from "../../../data/assets/service_icons/FanHead.png";
import electronics from "../../../data/assets/service_icons/Electronics.png";
import painting from "../../../data/assets/service_icons/Exterior.png";
import solar from "../../../data/assets/service_icons/SolarPanel.png";
import landscaping from "../../../data/assets/service_icons/Forest.png";
import graveling from "../../../data/assets/service_icons/Road.png";
import tiling from "../../../data/assets/service_icons/Grid.png";
import security from "../../../data/assets/service_icons/WallMountCamera.png";
import electric_fencing from "../../../data/assets/service_icons/ElectricFence.png";

import {
  CapeTownSuburbs,
  JohannesburgSuburbs,
} from "../../../data/locations/Suburbs";

const color = "#171658";

export function DesignerListBusiness() {
  const navigate = useNavigate();
  const externalArrayBusinessInputs = [];
  const externalArrayButtonsActive = [];

  function CitySelector({ value, onChange }) {
    const cities = ["Cape Town", "Johannesburg"];

    return (
      <select
        className="w-full md:h-1/2 h-1/4 text-sm text-xs md:p-2 p-0 text-colour_2 font-thin rounded-lg border-4 border-colour_2"
        value={value}
        onChange={onChange}
      >
        <option value="">--Select a city--</option>
        {cities.map((city) => (
          <option key={city} value={city}>
            {city}
          </option>
        ))}
      </select>
    );
  }

  function SuburbSelector({ city, value, onChange }) {
    let suburbs;
    if (city === "Cape Town") {
      suburbs = CapeTownSuburbs.sort();
    } else if (city === "Johannesburg") {
      suburbs = JohannesburgSuburbs.sort();
    } else {
      suburbs = [];
    }

    return (
      <select
        className="w-full md:h-1/2 h-1/4 text-sm text-xs md:p-2 p-0 text-colour_2 font-thin rounded-lg border-4 border-colour_2 overflow-y-auto max-h-60" // Tailwind classes for scroll
        value={value}
        onChange={onChange}
        disabled={suburbs.length === 0}
      >
        <option value="">--Select a suburb--</option>
        {suburbs.map((suburb) => (
          <option key={suburb} value={suburb}>
            {suburb}
          </option>
        ))}
      </select>
    );
  }

  function TextInputArray({ onInputsChange }) {
    const [inputs, setInputs] = useState([
      { label: "BUSINESS NAME", value: "", required: true },
      { label: "BUSINESS EMAIL", value: "", required: true },
      { label: "BUSINESS OWNER (NAME AND SURNAME)", value: "", required: true },
      { label: "BUSINESS CONTACT NUMBER", value: "", required: true },
      { label: "BUSINESS REGISTRATION NUMBER (OPTIONAL)", value: "" },
      { label: "PROFESSIONAL REGISTRATION NUMBER (DRAWING SIGN-OFF)", value: "" },
      { label: "CITY", value: "", required: true },
      { label: "SUBURB", value: "", required: true },
      { label: "YEARS OF EXPERIENCE", value: "", required: true },
      { label: "STANDARD DESIGN FEE (HOURLY)", value: "", required: true },
      { label: "CERTIFICATIONS", value: "" },
      { label: "DONT SEE YOUR SERVICE? RECOMMEND IT.", value: "" },
    ]);

    const [selectedCity, setSelectedCity] = useState("");

    const handleChange = (e, index) => {
      const newInputs = [...inputs];
      newInputs[index].value = e.target.value;
      setInputs(newInputs);
      onInputsChange(newInputs);

      if (inputs[index].label === "CITY") {
        setSelectedCity(e.target.value);
      }
    };

    const handleSubmit = (e) => {
      e.preventDefault();
      // Submission logic here
    };

    return (
      <form onSubmit={handleSubmit}>
        <div>
          {inputs.map((input, index) => {
            if (input.label === "CITY") {
              return (
                <div
                  key={index}
                  className="text-lg p-0. text-colour_2 font-semibold border-color_2"
                >
                  <label className="text-xs md:text-sm">{input.label}</label>
                  <br></br>
                  <CitySelector
                    value={input.value}
                    onChange={(e) => handleChange(e, index)}
                  />
                </div>
              );
            }

            if (
              input.label === "SUBURB" &&
              (selectedCity === "Cape Town" || selectedCity === "Johannesburg")
            ) {
              return (
                <div
                  key={index}
                  className="text-lg p-0. text-colour_2 font-semibold border-color_2"
                >
                  <label className="md:text-sm text-xs">{input.label}</label>
                  <br></br>
                  <SuburbSelector
                    city={selectedCity}
                    value={input.value}
                    onChange={(e) => handleChange(e, index)}
                  />
                </div>
              );
            }

            return (
              <div
                key={index}
                className="text-lg p-0. text-colour_2 font-semibold border-color_2"
              >
                <label className="md:text-sm text-xs">{input.label}</label>
                <br></br>
                <input
                  className="w-full md:h-1/2 h-1/4 text-sm text-xs md:p-2 p-0 text-colour_2 font-thin rounded-lg border-4 border-colour_2"
                  value={input.value}
                  onChange={(e) => handleChange(e, index)}
                  required={input.required}
                />
              </div>
            );
          })}
        </div>
      </form>
    );
  }

  function handleInputsChange(updatedInputs) {
    // Do something with the updated inputs (e.g., store them in state)
    // console.log(updatedInputs);
    // Update the external array with the updated inputs
    externalArrayBusinessInputs.splice(
      0,
      externalArrayBusinessInputs.length,
      ...updatedInputs
    );
  }

  function get_business_details() {
    console.log(externalArrayBusinessInputs);
  }

  function ButtonArray({ buttons, onButtonClick }) {
    const [activeButtonLabels, setActiveButtonLabels] = useState([]);
    const externalArrayServiceButtonsActive = useRef([]);

    useEffect(() => {
      // Update the external array whenever activeButtonLabels changes
      externalArrayServiceButtonsActive.current = activeButtonLabels;
      // You can perform any additional actions with the external array here
      console.log(
        "External array updated:",
        externalArrayServiceButtonsActive.current
      );
    }, [activeButtonLabels]);

    const handleButtonClick = (label) => {
      const labelExists = activeButtonLabels.includes(label);
      const newLabels = labelExists
        ? activeButtonLabels.filter((l) => l !== label)
        : [...activeButtonLabels, label];

      setActiveButtonLabels(newLabels);

      // Call parent component's callback function
      onButtonClick && onButtonClick(newLabels);
    };
    const isButtonActive = (label) => activeButtonLabels.includes(label);

    const getButtonTextColor = (label) =>
      activeButtonLabels.includes(label) ? "white" : color;

    const getButtonBackgroundColor = (label) =>
      activeButtonLabels.includes(label) ? color : "white";

    return (
      <div>
        {buttons.map(({ text, imageSrc }, index) => (
          <div key={index} className="flex items-center p-1">
            {imageSrc && (
              <img
                src={imageSrc}
                alt={`Image for ${text}`}
                className="object-scale-down md:h-12 h-6 md:w-24 w-12"
              />
            )}
            <button
              className={`w-full md:text-sm text-xs p-1 font-thin rounded-lg border-4 ${
                isButtonActive(text)
                  ? "border-colour_2 bg-colour_2 text-white"
                  : "border-colour_2 bg-white text-colour_2"
              }`}
              onClick={() => handleButtonClick(text)}
            >
              {text}
            </button>
          </div>
        ))}
      </div>
    );
  }

  const buttons = [
    {
      text: "STRUCTURAL ENGINEERING",
      imageSrc: builders_work,
    },
    { text: "ELECTRICAL ENGINEERING", imageSrc: electrical },
    { text: "STRUCTUAL ROOF ENGINEERING", imageSrc: roofing },
    { text: "WET SERVICES AND CIVIL ENGINEERING", imageSrc: plumbing },
    { text: "MECHANICAL ENGINEERING", imageSrc: mechanical },
    { text: "ELECTRONICS ENGINEERING", imageSrc: electronics },
    { text: "ARCHITECTURE", imageSrc: painting },
    { text: "SOLAR ENGINEERING", imageSrc: solar },
    { text: "ARCHITECURAL LANDSCAPING", imageSrc: landscaping },
    { text: "PAVEMENT ENGINEERING", imageSrc: graveling },
    { text: "INTERIOR DECORATOR", imageSrc: tiling },
    { text: "ACCESS CONTROL", imageSrc: electric_fencing },
    { text: "SECURITY", imageSrc: security },
    // add more buttons as needed
  ];

  const handleButtonClick = (activeLabels) => {
    // Handle button click event
    // console.log("Active button labels:", activeLabels);
    externalArrayButtonsActive.splice(
      0,
      externalArrayButtonsActive.length,
      ...activeLabels
    );
  };

  function get_active_buttons() {
    console.log(externalArrayButtonsActive);
  }

  // const list_business = async () => {
  //   if (externalArrayButtonsActive.length === 0) {
  //     alert("Please select at least one service");
  //   }

  //   else {
  //     const business_name = externalArrayBusinessInputs[0].value;

  //     // Check if business name already exists
  //     const businessRef = doc(
  //       db,
  //       "businesses_contractor",
  //       String(business_name)
  //     );
  //     const businessSnapshot = await getDoc(businessRef);

  //     if (businessSnapshot.exists()) {
  //       alert("The business name is already in use");
  //       return;
  //     }

  //     try {
  //       await setDoc(businessRef, {
  //         business_name: externalArrayBusinessInputs[0].value,
  //         business_email: externalArrayBusinessInputs[1].value,
  //         business_owner: externalArrayBusinessInputs[2].value,
  //         business_phone: externalArrayBusinessInputs[3].value,
  //         business_registration_number: externalArrayBusinessInputs[4].value,
  //         business_location: externalArrayBusinessInputs[5].value,
  //         business_suburb: externalArrayBusinessInputs[6].value,
  //         business_experience_years:
  //           externalArrayBusinessInputs[7].value.replace(/\D/g, ""),
  //         business_callout_fee: externalArrayBusinessInputs[8].value.replace(
  //           /\D/g,
  //           ""
  //         ),
  //         business_certifications: externalArrayBusinessInputs[9].value,
  //         business_recommeded_services: externalArrayBusinessInputs[9].value,
  //         services: externalArrayButtonsActive,
  //         date_joined: serverTimestamp(),
  //       });
  //     } catch (error) {
  //       console.log(error);
  //     }

  //     alert(
  //       "Congratulations! Your business has been successfully listed. Stay posted for new opportunities which will appear soon by email."
  //     );
  //   }
  // };


  // const [inputs, setInputs] = useState([
  //   { label: "BUSINESS NAME", value: "", required: true },
  //   { label: "BUSINESS EMAIL", value: "", required: true },
  //   { label: "BUSINESS OWNER", value: "", required: true },
  //   { label: "BUSINESS CONTACT NUMBER", value: "", required: true },
  //   { label: "BUSINESS REGISTRATION NUMBER (OPTIONAL)", value: "" },
  //   { label: "PROFESSIONAL REGISTRATION NUMBER (DRAWING SIGN-OFF)", value: "" },
  //   { label: "CITY", value: "", required: true },
  //   { label: "SUBURB", value: "", required: true },
  //   { label: "YEARS OF EXPERIENCE", value: "", required: true },
  //   { label: "STANDARD DESIGN FEE (HOURLY)", value: "", required: true },
  //   { label: "CERTIFICATIONS", value: "" },
  //   { label: "DONT SEE YOUR SERVICE? RECOMMEND IT.", value: "" },
  // ]);



  const list_business = async () => {
    // Check if service buttons are selected
    if (externalArrayButtonsActive.length === 0) {
      alert("Please select at least one service");
      return;
    }

    // Validate required fields
    const requiredFields = {
      "BUSINESS NAME": "Business Name",
      "BUSINESS EMAIL": "Business Email",
      "BUSINESS OWNER (NAME AND SURNAME)": "Business Owner (Name And Surname)",
      "BUSINESS CONTACT NUMBER": "Business Contact Number",
      CITY: "City",
      SUBURB: "Suburb",
      "YEARS OF EXPERIENCE": "Years of Experience",
      "STANDARD DESIGN FEE (HOURLY)": "Standard Design Fee (Hourly)",

      // business_name: "Business Name",
      // business_email: "Business Email",
      // business_owner: "Business Owner",
      // business_phone: "Business Contact Number",
      // business_location: "City",
      // business_suburb: "Suburb",
      // business_experience_years: "Years of Experience",
      // business_callout_fee: "Standard Call Out Fee",
    };
    for (const [key, label] of Object.entries(requiredFields)) {
      const input = externalArrayBusinessInputs.find(
        (input) => input.label === key
      );
      if (!input || !input.value.trim()) {
        alert(`Please fill in the ${label} field.`);
        return;
      }
    }
    // for (const [key, label] of Object.entries(requiredFields)) {
    //   const value = externalArrayBusinessInputs.find((input) =>
    //     input.label.includes(key.toUpperCase())
    //   )?.value;
    //   if (!value) {
    //     alert(`Please fill in the ${label} field.`);
    //     return;
    //   }
    // }

    // Business name check
    const business_name = externalArrayBusinessInputs[0].value;
    const businessRef = doc(db, "businesses_designer", String(business_name));
    const businessSnapshot = await getDoc(businessRef);

    if (businessSnapshot.exists()) {
      alert("The business name is already in use");
      return;
    }

    // Proceed with Firebase setDoc operation

    // const [inputs, setInputs] = useState([
    //   { label: "BUSINESS NAME", value: "", required: true },
    //   { label: "BUSINESS EMAIL", value: "", required: true },
    //   { label: "BUSINESS OWNER", value: "", required: true },
    //   { label: "BUSINESS CONTACT NUMBER", value: "", required: true },
    //   { label: "BUSINESS REGISTRATION NUMBER (OPTIONAL)", value: "" },
    //   { label: "PROFESSIONAL REGISTRATION NUMBER (DRAWING SIGN-OFF)", value: "" },
    //   { label: "CITY", value: "", required: true },
    //   { label: "SUBURB", value: "", required: true },
    //   { label: "YEARS OF EXPERIENCE", value: "", required: true },
    //   { label: "STANDARD DESIGN FEE (HOURLY)", value: "", required: true },
    //   { label: "CERTIFICATIONS", value: "" },
    //   { label: "DONT SEE YOUR SERVICE? RECOMMEND IT.", value: "" },
    // ]);


    try {
      await setDoc(businessRef, {
        business_name: externalArrayBusinessInputs[0].value,
        business_email: externalArrayBusinessInputs[1].value,
        business_owner: externalArrayBusinessInputs[2].value,
        business_phone: externalArrayBusinessInputs[3].value,
        business_registration_number: externalArrayBusinessInputs[4].value,
        business_professional_registration_number: externalArrayBusinessInputs[5].value,
        business_location: externalArrayBusinessInputs[6].value,
        business_suburb: externalArrayBusinessInputs[7].value,
        business_experience_years: externalArrayBusinessInputs[8].value.replace(
          /\D/g,
          ""
        ),
        business_design_fee_hourly: externalArrayBusinessInputs[9].value.replace(
          /\D/g,
          ""
        ),
        business_certifications: externalArrayBusinessInputs[10].value,
        business_recommeded_services: externalArrayBusinessInputs[11].value,
        services: externalArrayButtonsActive,
        date_joined: serverTimestamp(),
      });
      // alert("Congratulations! Your business has been successfully listed.");
      navigate("/congratulations_designer_list_business");
    } catch (error) {
      console.error(error);
      alert("An error occurred while listing your business.");
    }
  };

  return (
    <div className="h-screen smartphone:text-xs">
      <div className="flex items-center justify-center">
        {/* Header Section */}
        {/* <h1 className="text-white text-2xl">Header</h1> */}

        <div className="w-full h-100px flex items-center">
          <img
            className="h-16 md:h-32 w-16 md:w-32 p-1 md:p-5"
            src={logo}
            alt="Logo"
          />

          {/* Top Section */}
          <h1 className="w-8/10 md:w-max font-bold text-xs md:text-4xl text-colour_2 p-0 md:p-2 justify-center">
            LIST YOUR DESIGN BUSINESS TO VIEW DESIGN OPPORTUNITIES
          </h1>
        </div>
      </div>

      <div className="h-9/10 flex p-2">
        <div className="w-1/2 flex flex-col items-center">
          {/* Image */}

          {/* Heading */}
          <h1 className="font-semibold md:text-xl text-xs text-colour_2 p-2">
            ENTER YOUR BUSINESS DETAILS
          </h1>
          <div>
            <div>
              <TextInputArray onInputsChange={handleInputsChange} />
            </div>
          </div>
        </div>

        <div className="w-1/2 flex flex-col items-center">
          {/* Image */}

          {/* Heading */}
          <h1 className="font-semibold md:text-xl text-xs text-colour_2 p-2">
            SELECT THE DESIGN CATAGORIES YOU OFFER
          </h1>

          <div>
            <ButtonArray buttons={buttons} onButtonClick={handleButtonClick} />
          </div>

          <div>
            <div className="p-3">
              <button
                className="w-3/4 md:w-full rounded-lg bg-colour_2 text-white p-4  text-xs md:text-lg"
                onClick={() => {
                  list_business();
                }}
              >
                REGISTER BUSINESS
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
