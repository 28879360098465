import React, { useState, useEffect, useCallback } from "react";
import { db } from "../../backend/firebase/firebase-config";
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  doc,
  deleteDoc,
  serverTimestamp,
} from "firebase/firestore";
import logo from "../../data/assets/logo/openbuilder_logo.png";
import { useAuth } from "../../backend/firebase/AuthContext";

const AdminToDo = () => {
  const { user, handleSignOut, isAdmin } = useAuth();
  const [updates, setUpdates] = useState([]);
  const [updateText, setUpdateText] = useState("");
  const [updateUrl, setUpdateUrl] = useState("");
  const [startDate, setStartDate] = useState("");
  const [editId, setEditId] = useState(null);
  const [editText, setEditText] = useState("");
  const [subItemText, setSubItemText] = useState({});
  const [editSubItemId, setEditSubItemId] = useState(null);
  const [editSubItemText, setEditSubItemText] = useState("");
  const updatesRef = collection(db, "updates");
  const [initialFetchDone, setInitialFetchDone] = useState(false);

  const fetchUpdates = useCallback(async () => {
    const querySnapshot = await getDocs(updatesRef);
    const updatesList = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
      subItems: doc.data().subItems || [], // Ensure subItems is an array
    }));
    updatesList.sort((a, b) =>
      a.timestamp.seconds < b.timestamp.seconds ? -1 : 1
    );

    console.log(updatesList);

    setUpdates(updatesList);
    setInitialFetchDone(true);
  }, [updatesRef]);

  useEffect(() => {
    if (!initialFetchDone) {
      fetchUpdates();
    }
  }, [fetchUpdates, initialFetchDone]);

  const addUpdate = useCallback(async () => {
    if (updateText.trim()) {
      const newUpdate = {
        text: updateText,
        url: updateUrl,
        startDate,
        completeDate: "",
        timestamp: serverTimestamp(),
        subItems: [], // Initialize subItems as an empty array
      };

      try {
        const docRef = await addDoc(updatesRef, newUpdate);
        setUpdates((prevUpdates) => [
          ...prevUpdates,
          { id: docRef.id, ...newUpdate },
        ]);
        setUpdateText("");
        setUpdateUrl("");
        setStartDate("");
      } catch (error) {
        console.error("Error adding update: ", error);
      }
    }
  }, [updateText, updateUrl, startDate, updatesRef]);

  const saveUpdate = useCallback(
    async (id) => {
      const updateRef = doc(db, "updates", id);
      try {
        await updateDoc(updateRef, {
          text: editText,
        });
        setUpdates(
          updates.map((update) =>
            update.id === id ? { ...update, text: editText } : update
          )
        );
        setEditId(null);
        setEditText("");
      } catch (error) {
        console.error("Error saving update: ", error);
      }
    },
    [editText, updates]
  );

  const markAsComplete = useCallback(
    async (id) => {
      const now = serverTimestamp();
      try {
        await updateDoc(doc(db, "updates", id), {
          completeDate: now,
        });
        setUpdates(
          updates.map((update) => {
            if (update.id === id) {
              return {
                ...update,
                completeDate: new Date().toISOString().split("T")[0],
              };
            }
            return update;
          })
        );
      } catch (error) {
        console.error("Error marking update as complete: ", error);
      }
    },
    [updates]
  );

  const deleteUpdate = useCallback(
    async (id) => {
      try {
        await deleteDoc(doc(db, "updates", id));
        setUpdates(updates.filter((update) => update.id !== id));
      } catch (error) {
        console.error("Error deleting update: ", error);
      }
    },
    [updates]
  );

  const addSubItem = useCallback(
    async (updateId) => {
      const text = subItemText[updateId] || "";
      if (text.trim()) {
        const updateRef = doc(db, "updates", updateId);
        const update = updates.find((u) => u.id === updateId);
        const newSubItem = {
          id: Date.now().toString(),
          text,
          completeDate: "",
        };

        const updatedSubItems = [...(update.subItems || []), newSubItem];
        try {
          await updateDoc(updateRef, {
            subItems: updatedSubItems,
          });

          setUpdates(
            updates.map((u) =>
              u.id === updateId ? { ...u, subItems: updatedSubItems } : u
            )
          );
          setSubItemText((prev) => ({ ...prev, [updateId]: "" }));
        } catch (error) {
          console.error("Error adding sub-item: ", error);
        }
      }
    },
    [subItemText, updates]
  );

  const saveSubItem = useCallback(
    async (updateId, subItemId) => {
      const updateRef = doc(db, "updates", updateId);
      const update = updates.find((u) => u.id === updateId);
      const updatedSubItems = update.subItems.map((subItem) =>
        subItem.id === subItemId
          ? { ...subItem, text: editSubItemText }
          : subItem
      );

      try {
        await updateDoc(updateRef, {
          subItems: updatedSubItems,
        });

        setUpdates(
          updates.map((u) =>
            u.id === updateId ? { ...u, subItems: updatedSubItems } : u
          )
        );
        setEditSubItemId(null);
        setEditSubItemText("");
      } catch (error) {
        console.error("Error saving sub-item: ", error);
      }
    },
    [editSubItemText, updates]
  );

  const markSubItemAsComplete = useCallback(
    async (updateId, subItemId) => {
      const updateRef = doc(db, "updates", updateId);
      const update = updates.find((u) => u.id === updateId);
      const updatedSubItems = update.subItems.map((subItem) =>
        subItem.id === subItemId
          ? { ...subItem, completeDate: new Date().toISOString().split("T")[0] }
          : subItem
      );

      try {
        await updateDoc(updateRef, {
          subItems: updatedSubItems,
        });

        setUpdates(
          updates.map((u) =>
            u.id === updateId ? { ...u, subItems: updatedSubItems } : u
          )
        );
      } catch (error) {
        console.error("Error marking sub-item as complete: ", error);
      }
    },
    [updates]
  );

  const deleteSubItem = useCallback(
    async (updateId, subItemId) => {
      const updateRef = doc(db, "updates", updateId);
      const update = updates.find((u) => u.id === updateId);
      const updatedSubItems = update.subItems.filter(
        (subItem) => subItem.id !== subItemId
      );

      try {
        await updateDoc(updateRef, {
          subItems: updatedSubItems,
        });

        setUpdates(
          updates.map((u) =>
            u.id === updateId ? { ...u, subItems: updatedSubItems } : u
          )
        );
      } catch (error) {
        console.error("Error deleting sub-item: ", error);
      }
    },
    [updates]
  );

  const timestampToDate = (timestamp) => {
    if (typeof timestamp === "object" && "seconds" in timestamp) {
      return new Date(timestamp.seconds * 1000);
    }
    if (typeof timestamp === "string") {
      const date = new Date(timestamp);
      return !isNaN(date.getTime()) ? date : null;
    }
    return null;
  };

  const formatDate = (date) => {
    if (!date) return "";
    return date.toISOString().split("T")[0];
  };

  if (!isAdmin) {
    return <h1>Not an admin</h1>;
  }

  return (
    <div className="w-full md:full p-4">
      <div className="flex items-center justify-center mb-4">
        <div className="w-full flex items-center">
          <img
            className="h-16 md:h-32 w-16 md:w-32 p-1 md:p-5"
            src={logo}
            alt="Logo"
          />
          <h1 className="w-8/10 md:w-max font-bold text-xs md:text-4xl text-colour_2 p-0 md:p-2 justify-center">
            ADMIN UPDATES - TO DO LIST FOR IMPROVING THE PLATFORM
          </h1>
        </div>
      </div>

      <div className="mb-4">
        <input
          type="text"
          className="w-full h-10 p-2 text-sm md:text-md border-2 border-gray-300 rounded-lg mb-2"
          placeholder="Add a new update"
          value={updateText}
          onChange={(e) => setUpdateText(e.target.value)}
        />
        <input
          type="text"
          className="w-full h-10 p-2 text-sm md:text-md border-2 border-gray-300 rounded-lg mb-2"
          placeholder="Add a URL (optional)"
          value={updateUrl}
          onChange={(e) => setUpdateUrl(e.target.value)}
        />
        <input
          type="date"
          className="w-full h-10 p-2 text-sm md:text-md border-2 border-gray-300 rounded-lg mb-2"
          placeholder="Start Date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
        />
        <button
          className="w-full md:w-auto px-4 py-2 bg-colour_2 text-white rounded-lg text-xs md:text-xl"
          onClick={addUpdate}
        >
          Submit Update
        </button>
      </div>

      <div>
        <h2 className="font-bold text-lg mb-2">Ongoing Updates</h2>
        <ul className="list-disc pl-5 mb-6">
          {updates
            .filter((update) => !update.completeDate)
            .map((update, index) => (
              <li key={update.id} className="mb-4">
                <div className="flex justify-between items-center">
                  <div className="flex items-center">
                    <span
                      className={`inline-block w-3 h-3 rounded-full mr-2 ${
                        update.completeDate ? "bg-green-500" : "bg-red-500"
                      }`}
                    ></span>
                    {editId === update.id ? (
                      <input
                        type="text"
                        value={editText}
                        onChange={(e) => setEditText(e.target.value)}
                        className="text-sm md:text-md"
                      />
                    ) : (
                      <>
                        {`${index + 1}. ${update.text}`}
                        {update.startDate && (
                          <span>
                            {" "}
                            (Start:{" "}
                            {formatDate(timestampToDate(update.startDate))})
                          </span>
                        )}
                      </>
                    )}
                  </div>
                  <div className="flex">
                    {editId === update.id ? (
                      <button
                        className="ml-2 bg-green-500 text-white p-1 rounded"
                        onClick={() => saveUpdate(update.id)}
                      >
                        Save
                      </button>
                    ) : (
                      <>
                        <button
                          className="ml-2 bg-blue-500 text-white p-1 rounded"
                          onClick={() => {
                            setEditId(update.id);
                            setEditText(update.text);
                          }}
                        >
                          Edit
                        </button>
                        <button
                          className="ml-2 bg-blue-500 text-white p-1 rounded"
                          onClick={() => markAsComplete(update.id)}
                        >
                          Mark as Complete
                        </button>
                        <button
                          className="ml-2 bg-red-500 text-white p-1 rounded"
                          onClick={() => deleteUpdate(update.id)}
                        >
                          Delete
                        </button>
                      </>
                    )}
                  </div>
                </div>
                <div className="ml-10 mt-2">
                  <input
                    type="text"
                    className="w-full h-8 p-1 text-sm border-2 border-gray-300 rounded-lg mb-2"
                    placeholder="Add a sub-item"
                    value={subItemText[update.id] || ""}
                    onChange={(e) =>
                      setSubItemText((prev) => ({
                        ...prev,
                        [update.id]: e.target.value,
                      }))
                    }
                  />
                  <button
                    className="w-full md:w-auto px-2 py-1 bg-colour_2 text-white rounded-lg text-xs md:text-sm"
                    onClick={() => addSubItem(update.id)}
                  >
                    Add Sub-item
                  </button>
                  <ul className="list-disc pl-5 mt-2">
                    {update.subItems &&
                      update.subItems.map((subItem) => (
                        <li
                          key={subItem.id}
                          className="text-sm mb-1 flex justify-between items-center"
                        >
                          <div className="flex items-center">
                            <span
                              className={`inline-block w-2 h-2 rounded-full mr-2 ${
                                subItem.completeDate
                                  ? "bg-green-500"
                                  : "bg-red-500"
                              }`}
                            ></span>
                            {editSubItemId === subItem.id ? (
                              <input
                                type="text"
                                value={editSubItemText}
                                onChange={(e) =>
                                  setEditSubItemText(e.target.value)
                                }
                                className="text-sm"
                              />
                            ) : (
                              <>{subItem.text}</>
                            )}
                          </div>
                          <div className="flex">
                            {editSubItemId === subItem.id ? (
                              <button
                                className="ml-2 bg-green-500 text-white p-1 rounded"
                                onClick={() =>
                                  saveSubItem(update.id, subItem.id)
                                }
                              >
                                Save
                              </button>
                            ) : (
                              <>
                                <button
                                  className="ml-2 bg-blue-500 text-white p-1 rounded"
                                  onClick={() =>
                                    markSubItemAsComplete(update.id, subItem.id)
                                  }
                                >
                                  Mark as Complete
                                </button>
                                <button
                                  className="ml-2 bg-red-500 text-white p-1 rounded"
                                  onClick={() =>
                                    deleteSubItem(update.id, subItem.id)
                                  }
                                >
                                  Delete
                                </button>
                              </>
                            )}
                          </div>
                        </li>
                      ))}
                  </ul>
                </div>
              </li>
            ))}
        </ul>

        <h2 className="font-bold text-lg mb-2">Completed Updates</h2>
        <ul className="list-disc pl-5">
          {updates
            .filter((update) => update.completeDate)
            .map((update, index) => (
              <li key={update.id} className="mb-4">
                <div className="flex justify-between items-center">
                  <div className="flex items-center">
                    <span
                      className={`inline-block w-3 h-3 rounded-full mr-2 ${
                        update.completeDate ? "bg-green-500" : "bg-red-500"
                      }`}
                    ></span>
                    {`${index + 1}. ${update.text}`}
                    {update.startDate && (
                      <span>
                        {" "}
                        (Start: {formatDate(timestampToDate(update.startDate))})
                      </span>
                    )}
                    {update.completeDate && (
                      <span>
                        {" "}
                        (Complete:{" "}
                        {formatDate(timestampToDate(update.completeDate))})
                      </span>
                    )}
                  </div>
                  <div className="flex">
                    <button
                      className="ml-2 bg-red-500 text-white p-1 rounded"
                      onClick={() => deleteUpdate(update.id)}
                    >
                      Delete
                    </button>
                  </div>
                </div>
                <div className="ml-10 mt-2">
                  <ul className="list-disc pl-5 mt-2">
                    {update.subItems &&
                      update.subItems.map((subItem) => (
                        <li
                          key={subItem.id}
                          className="text-sm mb-1 flex justify-between items-center"
                        >
                          <div className="flex items-center">
                            <span
                              className={`inline-block w-2 h-2 rounded-full mr-2 ${
                                subItem.completeDate
                                  ? "bg-green-500"
                                  : "bg-red-500"
                              }`}
                            ></span>
                            {subItem.text}
                            {subItem.completeDate && (
                              <span>
                                {" "}
                                (Complete:{" "}
                                {formatDate(
                                  timestampToDate(subItem.completeDate)
                                )}
                                )
                              </span>
                            )}
                          </div>
                        </li>
                      ))}
                  </ul>
                </div>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};

export default React.memo(AdminToDo);
