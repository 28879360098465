import React from "react";
import { Radar } from "react-chartjs-2";
import Chart from "chart.js/auto";

const ExperienceVsServiceRadarChart = React.memo(({ data }) => {
  // Process data to find average experience for each service
  const experienceByService = {};
  data.forEach((item) => {
    const experience = parseInt(item.business_experience_years, 10) || 0;
    item.services.forEach((service) => {
      if (!experienceByService[service]) {
        experienceByService[service] = { totalExperience: 0, count: 0 };
      }
      experienceByService[service].totalExperience += experience;
      experienceByService[service].count++;
    });
  });

  const chartData = {
    labels: Object.keys(experienceByService),
    datasets: [
      {
        label: "Average Years of Experience",
        data: Object.values(experienceByService).map(
          (service) => service.totalExperience / service.count
        ),
        backgroundColor: "rgba(53, 162, 235, 0.2)",
        borderColor: "rgba(53, 162, 235, 1)",
        borderWidth: 1,
        pointBackgroundColor: "rgba(53, 162, 235, 1)",
        pointBorderColor: "#fff",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "rgba(53, 162, 235, 1)",
      },
    ],
  };

  return (
    <div style={{ height: "90%", width: "90%" }}>
      <Radar data={chartData} />
    </div>
  );
});
export default ExperienceVsServiceRadarChart;
