import React, { useState, useEffect } from "react";
import logo from "../../data/assets/logo/openbuilder_logo.png";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchPricedByName } from "../../backend/lists/fetchData";
import { addFieldToPricedJob } from "../../backend/lists/setData";
import PriceComparisonChart from "../../backend/graphs/PriceComparisonChart";
import { useAuth } from "../../backend/firebase/AuthContext";

export function PriceComparison() {
  const { state } = useLocation();
  const { project } = state;
  const [priced, setPriced] = useState([]);
  const { user, handleSignOut, isAdmin } = useAuth();
  const [contractors, setContractors] = useState([]);
  const [prices, setPrices] = useState([
    { name: "Contractor 1", value: "R1000", actual_contractor: "" },
    { name: "Contractor 2", value: "R5000", actual_contractor: "" },
    { name: "Contractor 3", value: "R2000", actual_contractor: "" },
  ]);
  const navigate = useNavigate();

  useEffect(() => {
    async function getData() {
      const postsData2 = await fetchPricedByName(project);

      console.log(postsData2.posts);

      setPriced(postsData2.posts);
      // Assuming each post in postsData2.posts is a group of contractors and their data
      const contractorsData = postsData2.posts.map((post) => ({
        contractors: post.contractors,
      }));
      setContractors(contractorsData);

      let totalSums = [];
      for (let contractor in postsData2.posts[0].contractors) {
        if (postsData2.posts[0].contractors[contractor].prices) {
          let sum = 0;
          for (let price in postsData2.posts[0].contractors[contractor]
            .prices) {
            sum += postsData2.posts[0].contractors[contractor].prices[price];
          }
          totalSums.push({ contractor: contractor, total: sum });
        }
      }
      totalSums.sort((a, b) => b.total - a.total);
      let topThreeContractors = totalSums.slice(0, 3);

      const newPrices = topThreeContractors.map((contractor, index) => ({
        name: `Contractor ${index + 1}`,
        actual_contractor: contractor.contractor,
        value: `R ${contractor.total}`,
      }));
      setPrices(newPrices);
    }

    getData();
  }, [project]);

  const handleContractorSelection = async (contractorName) => {
    try {
      await addFieldToPricedJob(
        project,
        "client_selected_contractor",
        contractorName
      );
      await addFieldToPricedJob(project, "contractor_mapping", prices);
      alert(`You have selected ${contractorName}`);
      navigate("/client_projects");
    } catch (error) {
      console.error("Error selecting contractor: ", error);
    }
  };

  // return (
  //   <div className="flex flex-col items-center justify-center h-screen">
  //     <img className="md:h-18 w-32 p-5" src={logo} alt="Logo" />
  //     <div className="md:w-1/2 w-3/4 flex justify-between">
  //       <div className="p-4 border border-gray-200 rounded shadow h-128">
  //         <h1>Contractor Price Comparison</h1>
  //         <PriceComparisonChart contractorsData={contractors} />
  //       </div>

        // {prices.map((price, index) => (
        //   <div
        //     key={index}
        //     className="flex flex-col items-center p-4 border-2 border-gray-300 rounded-lg"
        //   >
        //     <h3 className="md:text-lg text-md font-bold mb-2 text-colour_2">
        //       {price.name}
        //     </h3>
        //     <p className="md:text-xl text-lg text-gray-600 mb-4">
        //       {price.value}
        //     </p>
        //     <button
        //       className="w-full bg-colour_2 text-white rounded-lg p-2"
        //       onClick={() => handleContractorSelection(price.name)}
        //     >
        //       Choose {price.name}
        //     </button>
        //   </div>
        // ))}
  //     </div>
  //   </div>
  // );
  

  return (
    <div className="admin-dashboard">
      <div className="h-24 flex items-center justify-center">
        {/* Header Section */}
        {/* <h1 className="text-white text-2xl">Header</h1> */}

        <div className="w-full h-100px flex items-center">
          <img className="md:h-18 w-32 p-5" src={logo} alt="Logo" />
        </div>

        <div className="h-1/2 flex flex-col justify-center items-center">
          {/* Top Section */}
          <h1 className="md:w-max w-8/10 font-bold text-xs md:text-4xl  text-colour_2">
            PRICING COMPARISON
          </h1>
        </div>

        <div className="w-full h-100px flex items-center justify-between">
          <div className="flex items-center"></div>
        </div>
      </div>

      <div>
        <div className="grid md:grid-cols-2 grid-rows-2 gap-5">
          {/* Top Left: Bar Chart */}
          <div className="p-5 border border-gray-200 rounded shadow h-128">
              <h1>Contractor Price Comparison</h1>
              <br />
              <PriceComparisonChart contractorsData={contractors} />
          </div>

          {/* Top Right: Line Chart */}
          <div className="p-4 border border-gray-200 rounded shadow h-128">
            <div>
            {prices.map((price, index) => (
          <div
            key={index}
            className="flex flex-col items-center p-4 border-2 border-gray-300 rounded-lg"
          >
            <h3 className="md:text-lg text-md font-bold mb-2 text-colour_2">
              {price.name}
            </h3>
            <p className="md:text-xl text-lg text-gray-600 mb-4">
              {price.value}
            </p>
            <button
              className="w-full bg-colour_2 text-white rounded-lg p-2"
              onClick={() => handleContractorSelection(price.name)}
            >
              Choose {price.name}
            </button>
          </div>
        ))}
            </div>
          </div>

          {/* Bottom Left:  */}
          <div className="p-4 border border-gray-200 rounded shadow h-128">
            {/* <LineGraph data={data.lineGraphData} /> */}
            <div>
              {/* <StatisticsTable data={posts} /> */}
            </div>
          </div>

          {/* Bottom Right:  */}
          <div className="p-4 border border-gray-200 rounded shadow h-128 flex justify-center items-center">
            {/* <StackedBarChart data={data.stackedBarChartData} /> */}
            {/* <ExperienceVsServiceRadarChart data={posts} /> */}
          </div>
        </div>
      </div>
    </div>
  );
}
