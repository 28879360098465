import { Route, Routes } from "react-router-dom";
import React, { useState } from "react";

import background from "./backend/visual/background.png";

import { WelcomeClient } from "./screens/General/WelcomeClient";
import { WelcomeContractor } from "./screens/General/WelcomeContractor";
import { WelcomePage } from "./screens/General/WelcomePage";
import { CreateProfile } from "./screens/Authentication/CreateProfile";
import { SignIntoProfile } from "./screens/Authentication/SignIntoProfile";
import { ResetPassword } from "./screens/Authentication/ResetPassword";
import { Payments } from "./screens/Payments/Payments";
import { ClientBuildAlteration } from "./screens/ServicesClient/Services/ClientBuildAlteration";

import { ClientBuildAlterationNextGen } from "./screens/ServicesClient/Services/ClientBuildAlterationNextGen";

import { ContractorListBusiness } from "./screens/ServicesContractor/Services/ContractorListBusiness";
import { ContractorPricingEditor } from "./screens/ServicesContractor/Services/ContractorPricingEditor";

import { MaterialSupplierListBusiness } from "./screens/ServicesMaterialSupplier/Services/MaterialSupplierListBusiness";
import { DesignerListBusiness } from "./screens/ServicesDesigner/Services/DesignerListBusiness";
import { ContractorPricingEditorNextGen } from "./screens/ServicesContractor/Services/ContractorPricingEditorNextGen";

import { ProjectStatus } from "./screens/ProjectStatus/ProjectStatus";
import { Congratulations } from "./screens/Notifications/Congratulations";
import { CongratulationsClientBuildAlteration } from "./screens/Notifications/CongratulationsClientBuildAlteration";
import { CongratulationsContractorListBusiness } from "./screens/Notifications/CongratulationsContractorListBusiness";
import { CongratulationsDesignerListBusiness } from "./screens/Notifications/CongratulationsDesignerListBusiness";
import { CongratulationsMaterialSupplierListBusiness } from "./screens/Notifications/CongratulationsMaterialSupplierListBusiness";

import { Unsuccessful } from "./screens/Notifications/Unsuccessful";
import { ContractorOpportunities } from "./screens/ServicesContractor/Services/ContractorOpportunities";

import { ContractorOpportunitiesNextGen } from "./screens/ServicesContractor/Services/ContractorOpportunitiesNextGen";

import { ClientProjects } from "./screens/ServicesClient/Services/ClientProjects";

import { ClientProjectsNextGen } from "./screens/ServicesClient/Services/ClientProjectsNextGen";

import { PriceComparison } from "./screens/ProjectStatus/PriceComparison";
import { UniversalDashboard } from "./screens/UniversalDashboard/UniversalDashboard";

import { AdminDashboard } from "./screens/AdminDashboard/AdminDashboard";
import { AdminEmailSender } from "./screens/AdminDashboard/AdminEmailSender";
import { AdminContractorList } from "./screens/AdminDashboard/AdminContractorList";
import AdminToDo from "./screens/AdminDashboard/AdminToDo";
import UserJourneys from "./screens/UserJourneys/UserJourneys";

import { TestMultiple } from "./building_blocks/TestMultiple";

// import ParticlesBackground from './backend/visual/components/ParticlesBackground';
import { AuthProvider } from "./backend/firebase/AuthContext";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handleAuthChange = (status) => {
    setIsAuthenticated(status);
  };
  return (
    <>
      <div
        // className="bg-cover bg-center h-screen w-screen bg-no-repeat"
        // className="bg-cover bg-center min-h-screen min-w-full bg-no-repeat"
        // className="bg-cover bg-center h-screen w-screen bg-no-repeat bg-gradient-to-r from-blue-300 to-white"
        className="bg-cover bg-center min-h-screen min-w-full bg-no-repeat bg-gradient-to-r from-blue-300 to-white"

        // style={{ backgroundImage: `url(${background})` }}
      >
        <AuthProvider>
          <Routes>
            {/* Authentication Routes */}
            <Route path="/welcome_client" element={<WelcomeClient />} />
            <Route path="/welcome_contractor" element={<WelcomeContractor />} />
            <Route path="/" element={<WelcomePage />} />
            <Route
              path="/universal_dashboard"
              element={<UniversalDashboard />}
            />
            <Route
              path="/sign_into_profile"
              element={<SignIntoProfile onAuthChange={handleAuthChange} />}
            />
            <Route path="/create_profile" element={<CreateProfile />} />
            <Route path="/reset_password" element={<ResetPassword />} />
            {/* <Route
              path="/client_build_alteration"
              element={<ClientBuildAlteration />}
            /> */}

            <Route
              path="/client_build_alteration"
              element={<ClientBuildAlterationNextGen />}
            />

            <Route path="/client_projects" element={<ClientProjects />} />

            <Route
              path="/client_projects_next_gen"
              element={<ClientProjectsNextGen />}
            />

            <Route
              path="/create_business_profile"
              element={<ContractorListBusiness />}
            />

            <Route
              path="/create_business_profile_material_supplier"
              element={<MaterialSupplierListBusiness />}
            />
            <Route
              path="/create_business_profile_designer"
              element={<DesignerListBusiness />}
            />



            <Route
              path="/contractor_opportunities"
              element={<ContractorOpportunities />}
            />

            <Route
              path="/contractor_opportunities_next_gen"
              element={<ContractorOpportunitiesNextGen />}
            />

            <Route
              path="/contractor_pricing_editor/:projectId"
              element={<ContractorPricingEditor />}
            />

            <Route
              path="/contractor_pricing_editor_next_gen/:projectId"
              element={<ContractorPricingEditorNextGen />}
            />

            {/* Designer Routes */}
            <Route path="/payments" element={<Payments />} />
            {/* General Routes */}
            <Route path="/status" element={<ProjectStatus />} />
            <Route
              path="/client_contractor_price_comparison"
              element={<PriceComparison />}
            />
            {/* User Result Routes */}
            <Route path="/congratulations" element={<Congratulations />} />
            <Route path="/unsuccessful" element={<Unsuccessful />} />
            <Route
              path="/congratulations_client_build_alteration"
              element={<CongratulationsClientBuildAlteration />}
            />
            <Route
              path="/congratulations_contractor_list_business"
              element={<CongratulationsContractorListBusiness />}
            />

            <Route
              path="/congratulations_designer_list_business"
              element={<CongratulationsDesignerListBusiness />}
            />

            <Route
              path="/congratulations_material_supplier_list_business"
              element={<CongratulationsMaterialSupplierListBusiness />}
            />

            {/* Admin paths */}
            <Route path="/admin_dashboard" element={<AdminDashboard />} />
            <Route path="/admin_email_sender" element={<AdminEmailSender />} />
            {/* <Route
              path="/admin_contractor_list"
              element={<AdminContractorList />}
            /> */}
            <Route path="/admin_todo" element={<AdminToDo />} />

            {/* Test paths */}
            <Route path="/test_multiple" element={<TestMultiple />} />

            {/* User Journey Paths */}
            <Route path="/user_journeys" element={<UserJourneys />} />
          </Routes>
        </AuthProvider>
      </div>
    </>
  );
}
export default App;
