import React, { useState } from "react";
import { sendPasswordResetEmail, AuthErrorCodes } from "firebase/auth";
import { auth } from "../../backend/firebase/firebase-config";
import { NavLink, useNavigate } from "react-router-dom";
import logo from "../../data/assets/logo/openbuilder_logo.png";

export function ResetPassword() {
  const navigate = useNavigate();

  const [state, setState] = useState({
    email: "",
  });

  const handleInputChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { email } = state;

    try {
      await sendPasswordResetEmail(auth, email);
      // Handle successful password reset request
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <img src={logo} className="md:w-48 w-40 md:h-48 h-30 m-20" alt="Logo" />
      <form className="md:space-y-4 space-y-2 md:w-1/4 w-3/5" onSubmit={handleSubmit}>
        <input
          type="email"
          name="email"
          onChange={handleInputChange}
          placeholder="Email"
          className="w-full px-4 py-2 rounded-md border-colour_2 border-2 text-colour_2"
        />
        <button
          type="submit"
          className="w-full px-4 py-2 bg-colour_2 text-white rounded-md"
        >
          Reset Password
        </button>

        {/* <button
          type="submit"
          onClick={() => navigate("/sign_into_profile")}
          className="w-full px-4 py-2 bg-colour_2 text-white rounded-md"
        >
          Sign In
        </button> */}
      </form>
    </div>
  );
}
