import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  AuthErrorCodes,
} from "firebase/auth";
import { auth, db } from "../../backend/firebase/firebase-config";
import { setDoc, doc, serverTimestamp } from "firebase/firestore";
import logo from "../../data/assets/logo/openbuilder_logo.png";

export function CreateProfile() {
  const [state, setState] = useState({
    userType: "",
    firstName: "",
    lastName: "",
    companyName: "",
    contactNumber: "",
    email: "",
    password: "",
    confirmPassword: "",
    dateOfBirth: "",
    businessStartDate: "",
  });

  const navigate = useNavigate();

  const setUserDetails = async (userDetails) => {
    const {
      userType,
      firstName,
      lastName,
      companyName,
      contactNumber,
      email,
      dateOfBirth,
      businessStartDate,
    } = userDetails;

    try {
      const userDocRef = doc(db, userType, `${firstName}_${lastName}`);

      await setDoc(userDocRef, {
        name: firstName,
        surname: lastName,
        company: companyName,
        mobile: contactNumber,
        email: email,
        date_of_birth: dateOfBirth,
        business_start_date: businessStartDate,
        date_joined: serverTimestamp(),
      });
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const {
      userType,
      firstName,
      lastName,
      companyName,
      contactNumber,
      email,
      password,
      confirmPassword,
      dateOfBirth,
      businessStartDate,
    } = state;

    if (confirmPassword !== password) {
      alert("The confirmed password does not match. Please try again.");
      return;
    }

    if (
      !["homeowner", "contractor", "material_supplier", "designer"].includes(
        userType
      )
    ) {
      alert("Please confirm your user type.");
      return;
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      console.log(userCredential.user);

      setUserDetails({
        userType,
        firstName,
        lastName,
        companyName,
        contactNumber,
        email,
        dateOfBirth,
        businessStartDate,
      });

      sendEmailVerification(userCredential.user);
      navigate("/sign_into_profile");
    } catch (error) {
      console.error(error.code, error.message);
      if (error.code === AuthErrorCodes.EMAIL_EXISTS) {
        alert("Email In Use. Please use another email.");
      } else if (error.code === AuthErrorCodes.INVALID_EMAIL) {
        alert("Invalid Email. Please use a valid email.");
      } else {
        alert("An error occurred. Please try again later.");
      }
    }
  };

  const handleInputChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <img src={logo} className="md:w-48 w-40 md:h-48 h-30 m-20" alt="Logo" />
      <div>
        <h1 className="text-2xl font-bold text-colour_2 p-1">
          Choose your role
        </h1>
      </div>

      <form
        className="md:space-y-4 space-y-2 md:w-1/4 w-3/5"
        onSubmit={onSubmit}
      >
        <div className="flex justify-center space-x-4">
          <button
            type="button"
            onClick={() => setState({ ...state, userType: "homeowner" })}
            className={`px-4 py-2 ${
              state.userType === "homeowner"
                ? "bg-colour_2 text-white rounded-md"
                : "bg-gray-300 rounded-md"
            }`}
          >
            Homeowner
          </button>
          <button
            type="button"
            onClick={() => setState({ ...state, userType: "contractor" })}
            className={`px-4 py-2 ${
              state.userType === "contractor"
                ? "bg-colour_2 text-white rounded-md"
                : "bg-gray-300 rounded-md"
            }`}
          >
            Contractor
          </button>
        </div>
        <div className="flex justify-center space-x-4">
          <button
            type="button"
            onClick={() =>
              setState({ ...state, userType: "material_supplier" })
            }
            className={`px-4 py-2 ${
              state.userType === "material_supplier"
                ? "bg-colour_2 text-white rounded-md"
                : "bg-gray-300 rounded-md"
            }`}
          >
            Material Supplier
          </button>
          <button
            type="button"
            onClick={() => setState({ ...state, userType: "designer" })}
            className={`px-4 py-2 ${
              state.userType === "designer"
                ? "bg-colour_2 text-white rounded-md"
                : "bg-gray-300 rounded-md"
            }`}
          >
            Designer
          </button>
        </div>
        <input
          type="text"
          name="firstName"
          onChange={handleInputChange}
          placeholder="First name"
          className="md:w-full w-full px-4 py-2 rounded-md border-colour_2 border-2 text-colour_2"
        />
        <input
          type="text"
          name="lastName"
          onChange={handleInputChange}
          placeholder="Last name"
          className="w-full px-4 py-2 rounded-md border-colour_2 border-2 text-colour_2"
        />
        {state.userType !== "homeowner" && (
          <input
            type="text"
            name="companyName"
            onChange={handleInputChange}
            placeholder="Company name"
            className="w-full px-4 py-2 rounded-md border-colour_2 border-2 text-colour_2"
          />
        )}
        <h1 className="w-full text-colour_2">Date of Birth</h1>
        <input
          type="date"
          name="dateOfBirth"
          onChange={handleInputChange}
          placeholder="Date of Birth"
          className="w-full px-4 py-2 rounded-md border-colour_2 border-2 text-colour_2"
        />
        {state.userType !== "homeowner" && (
          <>
            <h1 className="w-full text-colour_2">Business Start Date</h1>
            <input
              type="date"
              name="businessStartDate"
              onChange={handleInputChange}
              placeholder="Business Start Date"
              className="w-full px-4 py-2 rounded-md border-colour_2 border-2 text-colour_2"
            />
          </>
        )}
        <input
          type="tel"
          name="contactNumber"
          onChange={handleInputChange}
          placeholder="Contact number"
          className="w-full px-4 py-2 rounded-md border-colour_2 border-2 text-colour_2"
        />
        <input
          type="email"
          name="email"
          onChange={handleInputChange}
          placeholder="Email"
          className="w-full px-4 py-2 rounded-md border-colour_2 border-2 text-colour_2 "
        />
        <input
          type="password"
          name="password"
          onChange={handleInputChange}
          placeholder="Password"
          className="w-full px-4 py-2 rounded-md border-colour_2 border-2 text-colour_2 "
        />
        <input
          type="password"
          name="confirmPassword"
          onChange={handleInputChange}
          placeholder="Confirm password"
          className="w-full px-4 py-2 rounded-md border-colour_2 border-2 text-colour_2"
        />
        <button
          type="submit"
          className="w-full px-4 py-2 bg-colour_2 text-white rounded-md"
        >
          Sign Up
        </button>
      </form>
    </div>
  );
}

// import React, { useState } from "react";
// import { NavLink, useNavigate } from "react-router-dom";
// import {
//   createUserWithEmailAndPassword,
//   sendEmailVerification,
//   AuthErrorCodes,
// } from "firebase/auth";
// import { auth, db } from "../../backend/firebase/firebase-config";
// import { setDoc, doc, serverTimestamp } from "firebase/firestore";
// import logo from "../../data/assets/logo/openbuilder_logo.png";

// // import { doc, setDoc, getDoc, serverTimestamp } from "firebase/firestore";

// export function CreateProfile() {
//   const [state, setState] = useState({
//     userType: "",
//     firstName: "",
//     lastName: "",
//     companyName: "",
//     contactNumber: "",
//     email: "",
//     password: "",
//     confirmPassword: "",
//   });

//   const navigate = useNavigate();

//   const setUserDetails = async (userDetails) => {
//     const { userType, firstName, lastName, companyName, contactNumber, email } =
//       userDetails;

//     try {
//       // Create a document reference with proper path segments
//       const userDocRef = doc(db, userType, `${firstName}_${lastName}`);

//       // Set the document data using setDoc()
//       await setDoc(userDocRef, {
//         name: firstName,
//         surname: lastName,
//         company: companyName,
//         mobile: contactNumber,
//         email: email,
//         date_joined: serverTimestamp(),
//       });
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   const onSubmit = async (e) => {
//     e.preventDefault();

//     const {
//       userType,
//       firstName,
//       lastName,
//       companyName,
//       contactNumber,
//       email,
//       password,
//       confirmPassword,
//     } = state;

//     if (confirmPassword !== password) {
//       alert("The confirmed password does not match. Please try again.");
//       return;
//     }

//     if (
//       !["homeowner", "contractor", "material_supplier", "designer"].includes(
//         userType
//       )
//     ) {
//       alert("Please confirm your user type.");
//       return;
//     }

//     try {
//       const userCredential = await createUserWithEmailAndPassword(
//         auth,
//         email,
//         password
//       );
//       console.log(userCredential.user);

//       // After sign up, you might want to set the user details somewhere
//       // like a user database. `setUserDetails` function is not defined
//       // in your code. This is just an example.
//       setUserDetails({
//         userType,
//         firstName,
//         lastName,
//         companyName,
//         contactNumber,
//         email,
//       });

//       sendEmailVerification(userCredential.user);
//       navigate("/sign_into_profile");
//     } catch (error) {
//       console.error(error.code, error.message);
//       if (error.code === AuthErrorCodes.EMAIL_EXISTS) {
//         alert("Email In Use. Please use another email.");
//       } else if (error.code === AuthErrorCodes.INVALID_EMAIL) {
//         alert("Invalid Email. Please use a valid email.");
//       } else {
//         alert("An error occurred. Please try again later.");
//       }
//     }
//   };

//   const handleInputChange = (e) => {
//     setState({
//       ...state,
//       [e.target.name]: e.target.value,
//     });
//   };

//   return (
//     <div className="flex flex-col items-center justify-center h-screen">
//       <img src={logo} className="md:w-48 w-40 md:h-48 h-30 m-20" alt="Logo" />
//       <div>
//         <h1 className="text-2xl font-bold text-colour_2 p-4">
//           Choose your role
//         </h1>
//       </div>

//       <form
//         className="md:space-y-4 space-y-2 md:w-1/4 w-3/5"
//         onSubmit={onSubmit}
//       >
//         <div className="flex justify-center space-x-4">
//           <button
//             type="button"
//             onClick={() => setState({ ...state, userType: "homeowner" })}
//             className={`px-4 py-2 ${
//               state.userType === "homeowner"
//                 ? "bg-colour_2 text-white rounded-md"
//                 : "bg-gray-300 rounded-md"
//             }`}
//           >
//             Homeowner
//           </button>
//           <button
//             type="button"
//             onClick={() => setState({ ...state, userType: "contractor" })}
//             className={`px-4 py-2 ${
//               state.userType === "contractor"
//                 ? "bg-colour_2 text-white rounded-md"
//                 : "bg-gray-300 rounded-md"
//             }`}
//           >
//             Contractor
//           </button>
//         </div>
//         <div className="flex justify-center space-x-4">
//           <button
//             type="button"
//             onClick={() =>
//               setState({ ...state, userType: "material_supplier" })
//             }
//             className={`px-4 py-2 ${
//               state.userType === "material_supplier"
//                 ? "bg-colour_2 text-white rounded-md"
//                 : "bg-gray-300 rounded-md"
//             }`}
//           >
//             Material Supplier
//           </button>
//           <button
//             type="button"
//             onClick={() => setState({ ...state, userType: "designer" })}
//             className={`px-4 py-2 ${
//               state.userType === "designer"
//                 ? "bg-colour_2 text-white rounded-md"
//                 : "bg-gray-300 rounded-md"
//             }`}
//           >
//             Designer
//           </button>
//         </div>
//         <input
//           type="text"
//           name="firstName"
//           onChange={handleInputChange}
//           placeholder="First name"
//           className="md:w-full w-full px-4 py-2 rounded-md border-colour_2 border-2 text-colour_2"
//         />
//         <input
//           type="text"
//           name="lastName"
//           onChange={handleInputChange}
//           placeholder="Last name"
//           className="w-full px-4 py-2 rounded-md border-colour_2 border-2 text-colour_2"
//         />
//         <input
//           type="text"
//           name="companyName"
//           onChange={handleInputChange}
//           placeholder="Company name"
//           className="w-full px-4 py-2 rounded-md border-colour_2 border-2 text-colour_2"
//         />
//         <input
//           type="tel"
//           name="contactNumber"
//           onChange={handleInputChange}
//           placeholder="Contact number"
//           className="w-full px-4 py-2 rounded-md border-colour_2 border-2 text-colour_2"
//         />
//         <input
//           type="email"
//           name="email"
//           onChange={handleInputChange}
//           placeholder="Email"
//           className="w-full px-4 py-2 rounded-md border-colour_2 border-2 text-colour_2 "
//         />
//         <input
//           type="password"
//           name="password"
//           onChange={handleInputChange}
//           placeholder="Password"
//           className="w-full px-4 py-2 rounded-md border-colour_2 border-2 text-colour_2 "
//         />
//         <input
//           type="password"
//           name="confirmPassword"
//           onChange={handleInputChange}
//           placeholder="Confirm password"
//           className="w-full px-4 py-2 rounded-md border-colour_2 border-2 text-colour_2"
//         />
//         <button
//           type="submit"
//           className="w-full px-4 py-2 bg-colour_2 text-white rounded-md"
//         >
//           Sign Up
//         </button>
//       </form>
//     </div>
//   );
// }
