import React, { useState, useEffect } from 'react';

function ProjectNameGenerator({ onProjectNameChange }) {
  const [projectName, setProjectName] = useState('');

  const generateProjectName = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < 8; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    setProjectName(result);
    onProjectNameChange(result); // Notify the parent component
  };

  // Use the useEffect hook to generate the project name when the component mounts
  useEffect(() => {
    generateProjectName();
  }, []); // The empty dependency array means this will run once when the component mounts

  return (
    <div>
      {/* <p>Project Name: {projectName}</p> */}
    </div>
  );
}

export default ProjectNameGenerator;
