import React, { useState, useEffect } from "react";
import FileSelectorMultiple from "../backend/upload/FileSelectorMultiple";

export const TestMultiple = () => {
    const [multipleFileUrls, setMultipleFileUrls] = useState({});

    const handleUrlReceivedMultiple = (id, urls) => {
      console.log("Received URLs for", id, ":", urls);
      setMultipleFileUrls((prevUrls) => ({ ...prevUrls, [id]: urls }));
    };

    // useEffect hook to log URLs once they're updated
    useEffect(() => {
        if (multipleFileUrls["multipleFiles"] && multipleFileUrls["multipleFiles"].length > 0) {
            console.log("All files uploaded. URLs:", multipleFileUrls["multipleFiles"]);
        }
    }, [multipleFileUrls]); // This effect depends on `multipleFileUrls` and runs whenever it changes

    return (
      <div className="w-3/4 p-4">
        <h1>TestMultiple</h1>
        <FileSelectorMultiple
          id="multipleFiles"
          text="UPLOAD MULTIPLE FILES"
          path={`testing/multiple_files_332024vdzdv/`}
          onUrlsReceived={handleUrlReceivedMultiple}
        />
  
        {/* Display URLs for multiple file uploads */}
        <div>
          <h2>Uploaded Multiple Files</h2>
          {multipleFileUrls["multipleFiles"] && multipleFileUrls["multipleFiles"].map((url, index) => (
            <div key={index}>
              <a href={url} target="_blank" rel="noopener noreferrer">File {index + 1}: {url}</a>
            </div>
          ))}
        </div>
      </div>
    );
};
