import React, { useRef, useState } from "react";
import Upload from "./Upload"; // Ensure this is adapted to handle progress with Tailwind CSS

const FileSelectorMultiple = ({ id, text, path, onUrlsReceived }) => {
  const fileInputRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [uploadProgress, setUploadProgress] = useState({});

  const handleButtonClick = (event) => {
    event.preventDefault(); // Stop the default form submission behavior
    event.stopPropagation(); // Stop the event from bubbling up
    fileInputRef.current.click();
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!isDragging) {
      setIsDragging(true);
    }
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    const files = e.dataTransfer.files;
    if (files.length) {
      uploadFiles(files);
    }
  };

  const handleFileChange = (e) => {
    const files = e.target.files;
    if (files.length) {
      uploadFiles(files);
    }
  };

  const uploadFiles = (files) => {
    const fileArray = Array.from(files);
    const uploadPromises = fileArray.map((file) => {
      const blob = new Blob([file], { type: file.type });
      setUploadProgress((prevProgress) => ({
        ...prevProgress,
        [file.name]: { progress: 0, completed: false },
      }));
      return Upload(path, file, blob, (progress) => {
        setUploadProgress((prevProgress) => ({
          ...prevProgress,
          [file.name]: { progress: progress, completed: progress === 100 },
        }));
      });
    });

    // Promise.all(uploadPromises)
    //   .then((urls) => {
    //     onUrlsReceived(id, urls); // Callback function to pass URLs to parent
    //     // Optionally notify user of complete upload
    //   })
    //   .catch((error) => console.error("Error uploading files:", error));

    Promise.all(uploadPromises)
      .then((urls) => {
        console.log("Received URLs:", urls); // This should log the URLs received from uploads
        onUrlsReceived(id, urls); // Make sure this is handling the URLs correctly
      })
      .catch((error) => console.error("Error uploading files:", error));
  };

  return (
    <div>
      <input
        type="file"
        multiple
        ref={fileInputRef}
        onChange={handleFileChange}
        className="hidden"
      />
      {/* <button
        onClick={() => fileInputRef.current.click()}
        className="w-full mt-4 border-2 rounded-lg border-colour_2 bg-colour_2 text-white py-2 px-4"
      >
        {text || "Select Files"}
      </button> */}
      <button
        onClick={handleButtonClick}
        className="w-full mt-4 border-2 rounded-lg border-colour_2 bg-colour_2 text-white py-2 px-4"
      >
        {text || "Select Files"}
      </button>

      <div
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        className={`border-dashed border-2 rounded-md border-colour_2 p-8 cursor-pointer text-center ${
          isDragging ? "bg-gray-100" : "bg-white"
        }`}
      >
        Drag and drop files here or click the button to select files
      </div>

      {Object.keys(uploadProgress).length > 0 && (
        <div className="mt-4">
          <h2 className="text-lg font-semibold">Upload Progress</h2>
          {Object.entries(uploadProgress).map(
            ([fileName, { progress, completed }]) => (
              <div key={fileName} className="mt-2">
                <span className="text-sm font-medium">{fileName}: </span>
                <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                  <div
                    className={`h-2.5 rounded-full ${
                      completed ? "bg-green-500" : "bg-blue-600"
                    }`}
                    style={{ width: `${progress}%` }}
                  ></div>
                </div>
                {completed && (
                  <span className="text-xs text-green-700">Completed</span>
                )}
              </div>
            )
          )}
        </div>
      )}
    </div>
  );
};

export default FileSelectorMultiple;

// import React, { useRef, useState } from "react";
// import Upload from "./Upload"; // Ensure this is adapted to handle progress with Tailwind CSS

// const FileSelectorMultiple = ({ id, text, path, onUrlsReceived }) => {
//   const fileInputRef = useRef(null);
//   const [isDragging, setIsDragging] = useState(false);
//   const [uploadProgress, setUploadProgress] = useState({});

//   const handleDragOver = (e) => {
//     e.preventDefault();
//     e.stopPropagation();
//     if (!isDragging) {
//       setIsDragging(true);
//     }
//   };

//   const handleDragEnter = (e) => {
//     e.preventDefault();
//     e.stopPropagation();
//   };

//   const handleDragLeave = (e) => {
//     e.preventDefault();
//     e.stopPropagation();
//     setIsDragging(false);
//   };

//   const handleDrop = (e) => {
//     e.preventDefault();
//     e.stopPropagation();
//     setIsDragging(false);
//     const files = e.dataTransfer.files;
//     if (files.length) {
//       uploadFiles(files);
//     }
//   };

//   const handleFileChange = (e) => {
//     const files = e.target.files;
//     if (files.length) {
//       uploadFiles(files);
//     }
//   };

//   const uploadFiles = (files) => {
//     const fileArray = Array.from(files);
//     const uploadPromises = fileArray.map((file) => {
//       const blob = new Blob([file], { type: file.type });
//       setUploadProgress((prevProgress) => ({
//         ...prevProgress,
//         [file.name]: 0,
//       }));
//       return Upload(path, file, blob, (progress) => {
//         setUploadProgress((prevProgress) => ({
//           ...prevProgress,
//           [file.name]: progress,
//         }));
//       });
//     });

//     Promise.all(uploadPromises)
//       .then((urls) => {
//         onUrlsReceived(id, urls); // Callback function to pass URLs to parent
//         // alert("Files uploaded successfully");
//         setUploadProgress({});
//       })
//       .catch((error) => console.error("Error uploading files:", error));
//   };

//   return (
//     <div>
//       <div
//         onDragOver={handleDragOver}
//         onDragEnter={handleDragEnter}
//         onDragLeave={handleDragLeave}
//         onDrop={handleDrop}
//         className={`border-dashed border-2 rounded-md border-colour_2 p-8 cursor-pointer text-center ${
//           isDragging ? "bg-gray-100" : "bg-white"
//         }`}
//       >
//         Drag and drop files here or click the button to select files
//       </div>
//       <input
//         type="file"
//         multiple
//         ref={fileInputRef}
//         onChange={handleFileChange}
//         className="hidden"
//       />
//       {/* <button
//         onClick={() => fileInputRef.current.click()}
//         className="mt-4 border-2 rounded-lg border-colour_2 bg-colour_2 text-white py-2 px-4"
//       >
//         {text || "Select Files"}
//       </button> */}
//       {Object.keys(uploadProgress).length > 0 && (
//         <div className="mt-4">
//           <h2 className="text-lg font-semibold">Upload Progress</h2>
//           {Object.entries(uploadProgress).map(([fileName, progress]) => (
//             <div key={fileName} className="mt-2">
//               <span className="text-sm font-medium">{fileName}: </span>
//               <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
//                 <div
//                   className="bg-blue-600 h-2.5 rounded-full"
//                   style={{ width: `${progress}%` }}
//                 ></div>
//               </div>
//             </div>
//           ))}
//         </div>
//       )}
//     </div>
//   );
// };

// export default FileSelectorMultiple;

// import React, { useRef, useState } from "react";
// import Upload from "./Upload"; // Make sure this can handle multiple file uploads

// const FileSelectorMultiple = ({ id, text, path, onUrlsReceived }) => {
//   const fileInputRef = useRef(null);
//   const [isDragging, setIsDragging] = useState(false);

//   const handleDrag = (e) => {
//     e.preventDefault();
//     e.stopPropagation();
//   };

//   const handleDrop = async (e) => {
//     handleDrag(e);
//     const files = e.dataTransfer.files;
//     uploadFiles(files);
//   };

//   const uploadFiles = async (files) => {
//     setIsDragging(false);
//     const uploads = Array.from(files).map(file => {
//       const blob = new Blob([file], { type: file.type });
//       return Upload(path, file, blob); // Adjusted Upload function to handle each file upload
//     });

//     try {
//       const urls = await Promise.all(uploads);
//       alert("Files uploaded successfully");
//       onUrlsReceived(id, urls); // Pass the ID and URLs to the parent component
//     } catch (error) {
//       console.error("Error uploading files:", error);
//     }
//   };

//   const handleFileChange = async (e) => {
//     const files = e.target.files;
//     uploadFiles(files);
//   };

//   return (
//     <div>
//       <div
//         onDragOver={handleDrag}
//         onDragEnter={() => setIsDragging(true)}
//         onDragLeave={() => setIsDragging(false)}
//         onDrop={handleDrop}
//         className={`drop-zone ${isDragging ? "dragging" : ""}`}
//       >
//         Drop files here or click to upload
//       </div>
//       <input
//         type="file"
//         multiple
//         ref={fileInputRef}
//         onChange={handleFileChange}
//         style={{ display: "none" }}
//       />
//       <button
//         onClick={() => fileInputRef.current.click()}
//         className="border-2 rounded-lg border-colour_2 bg-colour_2 text-white p-3 m-1"
//       >
//         <h1 className="text-xs">{text}</h1>
//       </button>
//     </div>
//   );
// };

// export default FileSelectorMultiple;

// import React, { useRef, useState } from "react";
// import Upload from "./Upload";

// const FileSelectorMultiple = ({ id, text, path, onUrlReceived }) => {
//   const fileInputRef = useRef(null);
//   const [selectedBlobs, setSelectedBlobs] = useState([]);
//   const [uploadComplete, setUploadComplete] = useState(false);

//   const handleFileChange = async () => {
//     const files = Array.from(fileInputRef.current.files);
//     if (files.length && !uploadComplete) {
//       const blobs = files.map(file => new Blob([file], { type: file.type }));
//       setSelectedBlobs(blobs);
//       try {
//         const urls = await Promise.all(files.map(file => Upload(path, file, new Blob([file], { type: file.type }))));
//         setUploadComplete(true);
//         alert("Files uploaded successfully");
//         urls.forEach((url, index) => onUrlReceived(id, url, index)); // Pass the ID and URL to the parent component
//       } catch (error) {
//         console.error("Error uploading the files:", error);
//       }
//     }
//   };

//   return (
//     <div>
//       <input
//         type="file"
//         multiple // Allow multiple file selection
//         ref={fileInputRef}
//         onChange={handleFileChange}
//         style={{ display: "none" }}
//       />
//       {selectedBlobs.length === 0 && (
//         <button
//           onClick={() => fileInputRef.current.click()}
//           className="border-2 rounded-lg border-colour_2 bg-colour_2 text-white p-3 m-1"
//         >
//           <h1 className="text-xs">{text}</h1>
//         </button>
//       )}
//     </div>
//   );
// };

// export default FileSelectorMultiple;
