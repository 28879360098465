import React, { useState } from "react";
import { UserPrompt } from "./UserPrompt";

// The ServiceCategory component that renders each service category
export const ServiceCategory = ({
  category,
  onSelect,
  selectedItems,
  handleRequestSubmit,
  project,
}) => {
  const [selectedItem, setSelectedItem] = useState(null);

  const handleButtonClick = (item) => {
    // Toggle the selectedItem state - if the item is already selected, deselect it.
    setSelectedItem(selectedItem === item ? null : item);
  };

  const closeUserPrompt = () => {
    setSelectedItem(null); // Deselect the item when closing the modal
  };

  // const handleRequestSubmit = (requestData) => {
  //   console.log("Request data submitted:", requestData);
  //   // Here you can handle the submitted data, including URLs
  //   // For example, you can update the state or make an API call
  // };

  return (
    <div className="bg-blue-100 p-6 rounded-lg shadow-lg max-w-sm my-4 relative">
      <h2 className="text-center text-lg font-semibold mb-4">
        {category.header}
      </h2>
      {category.items.map((item) => (
        <button
          key={item.id}
          className={`text-left w-full py-2 px-4 rounded-md shadow my-2 ${
            selectedItem === item
              ? "bg-blue-500 text-white"
              : "bg-white hover:bg-blue-500 hover:text-white"
          }`}
          onClick={() => handleButtonClick(item)}
        >
          {item.name}
        </button>
      ))}
      {selectedItem && (
        <UserPrompt
          isOpen={Boolean(selectedItem)}
          close={closeUserPrompt}
          item={selectedItem}
          header={category.header} // Pass the header here
          handleRequestSubmit={handleRequestSubmit}
          project={project}
        />
      )}
    </div>
  );
};

// import React, { useState } from "react";
// import { UserPrompt } from "./UserPrompt";

// // The ServiceCategory component that renders each service category
// export const ServiceCategory = ({
//   category,
//   onSelect,
//   selectedItems,
//   handleRequestSubmit,
//   project,
// }) => {
//   const [selectedItem, setSelectedItem] = useState(null);

//   const handleButtonClick = (item) => {
//     // Toggle the selectedItem state - if the item is already selected, deselect it.
//     setSelectedItem(selectedItem === item ? null : item);
//   };

//   const closeUserPrompt = () => {
//     setSelectedItem(null); // Deselect the item when closing the modal
//   };

//   return (
//     <div className="bg-blue-100 p-6 rounded-lg shadow-lg max-w-sm my-4 relative">
//       <h2 className="text-center text-lg font-semibold mb-4">
//         {category.header}
//       </h2>
//       {category.items.map((item) => (
//         <button
//           key={item.id}
//           className={`text-left w-full py-2 px-4 rounded-md shadow my-2
//                       ${
//                         selectedItem === item
//                           ? "bg-blue-500 text-white"
//                           : "bg-white hover:bg-blue-500 hover:text-white"
//                       }`}
//           onClick={() => handleButtonClick(item)}
//         >
//           {item.name}
//         </button>
//       ))}
//       {selectedItem && (
//         <UserPrompt
//           isOpen={Boolean(selectedItem)}
//           close={closeUserPrompt}
//           item={selectedItem}
//           header={category.header} // Pass the header here
//           handleRequestSubmit={handleRequestSubmit}
//           project={project}
//         />
//       )}
//     </div>
//   );

//   // return (
//   //   <div>
//   //     {/* Category UI elements */}
//   //     {category.items.map((item) => (
//   //       <button key={item.id} onClick={() => setSelectedItem(item)}>
//   //         {item.name}
//   //       </button>
//   //     ))}
//   //     {selectedItem && (
//   //       <UserPrompt
//   //         isOpen={Boolean(selectedItem)}
//   //         close={closeUserPrompt}
//   //         item={selectedItem}
//   //         handleRequestSubmit={handleRequestSubmit}
//   //       />
//   //     )}
//   //   </div>
//   // );
// };
