import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import logo from "../../data/assets/logo/openbuilder_logo.png";
import DoubleDown from "../../data/assets/icons/DoubleDown.png";
import { useAuth } from "../../backend/firebase/AuthContext";
import { UniversalDashboard } from "../UniversalDashboard/UniversalDashboard";
import { motion } from "framer-motion"; // Import motion component

export function WelcomePage() {
  const navigate = useNavigate();
  const { user, handleSignOut } = useAuth();
  const [logoSize, setLogoSize] = useState("30vw"); // Initial logo size

  const bounceAnimation = {
    y: ["0%", "-20%", "0%"],
    transition: {
      duration: 2,
      ease: "easeInOut",
      repeat: Infinity,
    },
  };

  const textAnimation = (delay) => ({
    opacity: [0, 1],
    y: [20, 0],
    transition: {
      duration: 0.5,
      ease: "easeOut",
      delay: delay,
    },
  });

  const logoAnimation = {
    opacity: [0, 1],
    transition: {
      duration: 1,
      ease: "easeOut",
    },
  };

  const characterAnimation = {
    hidden: { opacity: 0, y: 20 },
    visible: (i) => ({
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.03,
        delay: i * 0.03,
      },
    }),
  };

  useEffect(() => {
    function handleResize() {
      // Set the logo size smaller as the viewport width gets smaller (indicating zoom in)
      const newSize = Math.max(20, 30 - window.innerWidth / 100) + "vw";
      setLogoSize(newSize);
    }

    window.addEventListener("resize", handleResize);
    handleResize(); // Call once to set initial size

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const renderAnimatedText = (text) => {
    return text.split("").map((char, index) => (
      <motion.span key={index} custom={index} variants={characterAnimation} initial="hidden" animate="visible">
        {char}
      </motion.span>
    ));
  };

  return (
    <>
      <div className="flex flex-col md:flex-row min-h-screen">
        {/* Column 1 */}
        <div className="flex flex-col flex-grow">
          <div className="flex justify-center items-center m-3">
            {user ? (
              <div className="flex flex-col font-semibold text-base md:text-xl text-colour_2 p-2 text-center justify-center items-center">
                <div className="p-2">
                  <h1>Welcome ! {user.email}</h1>
                </div>
                <button
                  onClick={handleSignOut}
                  className="rounded-lg bg-colour_2 text-white p-2 text-base md:text-lg"
                >
                  Sign Out
                </button>
              </div>
            ) : (
              <div className="flex flex-col md:flex-row items-center">
                <div className="p-2 flex justify-center items-center">
                  <div className="p-1">
                    <button
                      className="rounded-lg bg-colour_2 text-white p-2 text-base md:text-lg"
                      onClick={() => navigate("/create_profile")}
                    >
                      Sign up
                    </button>
                  </div>

                  <div className="p-1">
                    <button
                      className="rounded-lg bg-colour_2 text-white p-2 text-base md:text-lg"
                      onClick={() => navigate("/sign_into_profile")}
                    >
                      Sign in
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="flex justify-center items-center">
            <motion.img
              src={logo}
              alt="Logo"
              style={{ width: logoSize, maxWidth: "800px", height: "auto" }}
              initial={{ opacity: 0 }}
              animate={logoAnimation}
            />
          </div>

          <div className="flex justify-center items-center">
            <div className="font-bold text-3xl md:text-9xl text-colour_2">
              <motion.h1 initial={{ opacity: 0, y: 20 }} animate={textAnimation(0.5)}>
                NEXT.
              </motion.h1>
              <motion.h1 initial={{ opacity: 0, y: 20 }} animate={textAnimation(1)}>
                GENERATION.
              </motion.h1>
              <motion.h1 initial={{ opacity: 0, y: 20 }} animate={textAnimation(1.5)}>
                CONSTRUCTION.
              </motion.h1>
            </div>
          </div>
          <div className="flex flex-col justify-center items-center">
            <div className="p-1 rounded-md m-4">
              <div className="font-bold text-xs md:text-4xl text-colour_2 pt-8">
                <h1>
                  {renderAnimatedText(
                    "Openbuilder AI is the go-to platform for homeowners, designers, contractors and material suppliers to make construction easy with all the solutions and tools required in one place."
                  )}
                </h1>
              </div>
            </div>
          </div>

          <div className="flex justify-center items-center">
            <motion.img
              src={DoubleDown}
              alt="Double Down"
              className="w-10 md:w-32 h-auto"
              animate={bounceAnimation}
              style={{ width: "5vw", maxWidth: "64px" }}
            />
          </div>
        </div>
      </div>

      <UniversalDashboard></UniversalDashboard>
    </>
  );
}

// import React, { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";

// import logo from "../../data/assets/logo/openbuilder_logo.png";
// import DoubleDown from "../../data/assets/icons/DoubleDown.png";
// import { useAuth } from "../../backend/firebase/AuthContext";
// import { UniversalDashboard } from "../UniversalDashboard/UniversalDashboard";
// import { motion } from "framer-motion"; // Import motion component

// export function WelcomePage() {
//   const navigate = useNavigate();
//   const { user, handleSignOut } = useAuth();
//   const [logoSize, setLogoSize] = useState("30vw"); // Initial logo size

//   const bounceAnimation = {
//     y: ["0%", "-20%", "0%"],
//     transition: {
//       duration: 2,
//       ease: "easeInOut",
//       repeat: Infinity,
//     },
//   };

//   useEffect(() => {
//     function handleResize() {
//       // Set the logo size smaller as the viewport width gets smaller (indicating zoom in)
//       const newSize = Math.max(20, 30 - window.innerWidth / 100) + "vw";
//       setLogoSize(newSize);
//     }

//     window.addEventListener("resize", handleResize);
//     handleResize(); // Call once to set initial size

//     return () => window.removeEventListener("resize", handleResize);
//   }, []);

//   return (
//     <>
//       <div className="flex flex-col md:flex-row min-h-screen">
//         {/* Column 1 */}
//         <div className="flex flex-col flex-grow">
//           <div className="flex justify-center items-center m-3">
//             {user ? (
//               <div className="flex flex-col font-semibold text-base md:text-xl text-colour_2 p-2 text-center justify-center items-center">
//                 <div className="p-2">
//                   <h1>Welcome ! {user.email}</h1>
//                 </div>
//                 <button
//                   onClick={handleSignOut}
//                   className="rounded-lg bg-colour_2 text-white p-2 text-base md:text-lg"
//                 >
//                   Sign Out
//                 </button>
//               </div>
//             ) : (
//               <div className="flex flex-col md:flex-row items-center">
//                 <div className="p-2 flex justify-center items-center">
//                   <div className="p-1">
//                     <button
//                       className="rounded-lg bg-colour_2 text-white p-2 text-base md:text-lg"
//                       onClick={() => navigate("/create_profile")}
//                     >
//                       Sign up
//                     </button>
//                   </div>

//                   <div className="p-1">
//                     <button
//                       className="rounded-lg bg-colour_2 text-white p-2 text-base md:text-lg"
//                       onClick={() => navigate("/sign_into_profile")}
//                     >
//                       Sign in
//                     </button>
//                   </div>
//                 </div>
//               </div>
//             )}
//           </div>
//           <div className="flex justify-center items-center">
//             <img
//               src={logo}
//               alt="Logo"
//               style={{ width: logoSize, maxWidth: "800px", height: "auto" }}
//             />
//           </div>

//           <div className="flex justify-center items-center">
//             <div className="font-bold text-3xl md:text-9xl text-colour_2">
//               <h1>NEXT.</h1>
//               <h1>GENERATION.</h1>
//               <h1>CONSTRUCTION.</h1>
//             </div>
//           </div>
//           <div className="flex flex-col justify-center items-center">
//             <div className="p-1 rounded-md m-4">
//               <div className="font-bold text-xs md:text-4xl text-colour_2 pt-8">
//                 <h1>
//                   Openbuilder AI is the go-to platform for homeowners, designers
//                   and contractors to make construction easy with all the
//                   solutions and tools required in one place.
//                 </h1>
//               </div>
//             </div>
//           </div>

//           <div className="flex justify-center items-center">
//             <motion.img
//               src={DoubleDown}
//               alt="Double Down"
//               className="w-10 md:w-32 h-auto"
//               animate={bounceAnimation}
//               style={{ width: "5vw", maxWidth: "64px" }}
//             />
//           </div>
//         </div>
//       </div>

//       <UniversalDashboard></UniversalDashboard>
//     </>
//   );
// }
