// CitySelector.js
import React from 'react';

function CitySelector({ value, onChange }) {
    // const cities = ["Cape Town", "Johannesburg"];
    const cities = ["Cape Town"];

    return (
        <select
            className="w-full md:h-1/2 h-1/4 text-sm text-xs md:p-2 p-0 text-colour_2 font-thin rounded-lg border-4 border-colour_2 m-2"
            value={value}
            onChange={onChange}
        >
            <option value="">--Select a city--</option>
            {cities.map((city) => (
                <option key={city} value={city}>
                    {city}
                </option>
            ))}
        </select>
    );
}

export default CitySelector;
