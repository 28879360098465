import React from "react";
import { useState, useEffect } from "react";
import logo from "../../data/assets/logo/openbuilder_logo.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../backend/firebase/AuthContext";
import {
  fetchPostsByName,
  fetchPricedByName,
} from "../../backend/lists/fetchData";
import completed from "../../data/assets/project/timelines/completed.png";
import busy from "../../data/assets/project/timelines/busy.png";
import { serverTimestamp } from "firebase/firestore";

export function ProjectStatus() {
  const { state } = useLocation();
  const { id, project } = state;
  const { user, handleSignOut } = useAuth();
  const navigate = useNavigate();

  const [unpriced, setUnpriced] = useState([]);
  const [priced, setPriced] = useState([]);
  const [client_choice_end_date, setClientChoiceEndDate] = useState([]);
  const [contractors_end_date, setContractorsEndDate] = useState([]);
  const [first_contractor_date, setFirstContractorDate] = useState([]);
  const [optimization_end_date, setOptimizationEndDate] = useState([]);

  const [showContractorChosenNote, setShowContractorChosenNote] =
    useState(false);

  const [first_contractor_date_state, setFirstContractorDate_status] =
    useState(false);
  const [contractors_end_date_state, setContractorsEndDate_status] =
    useState(false);
  const [optimization_end_date_state, setOptimizationEndDate_status] =
    useState(false);
  const [client_choice_end_date_state, setClientChoiceEndDate_status] =
    useState(false);

  const [contractors, setContractors] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [startDateStatus, setStartDateStatus] = useState("");
  const [paid, setPaid] = useState(false);
  // const [priced, setPriced] = useState([]);

  async function getData() {
    const postsData1 = await fetchPostsByName(project);

    setUnpriced(postsData1.posts);
    setStartDate(convertTimestampToDate(postsData1.posts[0].date_created));
    setPaid(postsData1.posts[0].paid);

    if (postsData1.posts[0].paid === true) {
      const currentTime = new Date(); // Current time as a JavaScript Date object
      const postsData2 = await fetchPricedByName(project);

      if (postsData2 && postsData2.posts && postsData2.posts[0]) {
        setPriced(postsData2.posts);
        setFirstContractorDate(postsData2.posts[0].first_contractor_date);
        setContractorsEndDate(postsData2.posts[0].contractors_end_date);
        setOptimizationEndDate(postsData2.posts[0].optimization_end_date);
        setClientChoiceEndDate(postsData2.posts[0].client_choice_end_date);

        // Convert Firebase Timestamps to JavaScript Date objects
        const firstContractorDateJS =
          postsData2.posts[0].first_contractor_date.toDate();
        const contractorsEndDateJS =
          postsData2.posts[0].contractors_end_date.toDate();
        const optimizationEndDateJS =
          postsData2.posts[0].optimization_end_date.toDate();
        const clientChoiceEndDateJS =
          postsData2.posts[0].client_choice_end_date.toDate();

        // Compare dates
        setFirstContractorDate_status(firstContractorDateJS < currentTime);
        setContractorsEndDate_status(contractorsEndDateJS < currentTime);
        setOptimizationEndDate_status(optimizationEndDateJS < currentTime);
        setClientChoiceEndDate_status(clientChoiceEndDateJS < currentTime);

        console.log(currentTime);
        console.log(postsData2.posts[0].first_contractor_date);
        console.log(postsData2.posts[0].contractors_end_date);
        console.log(postsData2.posts[0].optimization_end_date);
        console.log(postsData2.posts[0].client_choice_end_date);
      }
    }
  }

  function convertTimestampToDate(timestamp) {
    // Check if timestamp is defined and is a valid timestamp
    if (timestamp && timestamp.seconds) {
      const date = new Date(timestamp.seconds * 1000);
      return `${date.toLocaleString("default", {
        month: "long",
      })} ${date.getDate()}, ${date.getFullYear()}`;
    } else {
      // Return this string if the timestamp is invalid or undefined
      return "Date to be determined";
    }
  }

  useEffect(() => {
    getData();
  }, []);

  function logPosts() {
    // console.log(unpriced);
    // console.log(priced);
  }

  // const startDate = convertTimestampToDate(unpriced[0].date_created);

  // startDateStatus is 'completed' if the project has been started, else 'busy'
  const startDateStatusus = startDate ? "completed" : "busy";
  const paidStatus = paid ? "completed" : "busy";
  const wasPriced = priced.length > 0 ? "completed" : "busy";

  // const [client_choice_end_date, setClientChoiceEndDate] = useState([]);
  // const [contractors_end_date, setContractorsEndDate] = useState([]);
  // const [first_contractor_date, setFirstContractorDate] = useState([]);
  // const [optimization_end_date, setOptimizationEndDate] = useState([]);

  const clientChoiceEndDate = convertTimestampToDate(client_choice_end_date);
  const contractorsEndDate = convertTimestampToDate(contractors_end_date);
  const firstContractorDate = convertTimestampToDate(first_contractor_date);
  const optimizationEndDate = convertTimestampToDate(optimization_end_date);

  console.log("<--------------------");
  console.log(first_contractor_date_state);
  console.log(contractors_end_date_state);
  console.log(optimization_end_date_state);
  console.log(client_choice_end_date_state);
  console.log("-------------------->");

  function statusBusyOrCompleted(status) {
    if (status === true) {
      return "completed";
    } else {
      return "busy";
    }
  }

  function statusBusyOrCompletedClient(status) {
    if (status === true) {
      return "client_choice";
    } else {
      return "busy";
    }
  }

  const events = [
    {
      title: "Project Started",
      date: startDate,
      // date: "November 13, 2023",
      // date: startDate,
      description: "Home owner starts the project",
      status: startDateStatusus,
      color: "green",
    },

    {
      title: "Payment Received",
      date: "",
      description:
        "Home owner pays the callout fee (contact us for manual payment 0815872081)",
      status: paidStatus,
      color: "green",
    },

    // {
    //   title: "Openbuilder Requests for Pricing",
    //   date: "",
    //   description:
    //     "We reach out to the contractors to request pricing for the project",
    //   status: "busy",
    //   color: "green",
    // },

    // First contractor to be made aware that they are the first and must measure at the site add whatsapp link to notify
    // ID and business name

    {
      title: "Contractors begin to price",
      date: firstContractorDate,
      description:
        "Contractors begin to price the project for different services",
      status: statusBusyOrCompleted(first_contractor_date_state),
      color: "green",
    },

    {
      title: "All contractors pricing received",
      date: contractorsEndDate,
      description:
        "All contractors have submitted their pricing for the project",
      status: statusBusyOrCompleted(contractors_end_date_state),
      color: "green",
    },
    {
      title: "Openbuilder AI cost optimization",
      date: optimizationEndDate,
      description:
        "Openbuilder AI optimizes the pricing for the project from all contractor pricing received",
      status: statusBusyOrCompleted(optimization_end_date_state),
      color: "green",
    },
    {
      title: "Client selects contractors",
      date: clientChoiceEndDate,
      description:
        "Client selects contractors for the project based on the optimized pricing",
      status: statusBusyOrCompletedClient(client_choice_end_date_state),
      color: "red",
    },
  ];

  // Add a new function to handle the client's selection
  const handleClientChoice = () => {
    // Check if a contractor has already been selected
    const hasContractorBeenChosen = priced.some(
      (job) => job.client_selected_contractor
    );

    if (hasContractorBeenChosen) {
      // Set the state to show the warning message
      setShowContractorChosenNote(true);
    } else {
      // Navigate to the contractor selection page
      navigate("/client_contractor_price_comparison", { state: { project } });
    }
  };

  // Function to determine button background color based on status
  const getButtonColor = (color) => {
    switch (color) {
      case "green":
        return "bg-green-500";
      case "red":
        return "bg-red-500";
      case "yellow":
        return "bg-yellow-500";
      default:
        return "bg-gray-500";
    }
  };

  return (
    <div className="w-full p-0 md:p-1 flex flex-col items-center justify-center h-screen">
      <img src={logo} alt="Logo" className="w-20 md:w-40 h-20 md:h-40" />

      {/* Warning message if a contractor has already been selected */}
      {showContractorChosenNote && (
        <div
          className="alert bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
          role="alert"
        >
          <strong className="font-bold">Warning: </strong>
          <span className="block sm:inline">
            A contractor has already been selected for this project.
          </span>
        </div>
      )}

      <div className="w-full md:w-2/3">
        {events.map((event, index) => (
          <div
            key={index}
            className="grid grid-cols-3 g-0 md:gap-4 items-center mb-0 md:mb-8 border-b border-black p-2 md:p-2"
          >
            <div className="flex flex-col">
              <h3 className="text-xs md:text-sm  font-bold text-colour_2">
                {event.title}
              </h3>
              <p className="text-xs md:text-sm  text-gray-600">
                {event.description}
              </p>
            </div>
            <p className="text-xs md:text-sm text-gray-500 text-center">
              {event.date}
            </p>
            <div className="text-center">
              {event.status === "completed" ? (
                <img
                  className="w-14 md:h-14  p-1 inline-block"
                  src={completed}
                  alt="Completed"
                />
              ) : event.status === "busy" ? (
                <img
                  className="w-14 md:h-14  p-1 inline-block"
                  src={busy}
                  alt="Busy"
                />
              ) : event.status === "client_choice" ? (
                <button
                  onClick={handleClientChoice}
                  className="bg-colour_2 text-white rounded-lg p-1 md:p-2 m-2 text-xs md:text-base"
                >
                  Press to choose a contractor
                </button>
              ) : (
                <span
                  className={`${getButtonColor(
                    event.color
                  )} inline-block align-middle`}
                >
                  {event.status}
                </span>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
