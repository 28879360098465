import * as React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import logo from "../../data/assets/logo/openbuilder_logo.png";

const UserJourneys = () => {
  return (
    <div className="items-center justify-center">
      <div className="w-full h-100px flex items-center">
        <img
          className="h-16 md:h-32 w-16 md:w-32 p-1 md:p-5"
          src={logo}
          alt="Logo"
        />
        <h1 className="w-8/10 md:w-max font-bold text-xs md:text-4xl text-colour_2 p-0 md:p-2 justify-center">
          USER JOURNEYS
        </h1>
      </div>
      {/* Add image from url: https://zapier.com/blog/customer-journey-mapping/ */}
      <div className="w-1/4 h-1/4 p-3">
        <img
          src="https://miro.medium.com/v2/resize:fit:4800/format:webp/1*vm_zGA2EajlAwkPt4TAdrA.png"
          alt="Customer Journey Map"
        />
      </div>

      <div className="flex">
        <div className="w-1/2">
          <h1 className="w-8/10 md:w-max font-bold text-xs md:text-4xl text-colour_2 p-0 md:p-2 justify-center">
            HOMEOWNER USER JOURNEY{" "}
          </h1>
          <Timeline position="alternate">
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="secondary" />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                1. Current plan: I wonder if there is a better and more trusted way to get my home renovation done?,
                2. What I really want is a a comparison of prices to make sure I am not being overcharged
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="success" />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                3. Sees ad for Openbuilder AI,
                4 and 5. Research current way they do home improvement on Facebook, Gumtree etc
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="success" />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                6 and 7. Cannot call current carriers because it's not a formal contruction platform - single contractors etc
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="success" />
              </TimelineSeparator>
              <TimelineContent>
                8. Switches to Openbuilder AI
              </TimelineContent>
            </TimelineItem>
          </Timeline>
        </div>
        <div className="w-1/2">
          <h1 className="w-8/10 md:w-max font-bold text-xs md:text-4xl text-colour_2 p-0 md:p-2 justify-center">
            CONTRACTOR USER JOURNEY{" "}
          </h1>
          <Timeline position="alternate">
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="secondary" />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                1. Current plan: I wonder if there is a better to get more construction jobs?,
                2. What I really want is an easy way to get jobs
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="success" />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                3. Sees ad for Openbuilder AI for home requests,
                4 and 5. Research current way they scout for jobs on Facebook, Gumtree etc
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="success" />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                6 and 7. Goes through normal platforms but there's not a coherency in the requests and no way to see if it's completed
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="success" />
              </TimelineSeparator>
              <TimelineContent>
                8. Switches to Openbuilder AI
              </TimelineContent>
            </TimelineItem>
          </Timeline>
        </div>
      </div>
    </div>
  );
};

export default UserJourneys;
