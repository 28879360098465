import React, { useState, useCallback } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../backend/firebase/firebase-config";
import { NavLink, useNavigate } from "react-router-dom";
import logo from "../../data/assets/logo/openbuilder_logo.png";
import HouseGarden from "../../data/assets/icons/HouseWithGarden.png";
import Wrench from "../../data/assets/icons/Wrench.png";
import { Button } from "@material-tailwind/react";

import { useAuth } from "../../backend/firebase/AuthContext";

import "../../stylings/Header.css";

export function WelcomeClient() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  // Access the authentication status using the useAuth() hook

  const { user, handleSignOut } = useAuth();

  const onLogin = (e) => {
    e.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        navigate("/universal_dashboard");
        console.log(user);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage);
      });
  };

  const onForgotPassword = (e) => {
    e.preventDefault();
    navigate("/forget_password");
  };

  return (
    <div className="h-screen">
      <div className="h-28 flex items-center justify-center">
        {/* Header Section */}
        {/* <h1 className="text-white text-2xl">Header</h1> */}

        <div>
          {user ? (
            <div className="font-semibold text-lg md: text-sm text-colour_2 p-2 m-8">
              Welcome ! {user.email}
              <br />
              <button
                onClick={handleSignOut}
                className="border-2 w-100px rounded-lg bg-colour_2 text-white p-2 text-sm"
              >
                Sign Out
              </button>
            </div>
          ) : (
            <div className="flex flex-col md:flex-row items-center">
              <img
                className="md:h-18 h-9 md:w-32 w-16 p-5 mx-auto"
                src={logo}
                alt="Logo"
              />
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <button
                  className="w-32 md:w-64 rounded-lg bg-colour_2 text-white p-1 md:p-6 text-sm m-2 md:text-lg"
                  onClick={() => {
                    navigate("/create_profile");
                  }}
                >
                  Sign up
                </button>
                <button
                  className="w-32 md:w-64 rounded-lg bg-colour_2 text-white p-1 md:p-6 text-sm m-2 md:text-lg"
                  onClick={() => {
                    navigate("/sign_into_profile");
                  }}
                >
                  Sign in
                </button>
              </div>
            </div>
          )}
        </div>

        <div className="w-full h-100px flex items-center justify-between md:p-4 p-0">
          <div className="flex items-center"></div>
          <button
            className="w-32 md:w-64 rounded-lg bg-colour_2 text-white p-1 md:p-6 text-sm m-4 md:text-lg"
            onClick={() => {
              navigate("/welcome_contractor");
            }}
          >
            Contractor's Corner
          </button>
        </div>
      </div>

      <div className="md:h-2/5 1/5 flex flex-col justify-center items-center p-6">
        {/* Top Section */}
        <h1 className="font-bold md:text-5xl text-3xl text-colour_2 p-8s">
          NEXT GENERATION CONSTRUCTION
        </h1>

        <h1 className="font-thin md:text-2xl text-md text-colour_2">
          Openbuilder AI develops next generation construction technology,
          enabling efficient and sustainable development of living spaces
        </h1>
      </div>

      <div className="h-1/5 flex flex-col item md:flex-row">
        <div className="flex flex-col items-center md:p-10 p-1">
          {/* Image */}
          <img
            src={Wrench}
            className="md:w-24 w-12 md:h-24 h-12"
            alt="Wrench"
          />

          {/* Heading */}
          <h1 className="font-semibold text-xs md:text-xl text-colour_2 p-8">
            LOOK AT VARIOUS OPTIONS TO UPGRADE YOUR HOME
          </h1>

          {/* Button */}
          <div className="w-100">
            <button
              className="w-128 md:w-100px rounded-lg bg-colour_2 text-white p-1 md:p-6 text-sm md:text-lg"
              onClick={() => {
                navigate("/client_build_alteration");
              }}
            >
              Do alterations to your existing home
            </button>
          </div>
        </div>

        <div className="flex flex-col items-center p-10">
          {/* Image */}
          <img
            src={HouseGarden}
            className="md:w-24 w-12 md:h-24 h-12"
            alt="Wrench"
          />

          {/* Heading */}
          <h1 className="font-semibold text-xs md:text-xl text-colour_2 p-8">
            VIEW YOUR HOME ALTERATION PROGRESS
          </h1>

          {/* Button */}
          <div className="w-100">
            <button
              className="w-128 md:w-100px rounded-lg bg-colour_2 text-white p-1 md:p-6 text-sm md:text-lg"
              // onClick={() => { navigate('/client_new_build') }}
              onClick={() => {
                // alert("Coming soon");
                navigate("/client_projects");
              }}
            >
              {/* Design your new home */}
              View your home improvements
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
