import React from "react";
import { Bar } from "react-chartjs-2";
import Chart from "chart.js/auto";

export const PriceComparisonChart = React.memo(({ contractorsData }) => {
  // Processing the data to sum prices by category for each contractor
  const pricesByCategory = {};
  contractorsData.forEach((contractorsGroup) => {
    Object.entries(contractorsGroup.contractors).forEach(
      ([contractorEmail, contractorData]) => {
        Object.entries(contractorData.prices).forEach(([category, price]) => {
          if (!pricesByCategory[category]) {
            pricesByCategory[category] = [];
          }
          pricesByCategory[category].push({
            contractor: contractorEmail,
            price: price,
          });
        });
      }
    );
  });

  // Preparing datasets for each category
  const datasets = Object.keys(pricesByCategory).map((category, index) => {
    const colors = [
      "rgba(255, 99, 132, 0.5)", // Red
      "rgba(54, 162, 235, 0.5)", // Blue
      "rgba(255, 206, 86, 0.5)", // Yellow
      "rgba(75, 192, 192, 0.5)", // Green
      "rgba(153, 102, 255, 0.5)", // Purple
      "rgba(255, 159, 64, 0.5)", // Orange
      "rgba(255, 99, 71, 0.5)", // Tomato
      "rgba(60, 179, 113, 0.5)", // Medium Sea Green
      "rgba(255, 105, 180, 0.5)", // Hot Pink
      "rgba(0, 206, 209, 0.5)", // Dark Turquoise
      "rgba(148, 0, 211, 0.5)", // Dark Violet
      "rgba(70, 130, 180, 0.5)", // Steel Blue
      "rgba(244, 164, 96, 0.5)", // Sandy Brown
      "rgba(210, 105, 30, 0.5)", // Chocolate
      "rgba(220, 20, 60, 0.5)", // Crimson
      "rgba(0, 128, 128, 0.5)", // Teal
      "rgba(72, 61, 139, 0.5)", // Dark Slate Blue
      "rgba(143, 188, 143, 0.5)", // Dark Sea Green
      "rgba(47, 79, 79, 0.5)", // Dark Slate Grey
      "rgba(0, 191, 255, 0.5)", // Deep Sky Blue
    ];
    const color = colors[index % colors.length];
    const data = pricesByCategory[category].map((entry) => entry.price);

    return {
      label: category,
      data: data,
      backgroundColor: color,
      borderColor: color,
      borderWidth: 1,
    };
  });

  // Extracting contractor emails for labels
  const labels = contractorsData.flatMap((contractorsGroup) =>
    Object.keys(contractorsGroup.contractors)
  );

  // Chart data and options
  const chartData = { labels, datasets };
  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return <Bar data={chartData} options={options} />;
});

export default PriceComparisonChart;
