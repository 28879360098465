import React from "react";
import logo from "../../data/assets/logo/openbuilder_logo.png";
import { useNavigate } from "react-router-dom";

export function Congratulations() {

  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center justify-center">
      <img src={logo} className="w-48 h-48 m-10" alt="Logo" />

      <h1 className="font-bold text-5xl text-colour_2 p-8">CONGRATULATIONS!</h1>

      <br></br>

      <h1 className="font-md text-xl text-colour_2 p-8">
        YOUR REQUEST HAS BEEN SENT. YOU WILL BE NOTIFIED REGARDING THE NEXT STEPS.
      </h1>

      <button
        className="w-1/4 rounded-lg bg-colour_2 text-white p-4 text-lg m-10"
        onClick={() => {
          navigate("/");
        }}
      >
        RETURN TO THE HOME PAGE
      </button>
    </div>
  );
}
