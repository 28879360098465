export const CapeTownSuburbs = [
  "Athlone",
  "Bishop Lavis",
  "Bonteheuwel",
  "Crawford",
  "Crossroads",
  "Epping",
  "Gugulethu",
  "Hanover Park",
  "Kalksteenfontein",
  "Khayelitsha",
  "Langa",
  "Lavender Hill",
  "Manenberg",
  "Mitchells Plain",
  "Nyanga",
  "Ottery",
  "Pelican Park",
  "Philippi",
  "Samora Machel",
  "Strandfontein",
  "Valhalla Park",
  "Delft",
  "Belhar",
  "Elsie's River",
  "Vrygrond",

  "Bo-Kaap (Malay Quarter)",
  "Devil's Peak Estate",
  "De Waterkant",
  "Foreshore",
  "Gardens",
  "Higgovale",
  "Lower Vrede (District Six)",
  "Oranjezicht",
  "Salt River",
  "Schotsche Kloof",
  "Tamboerskloof",
  "University Estate",
  "Vredehoek",
  "Walmer Estate (District Six)",
  "Woodstock (including Upper Woodstock)",
  "Zonnebloem (District Six)",

  "Kuils River",
  "Eersterivier",
  "Blue Downs",
  "Blackheath",
  "Firgove",
  "Gordon's Bay",
  "Macassar",
  "Croydon",
  "Sir Lowry's Pass Village",
  "Somerset West",
  "Strand",

  "Bellville",
  "Brackenfell",
  "Bothasig",
  "Brooklyn",
  "Durbanville",
  "Edgemead",
  "Goodwood",
  "Kensington	",
  "Kraaifontein",
  "Loevenstein",
  "Maitland",
  "Monte Vista",
  "Panorama",
  "Thornton",
  "Parow",
  "Plattekloof",
  "Rugby",

  "Bergvliet",
  "Bishopscourt",
  "Claremont",
  "Constantia",
  "Diep River",
  "Grassy Park",
  "Harfield Village",
  "Heathfield",
  "Kenilworth",
  "Kenwyn",
  "Kirstenhof",
  "Kreupelbosch",
  "Lansdowne",
  "Lotus River",
  "Meadowridge",
  "Mowbray",
  "Ndabeni",
  "Newlands",
  "Observatory",
  "Pinelands",
  "Plumstead",
  "Retreat",
  "Rondebosch",
  "Rondebosch East",
  "Rosebank",
  "SouthField",
  "Steenberg",
  "Tokai",
  "Wynberg",
  "Wetton",
  "Capri Village",
  "Clovelly",
  "Fish Hoek",
  "Glencairn",
  "Kalk Bay",
  "Kommetjie",
  "Lakeside",
  "Marina da Gama",
  "Masiphumelele",
  "Muizenberg",
  "Noordhoek (PO boxes only)",
  "Ocean View	",
  "Scarborough (PO boxes only)",
  "Simon's Town",
  "St James",
  "Sunnydale",
  "Sun Valley",
  "Vrygrond",

  "Atlantis",
  "Bloubergstrand",
  "Mamre",
  "Melkbosstrand",
  "Milnerton",
  "Montague Gardens",
  "Parklands",
  "Table View",
  "West Beach",
];

export const JohannesburgSuburbs = [
  "Airdlin",
  "Ambitiouz Entertainment",
  "Barbeque Downs",
  "Barbeque Downs Business Park",
  "Bloubosrand",
  "Blue Hills, Gauteng",
  "Bridle Park",
  "Broadacres, Gauteng",
  "Buccleuch, Gauteng",
  "Bultfontein, Johannesburg",
  "Carlswald",
  "Chartwell, Gauteng",
  "Country View",
  "Crowthorne, Gauteng",
  "Dainfern",
  "Diepsloot",
  "Ebony Park",
  "Erand",
  "Farmall, Gauteng",
  "Glen Austin",
  "Halfway Gardens",
  "Halfway House Estate",
  "Headway Hill",
  "Houtkoppen",
  "Inadan (city)",
  "Ivory Park",
  "Jacaranda FM",
  "Kaalfontein",
  "Kya Sand",
  "Kya Sands, Johannesburg",
  "Kyalami Agricultural Holdings",
  "Kyalami Business Park",
  "Kyalami Estates",
  "Mall of Africa",
  "Maroeladal",
  "Midrand",
  "Midridge Park",
  "Millgate Farm",
  "Nietgedacht",
  "Noordwyk",
  "North Champagne Estates",
  "Paulshof",
  "Plooysville",
  "Rabie Ridge",
  "Randjesfontein Agricultural Holdings",
  "Randjespark",
  "Salfred",
  "Sunninghill, Gauteng",
  "Sunrella",
  "Trevallyn, Gauteng",
  "Trojan, Gauteng",
  "Truvelo Armoury",
  "Vorna Valley",
  "Willaway",
  "Witkoppen",
  "Witpoort",

  "Albertskroon",
  "Albertville, Gauteng",
  "Aldara Park",
  "Amalgam, Gauteng",
  "Auckland Park",
  "Baptist Theological College of Southern Africa",
  "Berario",
  "Beverley Gardens",
  "Blackheath, Gauteng",
  "Blairgowrie, Gauteng",
  "Bordeaux, Gauteng",
  "Bosmont",
  "Brixton, Johannesburg",
  "Bryanbrink",
  "Bryanston West, Gauteng",
  "Coronationville, Gauteng",
  "Country Life Park",
  "Cowdray Park, Gauteng",
  "Craighall",
  "Craighall Park",
  "Cramerview",
  "Cresta, Gauteng",
  "Daniel Brink Park",
  "Darrenwood",
  "Dunkeld West",
  "Dunkeld, Gauteng",
  "Emmarentia",
  "Ferndale, Gauteng",
  "Florida Glen",
  "Fontainebleau, Gauteng",
  "Forest Town, Gauteng",
  "Glenadrienne",
  "Greenside, Gauteng",
  "Greymont",
  "Hurlingham Gardens",
  "Hurlingham, Gauteng",
  "Hyde Park, Gauteng",
  "Kensington B",
  "Linden, Gauteng",
  "Lindfield House",
  "Lyme Park, Gauteng",
  "Malanshof",
  "Melville, Johannesburg",
  "Mill Hill, Gauteng",
  "Newlands, Johannesburg",
  "Northcliff",
  "Oerder Park",
  "Osummit",
  "Parkhurst, Gauteng",
  "Parkmore",
  "Parktown North",
  "Parkview, Gauteng",
  "President Ridge",
  "Randburg",
  "Randpark",
  "Randpark Ridge",
  "Riverbend, Gauteng",
  "Rosebank (Gautrain station)",
  "Rosebank, Gauteng",
  "Ruiterhof",
  "Sandhurst, Gauteng",
  "Solridge",
  "Sophiatown",
  "Strijdompark",
  "Total South Africa",
  "Vandia Grove",
  "Waterval Estate, Randburg",
  "Westbury, Johannesburg",
  "Westcliff, Gauteng",
  "Westdene, Johannesburg",
  "Willowild",

  "Bromhof",
  "Bush Hill, Gauteng",
  "Constantia Kloof",
  "Douglasdale, Gauteng",
  "Fairland, Gauteng",
  "Florida Hills",
  "Florida, Gauteng",
  "Johannesburg North",
  "Jukskei Park",
  "Kloofendal Nature Reserve",
  "Northgate, Gauteng",
  "Northriding",
  "Olivedale, Gauteng",
  "Roodepoort",
  "Weltevredenpark",
  "Zandspruit",

  "Diepkloof",
  "Dobsonville",
  "Doornkop",
  "Kliptown",
  "Meadowlands, Gauteng",
  "Noordgesig",
  "Orlando, Soweto",
  "Phiri, Soweto",
  "Protea Glen",
  "Soweto",
  "Zola, South Africa",

  "Abbotsford, Johannesburg",
  "Alexandra, South Africa",
  "Atholhurst",
  "Atholl Gardens",
  "Atholl, Gauteng",
  "Bagleyston",
  "Benmore Gardens",
  "Birdhaven, Gauteng",
  "Birnam, Gauteng",
  "Blue Haven, Gauteng",
  "Bramley North",
  "Bramley Park",
  "Bramley, Gauteng",
  "Bruma Lake Flea Market, Gauteng",
  "Bruma, Gauteng",
  "Bryanston East, Gauteng",
  "Bryanston, Gauteng",
  "Cheltondale",
  "Chislehurston",
  "Cyrildene",
  "Dennehof, Gauteng",
  "Dunhill, Gauteng",
  "Edenburg, Gauteng",
  "Elton Hill",
  "Epsom Downs, Gauteng",
  "Fairmount, Gauteng",
  "Fairway, Gauteng",
  "Fairwood, Gauteng",
  "Fellside, Gauteng",
  "Forbesdale",
  "Fourways",
  "Fourways Mall",
  "Gallo Manor",
  "The Gardens, Johannesburg",
  "Glenhazel",
  "Greenstone Hill",
  "Gresswold",
  "Hawkins Estate",
  "Highlands North, Gauteng",
  "Houghton Estate",
  "Hurl Park",
  "Illovo, Gauteng",
  "Inanda, Gauteng",
  "Kentview, Johannesburg",
  "Kew, Gauteng",
  "Khyber Rock",
  "Killarney, Johannesburg",
  "Klevehill Park",
  "Linksfield, Johannesburg",
  "Littlefillan",
  "Lombardy East",
  "Lone Hill",
  "Magaliessig",
  "Marlboro Gardens",
  "Marlboro, Gauteng",
  "Maryvale, Gauteng",
  "Melrose Estate",
  "Melrose North",
  "Melrose, Johannesburg",
  "Michelangelo Towers",
  "Modderfontein (East Rand)",
  "Moodie Hill",
  "Morningside Manor",
  "Morningside, Gauteng",
  "Mountain View, Johannesburg",
  "Norscot",
  "Northern Acres, Gauteng",
  "Norwood, Gauteng",
  "Oaklands, Gauteng",
  "Orange Grove, Gauteng",
  "Orchards, Johannesburg",
  "Parkwood, Johannesburg",
  "Percelia Estate",
  "Petervale",
  "The Radium Beerhall",
  "Raedene Estate",
  "Raumarais Park",
  "River Club, Gauteng",
  "Riviera, Gauteng",
  "Rivonia",
  "Sandown, Gauteng",
  "Sandringham, Gauteng",
  "Sandton",
  "Savoy Estate",
  "Saxonwold",
  "Simba, Gauteng",
  "South African Theological Seminary",
  "Strathavon",
  "Sunningdale Ridge",
  "Sunningdale, Gauteng",
  "Sunset Acres",
  "Sydenham, Johannesburg",
  "VBS Mutual Bank",
  "Victoria, Johannesburg",
  "Waverley, Johannesburg",
  "Wierda Valley",
  "Woodlands, Johannesburg",
  "Woodmead",
  "Wynberg, Gauteng",

  "Aeroton",
  "Alan Manor",
  "Aspen Hills, Gauteng",
  "Bassonia",
  "Belgravia, Gauteng",
  "Bellevue East",
  "Bellevue, Gauteng",
  "Benrose",
  "Berea, Gauteng",
  "Bertrams, Gauteng",
  "Bezuidenhout Valley",
  "Braamfontein",
  "Braamfontein Werf",
  "Chrisville",
  "City and Suburban Industrial, Gauteng",
  "City and Suburban, Gauteng",
  "City Deep, Gauteng",
  "City West-Denver",
  "Cleveland, Gauteng",
  "Cottesloe, Johannesburg",
  "Crosby, Gauteng",
  "Crown Gardens",
  "Crown North",
  "Crown, Gauteng",
  "Denver, Johannesburg",
  "Doornfontein",
  "Droste Park",
  "Eagles Nest, Gauteng",
  "Eastcliff, Johannesburg",
  "Elandspark",
  "Elcedes",
  "Electron, Gauteng",
  "Elladoone",
  "Evans Park",
  "Fairview, Gauteng",
  "Ferreirasdorp",
  "Fietas Museum",
  "Fordsburg",
  "Forest Hill, Gauteng",
  "Framton",
  "The Gables, Gauteng",
  "Gillview",
  "Glenanda",
  "Glenesk",
  "Glenvista",
  "Haddon, Gauteng",
  "Heriotdale",
  "Highlands, Johannesburg",
  "The Hill, Gauteng",
  "Hillbrow",
  "Homestead Park, Gauteng",
  "Houghton Estate",
  "Inner City, Johannesburg",
  "Jan Hofmeyer",
  "Jeppestown",
  "Jeppestown South",
  "Johannesburg CBD",
  "Johannesburg South",
  "Joubert Park",
  "Judith's Paarl",
  "Kenilworth, Johannesburg",
  "Kensington, Gauteng",
  "Kibler Park",
  "Klipriviersberg",
  "Klipriviersberg Estate",
  "La Rochelle, Johannesburg",
  "Lake View Estate",
  "Lakeside, Johannesburg",
  "Langlaagte North",
  "Liefde en Vrede",
  "Lindberg Park",
  "Linmeyer",
  "Lorentzville",
  "Malvern, Gauteng",
  "Marshalltown, Johannesburg",
  "Mayfair West, Gauteng",
  "Mayfair, Johannesburg",
  "Mayfield Park, Gauteng",
  "Meredale",
  "Milpark",
  "Moffat View",
  "Mondeor",
  "Mulbarton, Johannesburg",
  "Nasrec",
  "New Centre, Gauteng",
  "New Doornfontein",
  "Newtown, Johannesburg",
  "North Doornfontein",
  "Oakdene, Johannesburg",
  "Observatory, Johannesburg",
  "Ophirton",
  "Ormonde, Gauteng",
  "Pageview, Johannesburg",
  "Park Central, Gauteng",
  "Parktown",
  "Patlynn",
  "Prolecon",
  "Randview",
  "Regents Park Estate",
  "Regents Park, Gauteng",
  "Reuven, Gauteng",
  "Rewlatch",
  "Reynolds View",
  "Ridgeway, Johannesburg",
  "Riepen Park",
  "Risana",
  "Rispark",
  "Robertsham",
  "Roseacre, Gauteng",
  "Rosettenville",
  "Salisbury Claims",
  "Selby, Johannesburg",
  "South Hills, Gauteng",
  "Southdale, Johannesburg",
  "Southfork, Gauteng",
  "Southgate, Gauteng",
  "Spes Bona",
  "Springfield, Gauteng",
  "Stafford, Gauteng",
  "Steeledale",
  "Suideroord",
  "Theta, Gauteng",
  "Towerby",
  "Townsview",
  "Troyeville",
  "Tulisa Park",
  "Turf Club, Gauteng",
  "Turffontein",
  "Village Main, Gauteng",
  "Vrededorp, Gauteng",
  "Wemmer",
  "Winchester Hills",
  "Yeoville",
  "Drieziek",
  "Eldorado Park, Gauteng",
  "Ennerdale, South Africa",
  "Kanana Park",
  "Lawley, South Africa",
  "Lenasia",
  "Orange Farm",
];
