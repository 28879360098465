import React from "react";
import { Bar } from "react-chartjs-2";
import Chart from "chart.js/auto";

export const BarChart = React.memo(({ data }) => {
  // Processing the data to count services by location
  const serviceCountsByLocation = {};
  data.forEach((item) => {
    const location = item.business_location || 'Unknown';
    if (!serviceCountsByLocation[location]) {
      serviceCountsByLocation[location] = {};
    }
    item.services.forEach((service) => {
      serviceCountsByLocation[location][service] = (serviceCountsByLocation[location][service] || 0) + 1;
    });
  });

  // Preparing datasets for each location
  const datasets = Object.keys(serviceCountsByLocation).map((location, index) => {
    const colors = ["rgba(255, 99, 132, 0.5)", "rgba(54, 162, 235, 0.5)", "rgba(255, 206, 86, 0.5)", "rgba(75, 192, 192, 0.5)", "rgba(153, 102, 255, 0.5)", "rgba(255, 159, 64, 0.5)"]; // Extend this array for more colors
    const color = colors[index % colors.length];

    return {
      label: `${location}`,
      data: Object.values(serviceCountsByLocation[location]),
      backgroundColor: color,
      borderColor: color,
      borderWidth: 1,
    };
  });

  // Chart data
  const chartData = {
    labels: Object.keys(serviceCountsByLocation[Object.keys(serviceCountsByLocation)[0]] || {}),
    datasets: datasets,
  };

  // Chart options
  const options = {
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          font: {
            size: 10,
          },
        },
      },
      x: {
        beginAtZero: true,
        ticks: {
          font: {
            size: 10,
          },
        },
      },
    },
  };

  return <Bar data={chartData} options={options} />;
});

export default BarChart;
