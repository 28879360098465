import React, { useState, useEffect, useCallback } from "react";
import { useAuth } from "../../backend/firebase/AuthContext";
import logo from "../../data/assets/logo/openbuilder_logo.png";
import {
  fetchBusinessesContractorEmails,
  fetchHomeownersEmails,
  fetchContractorEmails,
} from "../../backend/lists/fetchData";

export const AdminEmailSender = React.memo(() => {
  const [emails, setEmails] = useState({});
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [manualEmails, setManualEmails] = useState([]);
  const [newEmail, setNewEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [content, setContent] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [initialFetchDone, setInitialFetchDone] = useState(false);

  const { isAdmin } = useAuth();

  const prepareContent = (content) => {
    return content
      .split("\n")
      .map((line) => `<p>${line}</p>`)
      .join("");
  };

  const fetchEmails = useCallback(async () => {
    try {
      const homeownerEmails = await fetchHomeownersEmails();
      const contractorEmails = await fetchContractorEmails();
      const businessEmails = await fetchBusinessesContractorEmails();
      setEmails({
        homeowners: homeownerEmails,
        contractors: contractorEmails,
        businesses: businessEmails,
      });
      setInitialFetchDone(true);
    } catch (error) {
      console.error("Error fetching emails:", error);
      setError("Failed to fetch emails.");
    }
  }, []);

  useEffect(() => {
    if (!initialFetchDone) {
      fetchEmails();
    }
  }, [fetchEmails, initialFetchDone]);

  const handleEmailSelect = (email) => {
    setSelectedEmails((prevSelected) => {
      if (prevSelected.includes(email)) {
        return prevSelected.filter((e) => e !== email);
      } else {
        return [...prevSelected, email];
      }
    });
  };

  const handleSelectAll = (category) => {
    setSelectedEmails((prevSelected) => {
      const categoryEmails = emails[category];
      const allSelected = categoryEmails.every((email) =>
        prevSelected.includes(email)
      );

      if (allSelected) {
        return prevSelected.filter((email) => !categoryEmails.includes(email));
      } else {
        return [...new Set([...prevSelected, ...categoryEmails])];
      }
    });
  };

  const handleAddEmail = () => {
    if (newEmail && !selectedEmails.includes(newEmail)) {
      setManualEmails((prevManual) => [...prevManual, newEmail]);
      handleEmailSelect(newEmail);
      setNewEmail("");
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!selectedEmails.length) {
      setError("Please select at least one email.");
      return;
    }

    setLoading(true);
    setError("");
    setMessage("");

    const data = {
      emails: selectedEmails,
      subject,
      content: prepareContent(content),
    };

    try {
      const response = await fetch(
        "https://opbai98.pythonanywhere.com/send_email",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      const result = await response.json();
      setMessage("Email sent successfully!");
      setSelectedEmails([]);
      setSubject("");
      setContent("");
    } catch (error) {
      console.error("Error:", error);
      setError("Failed to send email. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  if (!isAdmin) {
    return <h1>Not an admin</h1>;
  }

  return (
    <div className="flex flex-col items-center justify-center">
      <div>
        <img src={logo} className="md:w-48 w-40 md:h-48 h-30 m-10" alt="Logo" />
      </div>
      <div className="w-3/4">
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Subject:
            </label>
            <input
              type="text"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              className="shadow appearance-none w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline rounded-md border-colour_2 border-solid border-2"
            />
          </div>
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Content:
            </label>
            <textarea
              value={content}
              onChange={(e) => setContent(e.target.value)}
              className="shadow appearance-none w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline h-64 rounded-md border-colour_2 border-solid border-2"
            />
          </div>
          {error && <p className="text-red-500 text-xs italic mb-4">{error}</p>}
          {message && (
            <p className="text-green-500 text-xs italic mb-4">{message}</p>
          )}
          <div className="flex justify-center">
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 focus:outline-none focus:shadow-outline rounded-md"
              disabled={loading}
            >
              {loading ? "Sending..." : "Send Email"}
            </button>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Add Additional Email:
            </label>
            <div className="flex items-center">
              <input
                type="text"
                value={newEmail}
                onChange={(e) => setNewEmail(e.target.value)}
                className="shadow appearance-none w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline rounded-md border-colour_2 border-solid border-2"
              />
              <button
                type="button"
                onClick={handleAddEmail}
                className="ml-2 bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-1 px-2 rounded"
              >
                Add
              </button>
            </div>
          </div>
          {manualEmails.length > 0 && (
            <div>
              <h3 className="text-lg font-semibold">Manual Emails</h3>
              {manualEmails.map((email) => (
                <div key={email}>
                  <input
                    type="checkbox"
                    value={email}
                    checked={selectedEmails.includes(email)}
                    onChange={() => handleEmailSelect(email)}
                  />
                  <label className="ml-2">{email}</label>
                </div>
              ))}
            </div>
          )}
          <div className="grid grid-cols-3 gap-4 mb-4">
            {Object.keys(emails).map((category) => (
              <div key={category}>
                <h3 className="text-lg font-semibold">{category}</h3>
                <button
                  type="button"
                  onClick={() => handleSelectAll(category)}
                  className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-1 px-2 mb-2 rounded"
                >
                  Select All
                </button>
                {emails[category].map((email) => (
                  <div key={email}>
                    <input
                      type="checkbox"
                      value={email}
                      checked={selectedEmails.includes(email)}
                      onChange={() => handleEmailSelect(email)}
                    />
                    <label className="ml-2">{email}</label>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </form>
      </div>
    </div>
  );
});